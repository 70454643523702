/* eslint-disable max-len */
export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
export const SCHOOL_NAME_REGEXP =
  /^[A-Za-z0-9 !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/
export const ADDRESS_REGEXP =
  /^[A-Za-z0-9 !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/
export const FULL_NAME_REGEXP = /^[A-Za-z ]*$/

export const PHONE_REGEXP = /^\+?[1-9]\d{1,14}$/

export const TREATMENT_CODE_REGEXP = /^\d+$/

export const PASSWORD_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/
