import { toastSuccess } from '@/utils/lib'
import {
  PendingMutation,
  saveMutationToStorage
} from '@/utils/lib/offlineStorage'

export const useOfflineMutation = () => {
  const isOnline = navigator.onLine

  const handleOfflineMutation = (
    mutation: PendingMutation,
    onSuccess?: (data?: any) => void
  ) => {
    saveMutationToStorage(mutation)
    toastSuccess({
      content: `Report will be ${
        mutation.type.includes('Add') ? 'created' : 'updated'
      } when you're back online`
    })
    if (onSuccess) {
      onSuccess()
    }
  }

  return { isOnline, handleOfflineMutation }
}
