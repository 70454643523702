import {
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography
} from '@mui/material'
import React, { ReactNode, useState } from 'react'

import { EyeClosedIcon, EyeOpenIcon } from '@/assets/icons'
import { theme } from '@/theme'

export type CustomTextFieldProps = TextFieldProps & {
  labelProp: string
  requaire?: boolean
  register?: any
  tooltipComponent?: ReactNode
}

const PasswordInput: React.FC<CustomTextFieldProps> = ({
  labelProp,
  requaire,
  register,
  tooltipComponent,
  ...props
}: CustomTextFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const id = props.id || 'password'

  return (
    <FormControl fullWidth>
      <FormLabel htmlFor={id}>
        <Typography
          component={'span'}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '4px'
          }}
        >
          <Typography variant="inherit">
            {labelProp}
            {requaire && (
              <Typography
                component="span"
                variant="inherit"
                sx={{
                  color: theme.palette.error.main
                }}
              >
                *
              </Typography>
            )}
          </Typography>
          {tooltipComponent}
        </Typography>
      </FormLabel>
      <TextField
        {...props}
        {...register}
        fullWidth
        type={isPasswordVisible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="inherit"
                size="small"
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? (
                  <EyeOpenIcon
                    sx={{
                      width: '20px',
                      height: '20px'
                    }}
                  />
                ) : (
                  <EyeClosedIcon
                    sx={{
                      width: '20px',
                      height: '20px'
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  )
}

export default PasswordInput
