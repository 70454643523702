import { Box, Typography } from '@mui/material'
import React from 'react'
import { UseFormSetValue } from 'react-hook-form'

import { theme } from '@/theme'
import { TTreatmentPlanItem } from '@/types/TreatmentCodes'

import { CdbsReportData } from '../cdbs/components/CdbsReport'
import { TreatmentTable } from './TreatmentTable'

interface Props {
  treatment_plan: TTreatmentPlanItem[]
  setValue: UseFormSetValue<CdbsReportData>
}

export const TreatmentPlan: React.FC<Props> = (props): JSX.Element => {
  const { treatment_plan, setValue } = props

  return (
    <Box mb={3} display="flex" flexDirection="column">
      <Box mb={1}>
        <Typography variant="h3" mb="20px">
          Treatment plan
        </Typography>
        <Typography component="span">
          If your child requires any further dental treatment, it will be
          detailed in the table below. The quantity column indicates how many
          teeth require treatment for that particular treatment/service.{' '}
          <Typography component="span" fontWeight={600} fontSize="16px">
            MEDICARE WILL COVER THESE COSTS{' '}
          </Typography>
          up to the allocated balance if you give us consent.
          <br />
          <br />
          <Typography component="span" fontWeight={600} fontSize="16px">
            We can complete certain treatments at the school in our mobile
            dental clinic. Please see the detailed treatment plan for your
            reference and contact us on (03) 9323 9607 to discuss the completion
            of the treatment. If you would like us to contact you, email
            info@adhv.com.au with your child’s name and your best contact number
            and one of our team members will contact you as soon as possible.
          </Typography>
          <br />
          <br />
          <Typography>
            The treatment outlined below requires your consent before it can be
            completed by Australian Dental Health VIC.
          </Typography>
        </Typography>
      </Box>
      <TreatmentTable setValue={setValue} treatment_plan={treatment_plan} />
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
