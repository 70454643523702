export const filterDuplicatesByKey = <T>(array: T[], key: string): T[] => {
  const uniqueArray: T[] = []
  const uniqueValues: any[] = []

  array.forEach((obj) => {
    const value = obj[key as keyof T]

    if (!uniqueValues.includes(value)) {
      uniqueValues.push(value)
      uniqueArray.push(obj)
    }
  })

  return uniqueArray
}
