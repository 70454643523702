import { parsePhoneNumberWithError } from 'libphonenumber-js'
import * as yup from 'yup'

import { byteConverter } from '@/helpers/functions'
import {
  RegionEnum,
  SchoolStatusEnum,
  SchoolTypeEnum,
  TAESchoolFilesModel
} from '@/types'
import { dayjs } from '@/utils/lib'

import {
  ADDRESS_REGEXP,
  EMAIL_REGEXP,
  FULL_NAME_REGEXP,
  MAX_UPLOAD_FILE_SIZE,
  MIN_UPLOAD_FILE_SIZE,
  SCHOOL_NAME_REGEXP,
  SYSTEM_DATE_FORMAT
} from '../constants'

export const aeSchoolValidation = (isSchool: boolean) =>
  yup.object().shape({
    name: yup
      .string()
      .required('This is a required field')
      .matches(SCHOOL_NAME_REGEXP, 'School name is invalid'),
    address: yup
      .string()
      .required('This is a required field')
      .matches(ADDRESS_REGEXP, 'Address is invalid'),
    email: yup
      .string()
      .trim()
      .required('This is a required field')
      .matches(EMAIL_REGEXP, 'Email must be a valid'),
    phone: yup
      .string()
      .required('This is a required field')
      .test('test-phone-number', 'Phone is invalid', (value) => {
        try {
          return parsePhoneNumberWithError(value).isPossible()
        } catch (e) {
          return false
        }
      }),
    type: yup
      .mixed<SchoolTypeEnum | ''>()
      .oneOf([...Object.values(SchoolTypeEnum), ''], 'Invalid option')
      .nullable(),
    region: yup
      .mixed<RegionEnum | ''>()
      .oneOf([...Object.values(RegionEnum)], 'Invalid option')
      .required('This is a required field'),
    status: yup
      .mixed<SchoolStatusEnum | ''>()
      .oneOf([...Object.values(SchoolStatusEnum), ''], 'Invalid option')
      .nullable(),
    number_of_students: yup
      .string()
      .test(
        'test-number-of-students',
        'Maximum allowed number of students is 1000000',
        (value) => {
          if (value === '') return true
          return Number(value) > 1000000 ? false : true
        }
      ),
    classes: yup.array(),
    admin_note: yup.string(),
    general_notes: yup.string(),
    files: filesValidation,
    dental_visit_start_date: isSchool
      ? yup.string().defined()
      : yup
          .string()
          .required('This is a required field')
          .test('test-dental-visit-start-date', 'Date is invalid', (value) =>
            dayjs(value, SYSTEM_DATE_FORMAT).isValid()
          ),
    dental_visit_end_date: isSchool
      ? yup.string().defined()
      : yup
          .string()
          .test('test-dental-visit-end-date', 'Date is invalid', (value) =>
            value ? dayjs(value, SYSTEM_DATE_FORMAT).isValid() : true
          ),
    form_submission_deadline: isSchool
      ? yup.string().defined()
      : yup
          .string()
          .test(
            'test-form-submission-deadline-date',
            'Date is invalid',
            (value) =>
              value ? dayjs(value, SYSTEM_DATE_FORMAT).isValid() : true
          ),
    continuation_visit_date: isSchool
      ? yup.string().defined()
      : yup
          .string()
          .test(
            'test-continuation-visit-date',
            'Date is invalid',
            (value, ctx) => {
              if (ctx.parent?.status === SchoolStatusEnum.CONTINATION_VISIT)
                return dayjs(value, SYSTEM_DATE_FORMAT).isValid()
              return value ? dayjs(value, SYSTEM_DATE_FORMAT).isValid() : true
            }
          ),
    contact_people: isSchool
      ? contactPeopleValidation.defined()
      : contactPeopleValidation.required(),
    manager: isSchool
      ? yup.object({
          email: yup.string().defined()
        })
      : yup
          .object({
            email: yup
              .string()
              .trim()
              .required('This is a required field')
              .matches(EMAIL_REGEXP, 'Email must be a valid')
          })
          .required()
  })

export const filesValidation = yup.array().of(
  yup.lazy((value) =>
    yup.object({
      id: yup.string(),
      name: yup.string(),
      extension: yup.string(),
      size: yup.string(),
      created_at: yup.string(),
      confidential: yup.boolean(),
      file: yup.mixed<TAESchoolFilesModel['file'] | string>().when([], {
        is: () => value.file instanceof File,
        then: (schema) =>
          schema.test(
            'test-file-size',
            (params) => {
              const size = params.value.size

              const condition =
                size < MIN_UPLOAD_FILE_SIZE
                  ? 'less'
                  : size > MAX_UPLOAD_FILE_SIZE && 'more'

              return `This file size is ${condition} than ${byteConverter(
                condition === 'less'
                  ? MIN_UPLOAD_FILE_SIZE
                  : MAX_UPLOAD_FILE_SIZE
              )}`
            },
            async () => {
              const file = value.file

              return !(
                file.size < MIN_UPLOAD_FILE_SIZE ||
                file.size > MAX_UPLOAD_FILE_SIZE
              )
            }
          ),
        otherwise: (schema) => schema
      })
    })
  )
)

const contactPeopleValidation = yup.array().of(
  yup.object({
    id: yup.string().required(),
    full_name: yup
      .string()
      .required('This is a required field')
      .matches(FULL_NAME_REGEXP, 'Full name is invalid'),
    email: yup
      .string()
      .trim()
      .required('This is a required field')
      .matches(EMAIL_REGEXP, 'Email must be a valid')
      .test('unique-email', 'This email is already used', function (value) {
        if (!value) return true

        const contactPeople =
          this.from && this.from[1] && this.from[1].value.contact_people
        if (!Array.isArray(contactPeople)) return true

        const emails = contactPeople.map((person) =>
          person.email?.toLowerCase().trim()
        )

        const emailCount = emails.filter(
          (email) => email === value.toLowerCase().trim()
        ).length

        return emailCount <= 1
      }),
    phone: yup
      .string()
      .required('This is a required field')
      .test('test-phone-number', 'Phone is invalid', (value) => {
        try {
          return parsePhoneNumberWithError(value).isPossible()
        } catch (e) {
          return false
        }
      })
  })
)

export const viewSchoolDetailsValidation = yup.object().shape({
  files: filesValidation
})

export type TViewSchoolDetailsFormModel = yup.InferType<
  typeof viewSchoolDetailsValidation
>
