/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const DoubleArrowIcon: FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 4 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.76244 7.78855C3.73768 7.72821 3.70139 7.67342 3.65565 7.6273C3.61016 7.58107 3.55611 7.54436 3.4966 7.51927C3.43708 7.49419 3.37326 7.48121 3.30878 7.4811C3.2443 7.48098 3.18044 7.49373 3.12083 7.5186C3.06123 7.54347 3.00705 7.57999 2.96141 7.62606L2.49081 8.10259V1.8978L2.5 0.5L2.3473 0.346192C2.30172 0.299916 2.24756 0.263199 2.18792 0.238145C2.12829 0.213092 2.06435 0.200195 1.99977 0.200195C1.9352 0.200195 1.87126 0.213092 1.81163 0.238145C1.75199 0.263199 1.69783 0.299916 1.65225 0.346192L1.5 0.5L1.50955 1.8978V8.10259L1.03896 7.6273C0.946789 7.53405 0.821779 7.48166 0.691431 7.48166C0.561082 7.48166 0.436072 7.53405 0.343902 7.6273C0.251732 7.72055 0.199951 7.84703 0.199951 7.97891C0.199951 8.11078 0.251732 8.23726 0.343902 8.33051L1.65225 9.6542C1.69783 9.70047 1.75199 9.73719 1.81163 9.76225C1.87126 9.7873 1.9352 9.8002 1.99977 9.8002C2.06435 9.8002 2.12829 9.7873 2.18792 9.76225C2.24756 9.73719 2.30172 9.70047 2.3473 9.6542L3.65565 8.33051C3.70139 8.28439 3.73768 8.2296 3.76244 8.16926C3.7872 8.10893 3.79995 8.04424 3.79995 7.97891C3.79995 7.91358 3.7872 7.84889 3.76244 7.78855Z"
        fill="#7B8A99"
      />
      <path
        d="M0.237559 2.21194C0.262322 2.27227 0.298613 2.32707 0.344353 2.37319C0.389839 2.41942 0.443885 2.45613 0.503403 2.48122C0.562921 2.5063 0.626742 2.51927 0.691218 2.51939C0.755695 2.51951 0.819561 2.50676 0.879166 2.48189C0.938772 2.45701 0.992946 2.4205 1.03859 2.37443L1.50919 1.8979L1.50919 8.10269L1.5 9.50049L1.6527 9.6543C1.69828 9.70057 1.75244 9.73729 1.81208 9.76234C1.87171 9.7874 1.93565 9.80029 2.00023 9.80029C2.0648 9.80029 2.12874 9.7874 2.18837 9.76234C2.24801 9.73729 2.30217 9.70057 2.34775 9.6543L2.5 9.50049L2.49045 8.10269L2.49044 1.8979L2.96104 2.37319C3.05321 2.46644 3.17822 2.51883 3.30857 2.51883C3.43892 2.51883 3.56393 2.46644 3.6561 2.37319C3.74827 2.27994 3.80005 2.15346 3.80005 2.02158C3.80005 1.8897 3.74827 1.76323 3.6561 1.66998L2.34775 0.34629C2.30217 0.300014 2.24801 0.263296 2.18837 0.238243C2.12874 0.21319 2.0648 0.200293 2.00023 0.200293C1.93565 0.200293 1.87171 0.21319 1.81208 0.238243C1.75244 0.263296 1.69828 0.300014 1.6527 0.34629L0.344353 1.66998C0.298613 1.71609 0.262322 1.77089 0.237559 1.83123C0.212796 1.89156 0.200048 1.95625 0.200048 2.02158C0.200048 2.08691 0.212796 2.1516 0.237559 2.21194Z"
        fill="#7B8A99"
      />
    </SvgIcon>
  )
}
