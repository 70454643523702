import { Box, Checkbox, Grid, Typography } from '@mui/material'
import React from 'react'
import { UseFormSetValue } from 'react-hook-form'

import { CheckIcon } from '@/assets/icons'
import { useGetComletedTreatmentsQuery } from '@/redux/services'
import { theme } from '@/theme'

import { CdbsReportData } from '../cdbs/components/CdbsReport'

interface Props {
  treatments_completed: string[]
  setValue: UseFormSetValue<CdbsReportData>
}

const checkboxLabel = {
  exam: 'Exam',
  xray: 'X-ray',
  scale_clean: 'Scale/clean',
  remin_agent: 'Remineralising agent-topical',
  fissure_sealant: 'Fissure sealant(s)',
  filling: 'Filling(s)'
}

export const TreatmentsCompleted: React.FC<Props> = (props): JSX.Element => {
  const { treatments_completed, setValue } = props

  const { data } = useGetComletedTreatmentsQuery(null)

  const handleTreatmentsCompleted = (id: string) => {
    const alreadyInArray = treatments_completed.includes(id)

    if (alreadyInArray) {
      setValue(
        'treatments_completed',
        treatments_completed.filter((el) => el !== id)
      )
    } else {
      setValue('treatments_completed', [...treatments_completed, id])
    }
  }

  return (
    <Grid mb={5}>
      <Typography color={theme.palette.primary.main} variant="h5">
        Treatments/Services completed today{' '}
        <Typography
          component="span"
          variant="inherit"
          sx={{
            color: theme.palette.error.main
          }}
        >
          *
        </Typography>
      </Typography>
      <Box
        display="flex"
        gap={1}
        flexWrap="wrap"
        justifyContent="space-between"
        mt="20px"
      >
        {data?.data.results.map((el) => (
          <Box
            key={el.id}
            sx={{
              maxWidth: 'fit-content',
              mt: 1,
              display: 'flex',
              gap: '4px',
              cursor: 'pointer'
            }}
            onClick={() => handleTreatmentsCompleted(el.id)}
          >
            <Checkbox
              color="primary"
              icon={<></>}
              checkedIcon={
                <CheckIcon
                  sx={{
                    width: '16px',
                    height: '16px'
                  }}
                />
              }
              checked={treatments_completed.includes(el.id)}
            />
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600
              }}
            >
              {checkboxLabel[el.treatment_name as keyof typeof checkboxLabel] ||
                ''}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box mt="20px" borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Grid>
  )
}
