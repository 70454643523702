import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Container, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'

import { Logo } from '@/assets/icons'
import PasswordInput from '@/components/UI/PasswordInput'
import BasicTooltip from '@/components/UI/Tooltips/BasicTooltip'
import { getHookFormErrorMessage, showError } from '@/helpers/functions'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { updateteMePartially } from '@/redux/features/userSlice'
import { useChangeMePasswordMutation } from '@/redux/services/meApi'
import { PAGES } from '@/routes/pages-routes'
import { PASSWORD_TOOLTIP_TEXT } from '@/utils/constants'
import { toastSuccess } from '@/utils/lib'
import { ERole } from '@/utils/permissions/permissions'
import { changePasswordSchema } from '@/utils/yup/profile'

type TChangePasswordModel = InferType<typeof changePasswordSchema>

const initialState: TChangePasswordModel = {
  old_password: '',
  new_password1: '',
  new_password2: ''
}
const ChangePasswordPage: React.FC = (): JSX.Element => {
  const [updatePassword, { isLoading: isUpdating }] =
    useChangeMePasswordMutation()

  const role = useAppSelector((state) => state.userStore.me)?.role as ERole
  const navigate = useNavigate()

  const schema = changePasswordSchema
  const {
    reset,
    register,
    formState: { isDirty, isLoading, isValid, errors },
    handleSubmit
  } = useForm<TChangePasswordModel>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialState
  })

  const dispatch = useAppDispatch()

  const onSubmit: SubmitHandler<TChangePasswordModel> = useCallback(
    async (data) => {
      try {
        await updatePassword(data).unwrap()
        dispatch(updateteMePartially({ is_password_change_required: false }))
        reset(initialState)
        toastSuccess({
          content: 'Password has been updated!'
        })
        navigate(role === ERole.School ? PAGES.PATIENTS : PAGES.SCHOOLS)
      } catch (e: any) {
        showError(e?.data.errors)
      }
    },
    [dispatch, navigate, reset, role, updatePassword]
  )

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        mx: 'auto',
        px: { xs: 4, sm: 8 }
      }}
      maxWidth="sm"
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        gap={1}
        mt={{ xs: 8, lg: 0 }}
        mb={4}
      >
        <Box display="flex" justifyContent="center">
          <Logo
            sx={{
              width: '52px',
              height: '40px'
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          flexDirection="column"
          gap={1}
        >
          <Typography
            textAlign="center"
            fontSize={{ xs: '24px', md: '36px' }}
            variant="h2"
          >
            Reset password
          </Typography>
          <Typography textAlign="center" fontSize="16px" variant="body1">
            Enter your new password and you're good to go.
          </Typography>
        </Box>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        gap={2}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <PasswordInput
            id="old_password"
            labelProp="Password"
            requaire
            autoComplete="current-password"
            inputProps={{
              autoComplete: 'current-password'
            }}
            placeholder="Enter old password"
            error={!!errors['old_password']}
            helperText={getHookFormErrorMessage(errors, 'old_password')}
            register={register('old_password')}
          />
          <PasswordInput
            id="new_password1"
            labelProp="New password"
            requaire
            autoComplete="new-password"
            inputProps={{
              autoComplete: 'new-password'
            }}
            placeholder="Enter new password"
            error={!!errors['new_password1']}
            helperText={getHookFormErrorMessage(errors, 'new_password1')}
            register={register('new_password1')}
            tooltipComponent={
              <BasicTooltip
                hoverContentSx={{
                  maxWidth: '300px'
                }}
                hoverText={PASSWORD_TOOLTIP_TEXT}
              />
            }
          />
          <PasswordInput
            id="new_password2"
            labelProp="Confirm password"
            requaire
            autoComplete="new-password"
            inputProps={{
              autoComplete: 'new-password'
            }}
            placeholder="Enter confirm new password"
            error={!!errors['new_password2']}
            helperText={getHookFormErrorMessage(errors, 'new_password2')}
            register={register('new_password2')}
            tooltipComponent={
              <BasicTooltip
                hoverContentSx={{
                  maxWidth: '300px'
                }}
                hoverText={PASSWORD_TOOLTIP_TEXT}
              />
            }
          />
        </Box>

        <Box mt={3}>
          <Button
            fullWidth
            disabled={!isDirty || isLoading || !isValid || isUpdating}
            type="submit"
            variant="contained"
          >
            Reset password
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default ChangePasswordPage
