export const mergeRefs = <T>(
  ...refs: (React.Ref<T> | undefined)[]
): React.RefCallback<T> => {
  return (element: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element)
      } else if (ref) {
        // eslint-disable-next-line no-extra-semi
        ;(ref as React.MutableRefObject<T | null>).current = element
      }
    })
  }
}
