import * as Sentry from '@sentry/react'

export const initializeSentry = (environment: string) =>
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    // tracePropagationTargets: [
    //   'adhv-prod.pprnd.co',
    //   'adhv.pprnd.co',
    //   /^https:\/\/adhv(-prod)?\.pprnd\.co\//
    // ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
