import * as yup from 'yup'

import { PASSWORD_REGEXP } from '../constants'

export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .max(128, 'Max amount of symbols is 128')
    .matches(PASSWORD_REGEXP, 'Password must be valid')
    .required('This field is required'),
  confirm_password: yup
    .string()
    .max(128, 'Max amount of symbols is 128')
    .matches(PASSWORD_REGEXP, 'Password must be valid')
    .required('This field is required')
    .oneOf([yup.ref('password')], 'Passwords do not match')
})
