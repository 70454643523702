/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const UploadIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <svg>
        <path
          d="M17.5 11.875V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V11.875C2.5 11.7092 2.56585 11.5503 2.68306 11.4331C2.80027 11.3158 2.95924 11.25 3.125 11.25C3.29076 11.25 3.44973 11.3158 3.56694 11.4331C3.68415 11.5503 3.75 11.7092 3.75 11.875V16.25H16.25V11.875C16.25 11.7092 16.3158 11.5503 16.4331 11.4331C16.5503 11.3158 16.7092 11.25 16.875 11.25C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875Z"
          fill={props.fill || '#1C211B'}
        />
        <path
          d="M9.37486 4.63409L7.31705 6.69268C7.19977 6.80996 7.04071 6.87584 6.87486 6.87584C6.70901 6.87584 6.54995 6.80996 6.43267 6.69268C6.3154 6.5754 6.24951 6.41634 6.24951 6.25049C6.24951 6.08464 6.3154 5.92558 6.43267 5.8083L9.55767 2.6833C9.61572 2.62519 9.68465 2.57909 9.76052 2.54764C9.8364 2.51619 9.91772 2.5 9.99986 2.5C10.082 2.5 10.1633 2.51619 10.2392 2.54764C10.3151 2.57909 10.384 2.62519 10.442 2.6833L13.567 5.8083C13.6843 5.92558 13.7502 6.08464 13.7502 6.25049C13.7502 6.41634 13.6843 6.5754 13.567 6.69268C13.4498 6.80996 13.2907 6.87584 13.1249 6.87584C12.959 6.87584 12.7999 6.80996 12.6827 6.69268L10.6249 4.63409V11.8755C10.6249 12.0413 10.559 12.2002 10.4418 12.3174C10.3246 12.4346 10.1656 12.5005 9.99986 12.5005C9.8341 12.5005 9.67513 12.4346 9.55792 12.3174C9.44071 12.2002 9.37486 12.0413 9.37486 11.8755V4.63409Z"
          fill={props.fill || '#010800'}
        />
      </svg>
    </SvgIcon>
  )
}
