import * as yup from 'yup'

import { EMAIL_REGEXP } from '../constants'

export const loginSchema = yup.object({
  email: yup
    .string()
    .trim()
    .required('Email is a required field')
    .matches(EMAIL_REGEXP, 'Email is not valid'),
  password: yup.string().required('Password is a required field')
})
