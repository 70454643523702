import {
  TInviteSchoolManagerParams,
  TInviteSchoolManagerResponse
} from '@/types/SchoolsManagement'
import { API_METHODS, SCHOOLS_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const schoolsManagement = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    inviteSchoolManager: builder.mutation<
      TInviteSchoolManagerResponse,
      TInviteSchoolManagerParams
    >({
      query: (body) => ({
        url: SCHOOLS_API.SCHOOLS_MANAGER_INVITE,
        method: API_METHODS.POST,
        body
      })
    })
  })
})

export const { useInviteSchoolManagerMutation } = schoolsManagement
