export const downloadFileFromBlob = (blob: Blob, filename: string) => {
  const blobUrl = URL.createObjectURL(blob)

  const a = document.createElement('a')

  a.href = blobUrl

  a.download = filename

  document.body.appendChild(a)

  a.click()

  a.remove()

  URL.revokeObjectURL(blobUrl)
}
