import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Logo, SettingsIcon } from '@/assets/icons'
import { useAppSelector } from '@/hooks'
import { useDefaultPage } from '@/hooks/useDefaultPage'
import { menuPrimaryLinks, PAGES } from '@/routes/pages-routes'
import { theme } from '@/theme'
import { SIDEBAR_DRAWER_WIDTH } from '@/utils/constants'
import { canI, EPermission, ERole } from '@/utils/permissions/permissions'

const classes = {
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  listItem: {
    height: '45px',
    display: 'flex',
    gap: '12px',
    padding: '12px',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
    width: '100%',
    '& .MuiListItemIcon-root': {
      minWidth: 21
    }
  }
}

interface Props {
  open: boolean
}

const Sidebar: React.FC<Props> = (props): JSX.Element => {
  const { open } = props

  const me = useAppSelector((state) => state.userStore.me)
  const role = me?.role as ERole
  const { navigateToDefaultPage } = useDefaultPage()

  const canViewSettings = canI(role, EPermission.VIEW_SETTINGS)

  const navigate = useNavigate()
  const location = useLocation()

  const openedMixin = (theme: Theme): CSSObject => ({
    width: SIDEBAR_DRAWER_WIDTH,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    borderRight: `1px solid ${theme.palette.grey[200]} !important`,
    border: 'unset',
    overflowX: 'hidden'
  })

  const closedMixin = (theme: Theme): CSSObject => ({
    width: '103px',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    borderRight: `1px solid ${theme.palette.grey[200]} !important`,
    border: 'unset',
    overflowX: 'hidden'
  })

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
  })(({ theme, open }) => ({
    flexShrink: 0,
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  }))

  return (
    <Drawer
      variant="permanent"
      sx={{
        bgcolor: theme.palette.common.white,
        display: { xs: 'none', point_894: 'block' }
      }}
      open={open}
    >
      <Box
        sx={{
          bgcolor: theme.palette.common.white,
          borderBottom: `1px solid ${theme.palette.grey[200]}`
        }}
        py="20px"
        minHeight="80px"
        px={open ? '45px' : '20px'}
      >
        <Box
          onClick={() => navigateToDefaultPage()}
          display="flex"
          sx={{ cursor: 'pointer' }}
          alignItems="center"
          justifyContent={open ? 'flex-start' : 'center'}
          gap={open ? '10px' : '0px'}
          height="35px"
        >
          <Logo
            sx={{
              width: '44px',
              height: '40px'
            }}
          />

          <Box
            sx={{
              display: open ? 'flex' : 'none',
              width: '88px'
            }}
          >
            <Typography
              sx={{
                wordBreak: 'break-word',
                width: '88px'
              }}
              width="100%"
              maxWidth="88px"
              variant="h6"
            >
              Australian Dental Health
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          height: '100dvh'
        }}
      >
        <List
          sx={{
            pt: '40px',
            px: open ? '20px' : ''
          }}
        >
          {menuPrimaryLinks
            .filter((link) => canI(role, link.permissionName))
            .map((l) => {
              return (
                <ListItem
                  key={l.name}
                  sx={{
                    ...classes.listItem,
                    p: 0,
                    display: 'flex'
                  }}
                  onClick={() =>
                    navigate(
                      l.name === 'School information'
                        ? `${l.link}/${me?.id}`
                        : l.link
                    )
                  }
                >
                  <Box
                    sx={{
                      ...classes.listItem,
                      width: open ? '100%' : '45px',
                      backgroundColor:
                        location.pathname === l.link
                          ? theme.palette.secondary.light
                          : '',
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        borderRadius: '4px',
                        cursor: 'pointer'
                      }
                    }}
                  >
                    <ListItemIcon>
                      <l.icon
                        sx={{
                          width: '20px',
                          height: '20px'
                        }}
                        fill={
                          location.pathname === l.link
                            ? theme.palette.primary.main
                            : theme.palette.text.secondary
                        }
                      />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={l.name}
                        primaryTypographyProps={{
                          color:
                            location.pathname === l.link
                              ? theme.palette.primary.main
                              : 'text',
                          variant: 'subtitle1'
                        }}
                      />
                    )}
                  </Box>
                </ListItem>
              )
            })}
        </List>

        {canViewSettings && (
          <Box
            position="absolute"
            bottom={0}
            width="100%"
            left={0}
            borderTop={`1px solid ${theme.palette.grey[200]}`}
            pt={3}
            pb={1}
            pr={open ? '32px' : ''}
            pl={open ? '20px' : ''}
            onClick={() => navigate(PAGES.SETTINGS)}
          >
            <ListItem
              sx={{
                ...classes.listItem,
                p: 0,
                m: 0,
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  ...classes.listItem,
                  width: open ? '100%' : '45px',
                  backgroundColor:
                    location.pathname === PAGES.SETTINGS
                      ? theme.palette.secondary.light
                      : '',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.light,
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }
                }}
              >
                <ListItemIcon>
                  <SettingsIcon
                    sx={{
                      width: '20px',
                      height: '20px'
                    }}
                    fill={
                      location.pathname === PAGES.SETTINGS
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary
                    }
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={'Settings'}
                    primaryTypographyProps={{
                      color:
                        location.pathname === PAGES.SETTINGS
                          ? theme.palette.primary.main
                          : 'text',
                      variant: 'subtitle1'
                    }}
                  />
                )}
              </Box>
            </ListItem>
          </Box>
        )}
      </Box>
    </Drawer>
  )
}

export default Sidebar
