import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Paper } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { showError } from '@/helpers/functions'
import { useAppSelector } from '@/hooks'
import { useOfflineMutation } from '@/hooks/useOfflineMuitation'
import { useGetPatientReportAutofillQuery } from '@/redux/services'
import {
  useAddProbonoReportMutation,
  useEditProbonoReportMutation,
  useGetProbonoReportQuery
} from '@/redux/services/probonoReportsApi'
import { PAGES } from '@/routes/pages-routes'
import { dayjs, toastError, toastSuccess } from '@/utils/lib'
import { probonoReportSchema } from '@/utils/yup'

import {
  AddSignature,
  BrushingFocusBlock,
  HealthScreening,
  OralHealth,
  PatientInfoForm
} from '../../components'
import Actions from '../../components/Actions'
import { TreatmentAdvised } from '../../components/TreatmentAdvised'

export type ProbonoReportData = yup.InferType<typeof probonoReportSchema>

const initialValues: ProbonoReportData = {
  dental_assistant: '',
  patient: '',
  clinician: '',
  view_date: '',
  ability_to_brush: '',
  calculus_buildup: '',
  fissure_sealants: '',
  fluoride: '',
  plaque_buildup: '',
  scale_and_clean: '',
  teeth_type_present: '',
  xrays: '',
  fillings: '',
  fillings_comment: '',
  fissure_sealants_comment: '',
  next_dental_visit: '',
  ortho_consult_advised: '',
  ortho_consult_comment: '',
  focus_brushing_areas: [],
  signature: ''
}

type Props = {
  scrollToTop: () => void
}

const ProbonoReport: React.FC<Props> = (props: Props): JSX.Element => {
  const { scrollToTop } = props

  const { patientId, reportId } = useParams()

  const navigate = useNavigate()

  const me = useAppSelector((state) => state.userStore.me)

  const { data: autofillData } = useGetPatientReportAutofillQuery(
    { id: patientId || '' },
    { skip: !patientId, refetchOnReconnect: true }
  )

  const { data: reportData } = useGetProbonoReportQuery(
    { reportId: reportId || '', patientId: patientId || '' },
    { skip: !reportId || !patientId, refetchOnReconnect: true }
  )

  const [createReport, { isLoading: isCreateReportLoading }] =
    useAddProbonoReportMutation()

  const [editReport, { isLoading: isEditReportLoading }] =
    useEditProbonoReportMutation()

  const { control, register, watch, setValue, reset, handleSubmit } =
    useForm<ProbonoReportData>({
      mode: 'onSubmit',
      resolver: yupResolver(probonoReportSchema),
      defaultValues: {
        ...initialValues,
        clinician: me?.id as string,
        view_date: dayjs().format('YYYY-MM-DD'),
        patient: patientId || ''
      }
    })

  const { isOnline, handleOfflineMutation } = useOfflineMutation()

  const isLoading = isEditReportLoading || isCreateReportLoading

  const signedImage = watch('signature')

  const onSubmit = useCallback(
    async (data: ProbonoReportData) => {
      try {
        let responseId = ''
        const payload = reportId
          ? {
              ...data,
              id: reportId
            }
          : data

        if (!isOnline) {
          handleOfflineMutation({
            type: reportId ? 'EditProbonoReport' : 'AddProbonoReport',
            data: payload,
            timestamp: Date.now()
          })

          reset(data)
        } else {
          responseId = reportId
            ? await editReport(payload)
                .unwrap()
                .then((res) => res.data.id || '')
            : await createReport(payload)
                .unwrap()
                .then((res) => res.data.id || '')
          toastSuccess({
            content: `Report ${reportId ? 'updated' : 'created'} successfully`
          })

          if (reportId) {
            reset(data)
          } else {
            navigate(`${PAGES.PROBONO_REPORT}/${patientId}/${responseId}`)
          }
        }

        scrollToTop()
      } catch (error: any) {
        showError(error?.data?.errors)
      }
    },
    [
      createReport,
      editReport,
      handleOfflineMutation,
      isOnline,
      navigate,
      patientId,
      reportId,
      reset,
      scrollToTop
    ]
  )

  const onError: SubmitErrorHandler<ProbonoReportData> = (errors) => {
    toastError({ content: Object.values(errors)[0].message || '' })
  }

  const resetValues = useCallback(() => {
    if (reportData?.data) {
      const resetData = {
        calculus_buildup:
          reportData?.data.calculus_buildup || initialValues.calculus_buildup,
        ability_to_brush:
          reportData?.data.ability_to_brush || initialValues.ability_to_brush,
        plaque_buildup:
          reportData?.data.plaque_buildup || initialValues.plaque_buildup,
        teeth_type_present:
          reportData?.data.teeth_type_present ||
          initialValues.teeth_type_present,
        xrays: reportData?.data.xrays || initialValues.xrays,
        scale_and_clean:
          reportData?.data.scale_and_clean || initialValues.scale_and_clean,
        fluoride: reportData?.data.fluoride || initialValues.fluoride,
        fillings: reportData?.data.fillings || initialValues.fillings,
        fissure_sealants:
          reportData?.data.fissure_sealants || initialValues.fissure_sealants,
        fissure_sealants_comment:
          reportData?.data.fissure_sealants_comment ||
          initialValues.fissure_sealants_comment,
        fillings_comment:
          reportData?.data.fillings_comment || initialValues.fillings_comment,
        ortho_consult_advised:
          reportData?.data.ortho_consult_advised ||
          initialValues.ortho_consult_advised,
        next_dental_visit:
          reportData?.data.next_dental_visit || initialValues.next_dental_visit,
        ortho_consult_comment:
          reportData?.data.ortho_consult_comment ||
          initialValues.ortho_consult_comment,
        focus_brushing_areas:
          reportData?.data.focus_brushing_areas?.map((el) => el.id) ||
          initialValues.focus_brushing_areas,
        signature: initialValues.signature,

        dental_assistant:
          autofillData?.data.dental_assistant || initialValues.dental_assistant
      }
      reset(
        {
          ...resetData,
          patient: patientId || '',
          clinician: (me?.id as string) || '',
          view_date: dayjs().format('YYYY-MM-DD')
        },
        { keepDirty: false }
      )
    }
  }, [
    autofillData?.data.dental_assistant,
    me?.id,
    patientId,
    reportData?.data,
    reset
  ])

  useEffect(() => {
    if (reportData?.data) resetValues()
  }, [reportData?.data, resetValues])

  useEffect(() => {
    if (reportId) return

    if (autofillData?.data) {
      reset({
        ...initialValues,
        view_date: dayjs().format('YYYY-MM-DD'),
        dental_assistant: autofillData?.data.dental_assistant || '',
        patient: patientId,
        clinician: (me?.id as string) || ''
      })
      return
    }
  }, [autofillData?.data, isOnline, me?.id, patientId, reportId, reset])

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSubmit, onError)}
      sx={{ p: '52px 32px', maxWidth: 'xl', mx: 'auto', overflow: 'auto' }}
    >
      {reportId && (
        <Box mb={2} display="flex" justifyContent="flex-end">
          <Actions
            isProbono
            fullName={`${autofillData?.data.full_name || ''}`}
          />
        </Box>
      )}
      <PatientInfoForm
        disabled={isLoading}
        patientFullname={autofillData?.data.full_name || ''}
        birthDate={dayjs(autofillData?.data.date_of_birth || '').format(
          'DD/MM/YYYY'
        )}
        register={register}
        isProbono
      />
      <OralHealth control={control} />
      <HealthScreening />
      <TreatmentAdvised
        control={control}
        register={register}
        disabled={isLoading}
      />
      <BrushingFocusBlock
        setValue={setValue}
        focus_brushing_areas={watch('focus_brushing_areas') || []}
      />
      <AddSignature
        sign={(signatureImage) => setValue('signature', signatureImage)}
        signedImage={signedImage || ''}
      />
      <Box display="flex" justifyContent="center" gap="16px" my="40px">
        <Button
          onClick={resetValues}
          disabled={isLoading}
          variant="contained"
          color="grey200"
          sx={{
            maxWidth: '211px'
          }}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            maxWidth: '211px'
          }}
          fullWidth
          disabled={isLoading || !signedImage}
        >
          Save
        </Button>
      </Box>
    </Paper>
  )
}

export default ProbonoReport
