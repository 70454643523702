/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SignOutIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  const { fill, ...otherProps } = props
  return (
    <SvgIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M11.25 17.375C11.25 17.5408 11.3158 17.6997 11.4331 17.8169C11.5503 17.9342 11.7092 18 11.875 18H16.25C16.5815 18 16.8995 17.8683 17.1339 17.6339C17.3683 17.3995 17.5 17.0815 17.5 16.75V4.25C17.5 3.91848 17.3683 3.60054 17.1339 3.36612C16.8995 3.1317 16.5815 3 16.25 3H11.875C11.7092 3 11.5503 3.06585 11.4331 3.18306C11.3158 3.30027 11.25 3.45924 11.25 3.625C11.25 3.79076 11.3158 3.94973 11.4331 4.06694C11.5503 4.18415 11.7092 4.25 11.875 4.25H16.25V16.75H11.875C11.7092 16.75 11.5503 16.8158 11.4331 16.9331C11.3158 17.0503 11.25 17.2092 11.25 17.375ZM2.68281 10.0578L5.80781 6.93281C5.92509 6.81554 6.08415 6.74965 6.25 6.74965C6.41585 6.74965 6.57491 6.81554 6.69219 6.93281C6.80946 7.05009 6.87535 7.20915 6.87535 7.375C6.87535 7.54085 6.80946 7.69991 6.69219 7.81719L4.63359 9.875H11.875C12.0408 9.875 12.1997 9.94085 12.3169 10.0581C12.4342 10.1753 12.5 10.3342 12.5 10.5C12.5 10.6658 12.4342 10.8247 12.3169 10.9419C12.1997 11.0592 12.0408 11.125 11.875 11.125H4.63359L6.69219 13.1828C6.80946 13.3001 6.87535 13.4591 6.87535 13.625C6.87535 13.7909 6.80946 13.9499 6.69219 14.0672C6.57491 14.1845 6.41585 14.2503 6.25 14.2503C6.08415 14.2503 5.92509 14.1845 5.80781 14.0672L2.68281 10.9422C2.6247 10.8841 2.5786 10.8152 2.54715 10.7393C2.5157 10.6635 2.49951 10.5821 2.49951 10.5C2.49951 10.4179 2.5157 10.3365 2.54715 10.2607C2.5786 10.1848 2.6247 10.1159 2.68281 10.0578Z"
        fill={fill || '#1C211B'}
      />
    </SvgIcon>
  )
}
