import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TUserStateMe } from '@/types/Me'

import { authApi } from '../services/authApi'

interface InitialState {
  isLogin: boolean
  accessToken: string | null
  refreshToken: string | null
  me: TUserStateMe | null
}

const initialState: InitialState = {
  isLogin: false,
  accessToken: null,
  refreshToken: null,
  me: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },
    setMe: (state, action: PayloadAction<TUserStateMe>) => {
      state.me = action.payload
    },
    updateteMePartially: (
      state,
      action: PayloadAction<Partial<TUserStateMe>>
    ) => {
      if (state.me) state.me = { ...state.me, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.loginUser.matchFulfilled,
      (state, action) => {
        state.refreshToken = action.payload.data.refresh
        state.accessToken = action.payload.data.access
        state.isLogin = true
      }
    )
  }
})

export const { logout, setAccessToken, setMe, updateteMePartially } =
  userSlice.actions

export default userSlice.reducer
