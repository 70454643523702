import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  paperClasses,
  styled,
  Typography,
  typographyClasses
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DropdownIcon, GearSixIcon, SignOutIcon } from '@/assets/icons'
import { formatUserInitials, formatUserName } from '@/helpers/functions/user'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { logout } from '@/redux/features/userSlice'
import { PAGES } from '@/routes/pages-routes'
import { theme } from '@/theme'
import { ERole } from '@/utils/permissions/permissions'

const StyledDivider = styled(Divider)({
  margin: '8px 0',
  backgroundColor: theme.palette['grey'][200]
})

export default function PrivatePagesHeaderProfileMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const me = useAppSelector((state) => state.userStore.me)
  const isMeSchool = me?.role === ERole.School

  const dispatch = useAppDispatch()

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <>
      <Box
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          marginLeft: 'auto',
          maxWidth: '88px',
          width: '100%',
          minWidth: 'fit-content',
          height: { xs: '40px', md: '48px' },
          borderRadius: '100px',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          padding: { md: '4px 12px', xs: 0 },
          backgroundColor: { xs: 'none', md: theme.palette.secondary.light },
          cursor: 'pointer'
        }}
      >
        <Avatar
          variant="circular"
          src={me?.photo || undefined}
          sx={{
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            width: '40px',
            height: '40px',

            backgroundColor: { xs: theme.palette.secondary.light, md: 'none' }
          }}
        >
          {isMeSchool ? '' : formatUserInitials(me?.first_name, me?.last_name)}
        </Avatar>
        <IconButton sx={{ marginLeft: 'auto', backgroundColor: 'transparent' }}>
          <DropdownIcon
            sx={{
              width: '24px',
              height: '24px',
              transform: `rotate(${open ? -180 : 0}deg)`,
              transition: theme.transitions.create(['transform'])
            }}
          />
        </IconButton>
      </Box>
      <Menu
        disableAutoFocusItem
        sx={{
          [`.${paperClasses.root}`]: {
            minWidth: '260px',
            width: '100%',
            maxWidth: 'fit-content',
            marginTop: '4px',
            border: `1px solid ${theme.palette.grey200.main}`,
            borderRadius: '4px',
            boxShadow: '0px 4px 12px 0px rgba(224, 228, 233, 0.40)'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        id="basic-menu"
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Box
          sx={{
            paddingTop: 0,
            paddingX: '8px',
            paddingBottom: 0
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h5" fontSize={'16px'}>
              {isMeSchool
                ? (me?.name ?? ' ')
                : formatUserName(me?.first_name, me?.last_name)}
            </Typography>
            <Typography
              sx={{
                lineHeight: '24px',
                variant: 'body2',
                fontFamily: 'Inter',
                wordBreak: 'break-all',
                maxWidth: '320px',
                color: theme.palette.secondary.main
              }}
            >
              {me?.email}
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                color: theme.palette.primary.main
              }}
            >
              {me?.role}
            </Typography>
          </Box>
        </Box>

        <StyledDivider />

        <MenuItem
          sx={{
            borderRadius: '4px',
            width: '100%',
            padding: '12px',
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
              [`.${typographyClasses.root}`]: {
                color: theme.palette.primary.main
              }
            }
          }}
          onClick={() => {
            navigate(PAGES.PROFILE)
            handleClose()
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
              alignItems: 'center'
            }}
          >
            <GearSixIcon
              sx={{
                height: '20px',
                width: '20px',
                path: {
                  fill: 'currentcolor'
                }
              }}
            />
            <Typography variant="h6">Profile settings</Typography>
          </Box>
        </MenuItem>

        <StyledDivider />

        <MenuItem
          sx={{
            borderRadius: '4px',
            width: '100%',
            padding: '12px',
            '&:hover': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.main,
              [`.${typographyClasses.root}`]: {
                color: theme.palette.error.main
              }
            }
          }}
          onClick={handleLogout}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
              alignItems: 'center'
            }}
          >
            <SignOutIcon
              sx={{
                height: '20px',
                width: '20px',
                path: {
                  fill: 'currentcolor'
                }
              }}
            />
            <Typography variant="h6">Log out</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}
