import { Box, Skeleton } from '@mui/material'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface Props {
  src: string
  alt: string
  width?: string
  height?: string
}

export default function SkeletonLoadImage(props: Props) {
  const { src, alt, width = '100%', height = '100%' } = props

  const [loading, setLoading] = useState(true)

  const handleImageLoad = () => {
    setLoading(false)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {loading && <Skeleton variant="rounded" width={width} height={height} />}
      <LazyLoadImage
        style={{
          objectFit: 'contain'
        }}
        src={src}
        alt={alt}
        width={'100%'}
        height={'100%'}
        onLoad={handleImageLoad}
        loading="lazy"
        threshold={100}
      />
    </Box>
  )
}
