import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormTrigger
} from 'react-hook-form'
import toast from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'

import { UploadFiles } from '@/components/UI'
import { toastLoading } from '@/components/UI/Loader/ToastPromise'
import PreviewFileExtra from '@/components/UI/PreviewFiles/PreviewFileExtra'
import { IAction } from '@/components/UI/PreviewFiles/PreviewFileExtra/components/Actions'
import BasicTooltip from '@/components/UI/Tooltips/BasicTooltip'
import {
  byteConverter,
  downloadFileFromBlob,
  fileExtensionResolver,
  getFileNameFromString,
  getHookFormErrorMessage,
  showError
} from '@/helpers/functions'
import { useLazyGetCDBSFileBlobQuery } from '@/redux/services'
import { theme } from '@/theme'
import { SCHOOL_ACCEPT_FILES } from '@/utils/constants'
import { toastSuccess } from '@/utils/lib'

import { CdbsReportData } from '../cdbs/components/CdbsReport'

interface Props {
  control: Control<CdbsReportData>
  trigger: UseFormTrigger<CdbsReportData>
  errors: FieldErrors<CdbsReportData>
}

export const UploadFileBlock: React.FC<Props> = (props): JSX.Element => {
  const { errors, control, trigger } = props

  const { breakpoints } = theme

  const [getFileBlob] = useLazyGetCDBSFileBlobQuery()

  const { fields, prepend, update, remove } = useFieldArray({
    control,
    name: 'files',
    keyName: 'local_id'
  })

  const generateFileActions = (file: any, index: number): IAction[] => {
    const actions: IAction[] = [
      {
        name: 'delete',
        title: 'Delete',
        onClick: () => remove(index)
      },
      {
        name: 'download',
        title: 'Download',
        onClick: async () => {
          const retrieveToast = toastLoading({
            content: 'Downloading file...',
            duration: Infinity
          })
          try {
            const blob = await getFileBlob({ id: file.id }).unwrap()
            downloadFileFromBlob(blob, file.name)
            toastSuccess({ content: 'File download has started' })
          } catch (e: any) {
            showError(e?.data.errors)
          } finally {
            toast.dismiss(retrieveToast)
          }
        }
      }
    ]

    if (typeof file.file !== 'string') return [actions[0]]

    return actions
  }

  return (
    <Box
      mb={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative'
      }}
    >
      <Grid
        container
        spacing="16px"
        sx={{
          listStyle: 'none',
          [breakpoints.down('md')]: {
            marginTop: '20px'
          }
        }}
      >
        <Grid container item spacing={2} xs={12}>
          {fields.map((item, i) => (
            <Grid key={item.id} item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <PreviewFileExtra
                  file={{
                    id: item.id || '',
                    file: item.file || '',
                    metadata: {
                      // eslint-disable-next-line max-len
                      name: `${getFileNameFromString(item?.name || '')}.${fileExtensionResolver(item.extension!, item.name!)?.toUpperCase()}`,
                      extension: fileExtensionResolver(
                        item.extension!,
                        item.name!
                      ),
                      size: byteConverter(Number(item.size) || 0),
                      created_at: item.created_at
                    }
                  }}
                  checkboxLabel={'Add as attachment'}
                  CheckboxProps={{
                    checked: item.add_as_attachment,
                    onChange: (e) => {
                      update(
                        fields.findIndex((f) => f.id === item.id),
                        {
                          ...item,
                          id: item.id,
                          add_as_attachment: e.target.checked
                        }
                      )
                    }
                  }}
                  actions={generateFileActions(item, i)}
                  helperText={getHookFormErrorMessage(
                    errors?.files?.[i] as FieldErrors,
                    'file'
                  )}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <UploadFiles
        accept={SCHOOL_ACCEPT_FILES}
        tooltip={
          <BasicTooltip
            hoverContent={
              <Typography
                sx={{
                  lineHeight: '30px',
                  whiteSpace: 'pre-line'
                }}
              >
                Allowed file extensions:
                <br />
                images - jpg, jpeg, png
                <br />
                videos - mov, mp4, avi
                <br />
                doc
                <br />
                excel
                <br />
                csv
                <br />
                pdf
              </Typography>
            }
          />
        }
        multiple={true}
        sx={{
          marginTop: '20px'
        }}
        onChange={(e) => {
          if (!e.target.files) return

          Object.values(e.target.files).forEach((file, i) => {
            prepend({
              id: uuidv4(),
              file,
              name: file.name,
              extension: file.name.split('.').pop() || '',
              size: String(file.size),
              created_at: new Date().toDateString(),
              add_as_attachment: false
            })

            trigger(`files.${i}.file`)
          })
        }}
      />
    </Box>
  )
}
