import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { theme } from '@/theme'

import { CdbsReportData } from '../cdbs/components/CdbsReport'

interface Props {
  control: Control<CdbsReportData>
}

const options = [
  {
    key: 'next_dental_visit',
    options: [
      { name: 'ASAP', value: 'asap' },
      { name: '3 months', value: '3_months' },
      { name: '6 months', value: '6_months' },
      { name: '12 months', value: '12_months' }
    ]
  }
]

export const NextDentalVisit: React.FC<Props> = (props): JSX.Element => {
  const { control } = props

  return (
    <Grid mb={5}>
      <Typography color={theme.palette.primary.main} variant="h5">
        Next dental visit:
        <Typography
          component="span"
          variant="inherit"
          sx={{
            color: theme.palette.error.main
          }}
        >
          *
        </Typography>
      </Typography>
      <Box>
        {options.map((el) => (
          <Controller
            key={el.key}
            control={control}
            name={el.key as keyof CdbsReportData}
            render={({ field }) => (
              <Grid
                container
                item
                width="100%"
                xs={12}
                lg={12}
                display="flex"
                alignItems="center"
              >
                <Grid item xs={12} lg={12}>
                  <RadioGroup
                    row
                    sx={{
                      mt: 1,
                      display: 'flex',
                      width: '100%'
                    }}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {el.options.map((r, i) => (
                      <Grid key={i} mt={2} item xs={12} sm={6} lg={3}>
                        <FormControlLabel
                          value={r.value}
                          control={<Radio />}
                          label={r.name}
                          componentsProps={{
                            typography: {
                              sx: {
                                fontSize: '16px',
                                fontWeight: 600
                              }
                            }
                          }}
                        />
                      </Grid>
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
          />
        ))}
      </Box>
      <Box mt="20px" borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Grid>
  )
}
