import toast from 'react-hot-toast'

import { toastLoading } from '@/components/UI/Loader/ToastPromise'
import { showError } from '@/helpers/functions'
import { CdbsReportData } from '@/pages/private/reports/cdbs/components/CdbsReport'
import {
  useAddReportFileMutation,
  useDeleteReportFileMutation,
  usePatchReportFileMutation
} from '@/redux/services'
import { ICDBSReportFiles, TProbonoCDBSResponse } from '@/types'

export const useUpdateCdbsFiles = () => {
  const [addReportFile, { isLoading: isUploadingFiles }] =
    useAddReportFileMutation()
  const [deleteReportFile, { isLoading: isDeletingFiles }] =
    useDeleteReportFileMutation()
  const [updateReportFile, { isLoading: isUpdatingFiles }] =
    usePatchReportFileMutation()

  const handleFileChanges = async (
    oldFiles: TProbonoCDBSResponse['data']['files'],
    files: CdbsReportData['files'],
    reportId: string
  ) => {
    const filesToUpdate: Pick<ICDBSReportFiles, 'id' | 'add_as_attachment'>[] =
      []
    const filesToUpload: CdbsReportData['files'] = []
    const filesToDelete: string[] = []

    files?.forEach((file) => {
      if (typeof file.file !== 'string') {
        filesToUpload.push(file)
      }
    })

    oldFiles.map((el) => {
      files?.find((e) => e.id === el.id)
        ? files?.find((e) => e.id === el.id)?.add_as_attachment !==
            el.add_as_attachment &&
          filesToUpdate.push({
            add_as_attachment: !el.add_as_attachment,
            id: el.id
          })
        : filesToDelete.push(el.id)
    })

    if (filesToDelete.length > 0) {
      await Promise.all(
        filesToDelete.map(async (fileId) => {
          try {
            await deleteReportFile({ id: fileId }).unwrap()
          } catch (error: any) {
            showError(error?.data?.errors)
          }
        })
      )
    }

    if (filesToUpload.length > 0) {
      const uploadToast = toastLoading({
        content: 'Uploading files...',
        duration: Infinity
      })

      await Promise.all(
        filesToUpload?.map(async (file) => {
          const formData = new FormData()

          formData.append('report', reportId)
          file.file && formData.append('file', file.file)
          formData.append(
            'add_as_attachment',
            JSON.stringify(file.add_as_attachment)
          )
          file.name && formData.append('name', file.name)
          file.extension && formData.append('extension', file.extension)
          file.size && formData.append('size', file.size)

          try {
            await addReportFile({ file: formData }).unwrap()
          } catch (error: any) {
            showError(error?.data?.errors)
          }
        })
      ).then(() => toast.dismiss(uploadToast))
    }

    if (filesToUpdate.length > 0) {
      const updateToast = toastLoading({
        content: 'Uploading files...',
        duration: Infinity
      })

      await Promise.all(
        filesToUpdate?.map(async (file) => {
          const formData = new FormData()

          formData.append(
            'add_as_attachment',
            JSON.stringify(file.add_as_attachment)
          )

          try {
            await updateReportFile({
              body: formData,
              id: file.id
            }).unwrap()
          } catch (error: any) {
            showError(error?.data?.errors)
          }
        })
      ).then(() => toast.dismiss(updateToast))
    }
  }

  const isLoading = isDeletingFiles || isUploadingFiles || isUpdatingFiles
  return { handleFileChanges, isLoading }
}
