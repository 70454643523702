import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAppSelector } from '@/hooks'
import { useDefaultPage } from '@/hooks/useDefaultPage'
import ChangePasswordPage from '@/pages/private/change-password'
import { canI, EPermission } from '@/utils/permissions/permissions'

import { PAGES } from './pages-routes'

interface Props {
  component: React.ComponentType
  permissionName: null | EPermission
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  permissionName
}) => {
  const isLogin = useAppSelector((state) => state.userStore.isLogin)
  const me = useAppSelector((state) => state.userStore.me)

  const { getDefaultPagePath } = useDefaultPage()

  if (!isLogin || !me) return <Navigate to={PAGES.LOGIN} />

  if (me.is_password_change_required) {
    return <ChangePasswordPage />
  }

  const hasPermission =
    !permissionName || (permissionName && canI(me.role, permissionName))

  return hasPermission ? (
    <RouteComponent />
  ) : (
    <Navigate to={getDefaultPagePath()} />
  )
}
