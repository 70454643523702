import * as yup from 'yup'

import { byteConverter } from '@/helpers/functions'

import {
  MAX_NAME_LENGTH,
  MAX_UPLOAD_FILE_SIZE,
  MIN_UPLOAD_FILE_SIZE
} from '../constants'

export const filesValidation = yup.array().of(
  yup.lazy((value) =>
    yup.object({
      id: yup.string(),
      name: yup.string(),
      extension: yup.string(),
      size: yup.string(),
      created_at: yup.string(),
      add_as_attachment: yup.boolean(),
      file: yup.mixed<File | string>().when([], {
        is: () => value.file instanceof File,
        then: (schema) =>
          schema.test(
            'test-file-size',
            (params) => {
              const size = params.value.size

              const condition =
                size < MIN_UPLOAD_FILE_SIZE
                  ? 'less'
                  : size > MAX_UPLOAD_FILE_SIZE && 'more'

              return `This file size is ${condition} than ${byteConverter(
                condition === 'less'
                  ? MIN_UPLOAD_FILE_SIZE
                  : MAX_UPLOAD_FILE_SIZE
              )}`
            },
            async () => {
              const file = value.file

              return !(
                file.size < MIN_UPLOAD_FILE_SIZE ||
                file.size > MAX_UPLOAD_FILE_SIZE
              )
            }
          ),
        otherwise: (schema) => schema
      })
    })
  )
)

export const cdbsReportSchema = yup.object({
  dental_assistant: yup
    .string()
    .max(MAX_NAME_LENGTH, 'Max amount of symbols is 30'),
  clinician: yup.string().required(),
  view_date: yup.string().required(),
  focus_brushing_areas: yup.array().of(yup.string().required()).min(0),
  signature: yup.string().required(),
  teeth_type_present: yup
    .string()
    .required('Teeth type present is required field'),

  plaque_buildup: yup.string().required('Plaque build up is required field'),
  calculus_buildup: yup
    .string()
    .required('Calculus build up is required field'),
  holes_decay_present: yup
    .string()
    .required('Holes/Decay present is required field'),
  ability_to_brush: yup
    .string()
    .required('Ability to brush own teeth is required field'),
  ortho_consult_advised: yup
    .string()
    .required('Orthodontic consult advised is required field'),
  next_dental_visit: yup
    .string()
    .required('Next dental visit is required field'),
  treatments_completed: yup.array().of(yup.string().required()),
  treatment_plan: yup.array().of(
    yup.object({
      id: yup.string().defined(),
      treatment_code: yup.string().defined(),
      price: yup.number().defined(),
      description: yup.string(),
      quantity: yup.number().defined(),
      tooth_number: yup.number()
    })
  ),
  additional_notes: yup.string(),
  files: filesValidation
})
