import { Box } from '@mui/material'
import React, { ReactNode, useCallback, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { EFormsicon, PatientsIcon } from '@/assets/icons'
import { TabItem } from '@/components'
import { Back } from '@/components/UI'
import { PAGES } from '@/routes/pages-routes'
import { ReportTypeEnum, TabOption } from '@/types'

import CDBSReport from '../../reports/cdbs/components/CdbsReport'
import ProbonoReport from '../../reports/probono/components/ProbonoReport'

interface Props {
  children: ReactNode
}

type Tab = 'report' | 'patient-card'

const tabsOptions: TabOption<Tab>[] = [
  { icon: PatientsIcon, title: 'Patient’s card', id: 'patient-card' },
  {
    icon: EFormsicon,
    title: 'Report',
    id: 'report'
  }
]

export const PatientWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const [searchParams] = useSearchParams()

  const [selectedTab, setSelectedTab] = useState<Tab>('patient-card')

  const report_type = searchParams.get('report_type')

  const navigate = useNavigate()

  const topBoxRef = useRef<HTMLDivElement | null>(null)

  const scrollToTop = useCallback(() => {
    topBoxRef?.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }, [topBoxRef])

  const content = {
    report:
      report_type === ReportTypeEnum.CDBS ? (
        <CDBSReport scrollToTop={scrollToTop} />
      ) : (
        <ProbonoReport scrollToTop={scrollToTop} />
      ),
    'patient-card': children
  }

  return (
    <Box ref={topBoxRef}>
      <Box px={4} pb={6}>
        <Back
          sx={{
            marginBottom: '28px'
          }}
          onClick={() => navigate(PAGES.PATIENTS)}
        >
          Back to patient's page
        </Back>
        {report_type && (
          <Box display="flex" maxWidth="xl" mx="auto" gap={1.5}>
            {tabsOptions.map((tab) => (
              <Box
                sx={{ width: 'fit-content' }}
                key={tab.id}
                onClick={() => setSelectedTab(tab.id)}
              >
                <TabItem
                  active={tab.id === selectedTab}
                  Icon={tab.icon}
                  title={tab.title}
                />
              </Box>
            ))}
          </Box>
        )}
        <Box mt="20px">{content[selectedTab]}</Box>
      </Box>
    </Box>
  )
}
