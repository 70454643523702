import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  inputBaseClasses,
  TextField,
  Typography
} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import { CalendarIcon } from '@/assets/icons'
import { formatUserName } from '@/helpers/functions/user'
import { useAppSelector } from '@/hooks'
import { theme } from '@/theme'
import { SYSTEM_DATE_FORMAT } from '@/utils/constants'

interface Props {
  register: UseFormRegister<any>
  disabled: boolean
  isProbono?: boolean
  patientFullname: string
  birthDate: string
}

export const PatientInfoForm: React.FC<Props> = (props): JSX.Element => {
  const { register, disabled, isProbono, patientFullname, birthDate } = props

  const me = useAppSelector((state) => state.userStore.me)

  return (
    <Box mb={5}>
      <Box mb={1}>
        <Typography variant="h3">
          {isProbono
            ? 'Your child’s dental health screening report'
            : 'Your child’s oral health report'}
        </Typography>
        {isProbono ? (
          <Typography mt={1} fontSize={16}>
            Dear Parent/Guardian,
            <br />
            <br />
            Your child was seen at school today for a free limited dental health
            screening by Australian Dental Health VIC . Below you will find a
            report outlining any advised treatment for your child.
            <br />
            <br />
            Please contact Australian Dental Health VIC on (03) 9323 9607 if you
            have any questions regarding your child’s report.
          </Typography>
        ) : (
          <Typography mt={1} fontSize={16}>
            Your child was seen at school today for their dental check up as
            part of the Australian Dental Health VIC school dental initiative.
            Below is a report letting you know about your child’s oral health,
            and any treatment we have completed today with your consent. If your
            child requires any further dental treatment, and we were not able to
            contact you during our checkup today for consent you will find a
            Treatment Plan on the reverse of this page.
            <br />
            <br />
            Please contact Australian Dental Health VIC on (03) 9323 9607 if or
            report you have any questions regarding your child’s treatment
          </Typography>
        )}
      </Box>
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="patient-fullname">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography variant="inherit">
                  Patient fullname
                  <Typography
                    component="span"
                    variant="inherit"
                    sx={{
                      color: theme.palette.error.main
                    }}
                  >
                    *
                  </Typography>
                </Typography>
              </Box>
            </FormLabel>
            <TextField
              id="patient-fullname"
              variant="outlined"
              color="primary"
              autoComplete="none"
              placeholder="Enter patient full name"
              type="text"
              value={patientFullname}
              InputProps={{
                readOnly: true
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="birth_date">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '5.12px'
                }}
              >
                <Typography variant="inherit">
                  Date of birth
                  <Typography
                    component="span"
                    variant="inherit"
                    sx={{
                      color: theme.palette.error.main
                    }}
                  >
                    *
                  </Typography>
                </Typography>
              </Box>
            </FormLabel>
            <TextField
              id="birth_date"
              variant="outlined"
              color="primary"
              disabled={disabled}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarIcon
                      sx={{
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  </InputAdornment>
                )
              }}
              autoComplete="off"
              placeholder={SYSTEM_DATE_FORMAT}
              value={birthDate}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="clinician">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography variant="inherit">
                  Clinician
                  <Typography
                    component="span"
                    variant="inherit"
                    sx={{
                      color: theme.palette.error.main
                    }}
                  >
                    *
                  </Typography>
                </Typography>
              </Box>
            </FormLabel>
            <TextField
              id="clinician"
              variant="outlined"
              color="primary"
              autoComplete="none"
              placeholder="Enter clinician name"
              type="text"
              value={formatUserName(me?.first_name, me?.last_name)}
              InputProps={{
                readOnly: true
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="view-date">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '5.12px'
                }}
              >
                <Typography variant="inherit">
                  View date{' '}
                  <Typography
                    component="span"
                    variant="inherit"
                    sx={{
                      color: theme.palette.error.main
                    }}
                  >
                    *
                  </Typography>
                </Typography>
              </Box>
            </FormLabel>
            <TextField
              id="view-date"
              variant="outlined"
              color="primary"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarIcon
                      sx={{
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  </InputAdornment>
                )
              }}
              autoComplete="off"
              sx={{
                [`& .${inputBaseClasses.root}`]: {
                  padding: '0 14px'
                }
              }}
              placeholder={SYSTEM_DATE_FORMAT}
              value={dayjs().format('DD/MM/YYYY')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="dental_assistant">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '4px'
                }}
              >
                <Typography variant="inherit">Dental assistant</Typography>
              </Box>
            </FormLabel>
            <TextField
              id="dental_assistant"
              variant="outlined"
              color="primary"
              autoComplete="none"
              placeholder="Enter dental assistant full name"
              type="text"
              {...register('dental_assistant')}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
