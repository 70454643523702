import {
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  Typography
} from '@mui/material'

import { DoubleArrowIcon } from '@/assets/icons'
import { TableBodyItems, TableHeadCell, TableHeadCells } from '@/types/Table'

export interface Props<T> {
  rows: TableBodyItems
  cells: TableHeadCells
  sortBy?: string
  handleSort?: (name: TableHeadCell<T>['name']) => void
}

function TableHead<T extends TableBodyItems<T>>(props: Props<T>) {
  const { rows, cells, sortBy, handleSort } = props

  return (
    <MuiTableHead
      sx={{
        position: 'sticky',
        zIndex: 1
      }}
    >
      <TableRow>
        {cells.map(
          (cell, index) =>
            (typeof cell.visible === 'function'
              ? cell.visible(rows[index])
              : true) && (
              <TableCell
                key={cell.name}
                align={cell.align || 'left'}
                sx={{
                  width: cell.width,
                  minWidth: cell.minWidth,
                  paddingTop: '19px',
                  paddingBottom: '19px',
                  whiteSpace: 'nowrap'
                }}
                padding="none"
              >
                {cell.sortable ? (
                  <Typography
                    onClick={() =>
                      typeof handleSort === 'function' && handleSort(cell.name)
                    }
                    variant="caption"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '8.2px',
                      cursor: 'pointer'
                    }}
                  >
                    {cell.label}
                    <DoubleArrowIcon
                      sx={{
                        width: '3.6px',
                        height: '9.6px',
                        ...(sortBy && sortBy.replace('-', '') === cell.name
                          ? sortBy[0] === '-'
                            ? {
                                'path:first-of-type': {
                                  fill: 'transparent'
                                }
                              }
                            : {
                                'path:last-of-type': {
                                  fill: 'transparent'
                                }
                              }
                          : {})
                      }}
                    />
                  </Typography>
                ) : (
                  <Typography variant="caption">{cell.label}</Typography>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </MuiTableHead>
  )
}

export default TableHead
