import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import { theme } from '@/theme'
import { IGetPatient } from '@/types'

interface Props {
  school_info: IGetPatient['school_info']
}

export const SchoolInformation: React.FC<Props> = (props): JSX.Element => {
  const { school_info } = props

  const schoolInfo = [
    { name: 'School', value: school_info.name },
    { name: 'Year level', value: school_info.year_level },
    { name: 'Class', value: school_info.class }
  ]

  return (
    <Box mb={5}>
      <Box mb="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          School information
        </Typography>
      </Box>
      <Grid
        mb="20px"
        container
        xs={12}
        item
        spacing={2}
        display="flex"
        flexWrap="wrap"
      >
        {schoolInfo.map((el) => (
          <Grid
            item
            key={el.name}
            md={3}
            xs={12}
            gap={1}
            display="flex"
            minWidth="200px"
            flexDirection="column"
          >
            <Typography
              variant="body1"
              fontSize={'16px'}
              color={theme.palette.secondary.main}
            >
              {el.name}
            </Typography>
            <Typography variant="h5">{el.value}</Typography>
          </Grid>
        ))}
      </Grid>
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
