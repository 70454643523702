import * as yup from 'yup'

import { MAX_NAME_LENGTH } from '../constants'

export const probonoReportSchema = yup.object({
  dental_assistant: yup
    .string()
    .max(MAX_NAME_LENGTH, 'Max amount of symbols is 30'),
  clinician: yup.string().required(),
  patient: yup.string().required(),
  view_date: yup.string().required(),
  teeth_type_present: yup
    .string()
    .required('Teeth type present is required field'),
  plaque_buildup: yup.string().required('Plaque build up is required field'),
  calculus_buildup: yup
    .string()
    .required('Calculus build up is required field'),
  ability_to_brush: yup
    .string()
    .required('Ability to brush own teeth is required field'),
  xrays: yup.string().required('X-rays is required field'),
  scale_and_clean: yup.string().required('Scale and clean is required field'),
  fluoride: yup.string().required('Fluoride is required field'),
  fissure_sealants: yup.string().required('Fissure sealants is required field'),
  fillings_comment: yup.string(),
  fillings: yup.string().required('Fillings is required field'),
  fissure_sealants_comment: yup.string(),
  ortho_consult_advised: yup
    .string()
    .required('Orthodontic consult is required field'),
  ortho_consult_comment: yup.string(),
  next_dental_visit: yup
    .string()
    .required('Next dental visit is required field'),
  focus_brushing_areas: yup.array().of(yup.string().required()).min(0),
  signature: yup.string()
})
