import {
  EFormsicon,
  GearSixIcon,
  PatientsIcon,
  SchoolIcon,
  StaffIcon
} from '@/assets/icons'
import { EPermission } from '@/utils/permissions/permissions'

export const enum PAGES {
  // Public
  ENTRY = '/*',
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  CHANGE_PASSWORD = '/change-password',

  // Private
  SCHOOLS = '/schools',
  ADD_SCHOOL = '/add-school',
  EDIT_SCHOOL = '/edit-school',
  SCHOOL_DETAILS = '/school-details',
  EFORMS = '/e-forms',
  EFORM = '/e-form',
  EFORM_SUBMITED = '/e-form-submited',
  PATIENTS = '/patients',
  PATIENTS_ADD = '/patients/add',
  PATIENTS_DETAILS = '/patients-details',
  STAFF = '/staff',
  ADD_STAFF = '/add-staff',
  EDIT_STAFF = '/edit-staff',
  SETTINGS = '/settings',

  PROFILE = '/profile',
  PROBONO_REPORT = '/probono-report',
  CDBS_REPORT = '/cdbs-report'
}

export const menuPrimaryLinks = [
  {
    name: 'School information',
    link: `${PAGES.SCHOOL_DETAILS}`,
    icon: SchoolIcon,
    permissionName: EPermission.VIEW_SCHOOL_INFORMATION_SIDEBARTAB
  },
  {
    name: 'Schools',
    link: PAGES.SCHOOLS,
    icon: SchoolIcon,
    permissionName: EPermission.VIEW_SCHOOLS
  },
  {
    name: 'E-forms',
    link: PAGES.EFORMS,
    icon: EFormsicon,
    permissionName: EPermission.VIEW_EFORMS
  },
  {
    name: 'Patients',
    link: PAGES.PATIENTS,
    icon: PatientsIcon,
    permissionName: EPermission.VIEW_PATIENTS
  },
  {
    name: 'Staff',
    link: PAGES.STAFF,
    icon: StaffIcon,
    permissionName: EPermission.VIEW_STAFF
  }
]

export const settingsLink = {
  name: 'Settings',
  link: `${PAGES.SETTINGS}`,
  icon: GearSixIcon,
  permissionName: EPermission.VIEW_SETTINGS
}

export const showNavigationMenuPages = [
  PAGES.PROFILE,
  PAGES.EDIT_SCHOOL,
  PAGES.ADD_SCHOOL,
  PAGES.ADD_STAFF,
  PAGES.EDIT_STAFF,
  PAGES.PATIENTS_ADD
]

export const mobileSupportedPages = [PAGES.EFORM, PAGES.EFORM_SUBMITED]
