/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CheckIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8591 1.2186L4.17944 9.51428C4.13486 9.56248 4.08192 9.60073 4.02365 9.62682C3.96539 9.65291 3.90293 9.66634 3.83985 9.66634C3.77678 9.66634 3.71432 9.65291 3.65605 9.62682C3.59778 9.60073 3.54484 9.56248 3.50027 9.51428L0.140416 5.88492C0.050353 5.78763 -0.000244141 5.65568 -0.000244141 5.51809C-0.000244141 5.38051 0.050353 5.24856 0.140416 5.15127C0.23048 5.05398 0.352632 4.99933 0.480001 4.99933C0.60737 4.99933 0.729523 5.05398 0.819586 5.15127L3.83985 8.41445L11.1799 0.484951C11.27 0.387663 11.3921 0.333008 11.5195 0.333008C11.6469 0.333008 11.769 0.387663 11.8591 0.484951C11.9492 0.582239 11.9998 0.71419 11.9998 0.851776C11.9998 0.989362 11.9492 1.12131 11.8591 1.2186Z"
        fill="#32B22B"
      />
    </SvgIcon>
  )
}
