export const enum API_METHODS {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  PUT = 'PUT'
}

export const enum SCHOOLS_API {
  SCHOOLS = '/api/schools/',
  SCHOOLS_FILES = '/api/schools/files/',
  SCHOOLS_FILTER = '/api/schools/filter/',
  SCHOOLS_TEMPLATE_DOWNLOAD = '/api/schools/template/download/',
  SCHOOLS_UPLOAD = '/api/schools/upload/',
  SCHOOLS_MANAGER_INVITE = '/api/schools/manager/invite/',
  SCHOOLS_CONTACTS = '/api/schools/contacts/',
  SCHOOLS_QUESTIONS = '/api/schools/questions/',
  SCHOOLS_SETTINGS = '/api/schools/global/'
}

export const enum PATIENTS_API {
  PATIENTS = '/api/patients/',
  PATIENTS_EFORMS = '/api/patients/eforms/',
  PATIENTS_SEND_REPORTS = '/api/patients/send-reports/',
  REFRESH = '/api/patients/eforms/refresh/',
  NEXT_REFRESH = '/api/patients/eforms/next_refresh_possible/',
  FILES = '/api/patients/files/'
}

export const enum AUTH_API {
  TOKEN = '/api/token/',
  REFRESH = '/api/token/refresh/'
}

export const enum EFORM_API {
  FORMS = '/api/eforms/forms/',
  SUBMIT = '/api/eforms/submit/'
}

export const enum REPORTS {
  MOUTH_PARTS = '/api/reports/mouth-parts/',
  COMPLETED_TREATMENTS = '/api/reports/completed-treatments/',
  FILES = '/api/reports/cdbs/files/'
}

export const enum USERS {
  ME = '/api/users/me'
}

export const enum TREATMENT_CODES_API {
  ALL = '/api/treatment-codes/'
}

export const enum USERS_API {
  ME = '/api/users/me/',
  CHANGE_PASSWORD = '/api/users/me/change-password/',
  RESET_PASSWORD = 'api/users/me/reset-password/',
  RESET_PASSWORD_CONFIRM = 'api/users/me/reset-password/confirm/'
}

export const enum STAFF_API {
  STAFF = '/api/users/staff/',
  ME = '/api/users/staff/me/'
}
