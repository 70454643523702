import { Box, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

import { CheckIcon, CrossIcon, PencilIcon } from '@/assets/icons'

interface Props {
  isHover: boolean
  tooth_number: number | undefined
  id: string
  handleTreatmentPlanChange: (
    id: string,
    key: string,
    value: string | number | null
  ) => void
}

export const ThoothNumber: React.FC<Props> = (props): JSX.Element => {
  const { isHover, tooth_number, id, handleTreatmentPlanChange } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [value, setValue] = useState<number | undefined>(tooth_number)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%'
      }}
    >
      {!isEdit && isHover && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      <Box flexGrow={1}>
        {isEdit ? (
          <TextField
            value={value ? value : ''}
            onChange={(e) => setValue(Number(e.target.value))}
            type="number"
          />
        ) : (
          <Typography variant={'body1'}>
            {tooth_number ? tooth_number : ''}
          </Typography>
        )}
      </Box>

      {isEdit && (
        <Box
          sx={{
            display: 'flex',
            gap: '4px'
          }}
        >
          <IconButton
            onClick={() => {
              handleTreatmentPlanChange(id, 'tooth_number', Number(value))
              setIsEdit(false)
            }}
          >
            <CheckIcon
              sx={{
                width: '24px',
                height: '24px',
                path: {
                  fill: '#010800'
                }
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              handleTreatmentPlanChange(
                id,
                'tooth_number',
                Number(tooth_number)
              )
              setIsEdit(false)
            }}
          >
            <CrossIcon
              sx={{
                width: '24px',
                height: '24px'
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}
