import {
  TChangeMePasswordParams,
  TChangePasswordResponse,
  TGetMeParams,
  TGetMeResponse,
  TResetPasswordConfirmParams,
  TResetPasswordConfirmResponse,
  TResetPasswordParams,
  TResetPasswordResponse
} from '@/types/Me'
import { API_METHODS, USERS_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const meApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<TGetMeResponse, TGetMeParams>({
      query: () => ({
        url: USERS_API.ME,
        method: API_METHODS.GET
      })
    }),
    changeMePassword: builder.mutation<
      TChangePasswordResponse,
      TChangeMePasswordParams
    >({
      query: (body) => ({
        url: USERS_API.CHANGE_PASSWORD,
        method: API_METHODS.POST,
        body
      })
    }),
    resetPassword: builder.mutation<
      TResetPasswordResponse,
      TResetPasswordParams
    >({
      query: (body) => ({
        url: USERS_API.RESET_PASSWORD,
        method: API_METHODS.POST,
        body
      })
    }),
    resetPasswordConfirm: builder.mutation<
      TResetPasswordConfirmResponse,
      TResetPasswordConfirmParams
    >({
      query: (body) => ({
        url: USERS_API.RESET_PASSWORD_CONFIRM,
        method: API_METHODS.POST,
        body
      })
    })
  })
})

export const {
  useLazyGetMeQuery,
  useChangeMePasswordMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation
} = meApi
