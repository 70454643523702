import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import { theme } from '@/theme'

interface Props {
  health_info: Record<string, string>[]
  consent_info: Record<string, string>[]
}

export const HealthAndConsentInformation: React.FC<Props> = (
  props
): JSX.Element => {
  const { health_info, consent_info } = props

  const healthInfo = [...health_info, ...consent_info].map((el) => {
    const name = Object.keys(el)[0]
    const value = el[name]

    return {
      name,
      value,
      color: getQuestionValueColor(name, value)
    }
  })

  return (
    <Box mb={5}>
      <Box mb="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          Health and consent information
        </Typography>
      </Box>
      <Grid
        mb="20px"
        container
        xs={12}
        item
        spacing={2}
        display="flex"
        flexWrap="wrap"
      >
        {healthInfo.map(({ name, value, color }, i) => (
          <Grid
            key={i}
            item
            md={4}
            sm={6}
            xs={12}
            gap={1}
            display="flex"
            minWidth="200px"
            flexDirection="column"
          >
            <Typography
              variant="body1"
              fontSize={'16px'}
              color={theme.palette.secondary.main}
            >
              {name}
            </Typography>
            <Typography variant="h5" color={color}>
              {value || '-'}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}

const yesRedQuestions = [
  'Heart murmur/problem',
  'Diabetes',
  'Anaphylaxis',
  'Dental phobia',
  'Epilepsy',
  'Bleeding problem',
  'Autism',
  'Problems with previous dental treatment',
  'Asthma',
  'Fainting',
  'Jaw or sleeping problem',
  'Does this child require Antibiotics prior to Dental Treatment?',
  'Finger/Thumb sucking',
  'Had dental X-rays in the past 6 months'
]

const valuePresentRedQuestions = [
  'Please list any current medications',
  'Please, list any alergies',
  'Please list any other medical conditions:'
]

const yesGreenQuestions = [
  'Consent examination and clean',
  'Consent X rays',
  'Consent Fissure sealants'
]

const getQuestionValueColor = (name: string, value: string | null) => {
  if (yesRedQuestions.includes(name) && value === 'Yes') {
    return theme.palette.error.main
  }

  if (valuePresentRedQuestions.includes(name) && value) {
    return theme.palette.error.main
  }
  if (yesGreenQuestions.includes(name)) {
    const valueLowerCase = (value || '').toLowerCase()

    return valueLowerCase.includes('yes')
      ? theme.palette.primary.main
      : valueLowerCase.includes('no')
        ? theme.palette.error.main
        : theme.palette.text.primary
  }
}
