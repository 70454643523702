import { Box, SxProps, tooltipClasses, Typography } from '@mui/material'
import { ReactNode } from 'react'

import { InfoIcon } from '@/assets/icons'

import { TooltipLight } from '.'

type Props = {
  hoverText?: string
  hoverContent?: ReactNode
  hoverContentSx?: SxProps
}

export default function BasicTooltip({
  hoverText,
  hoverContent = null,
  hoverContentSx = {}
}: Props) {
  return (
    <TooltipLight
      title={
        <>
          {!hoverContent && (
            <Typography
              sx={{
                lineHeight: '30px',
                whiteSpace: 'pre-line',
                ...hoverContentSx
              }}
            >
              {hoverText}
            </Typography>
          )}
          {hoverContent && hoverContent}
        </>
      }
      placement="top"
      color="white"
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 'none'
        }
      }}
      arrow
    >
      <Box
        sx={{
          display: 'flex'
        }}
      >
        <InfoIcon
          sx={{
            width: '12px',
            height: '12px',
            cursor: 'pointer'
          }}
        />
      </Box>
    </TooltipLight>
  )
}
