import { TCompletedTreatmentsResponse, TGetCDBSFileBlobParams } from '@/types'
import { API_METHODS, REPORTS } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const reportsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComletedTreatments: builder.query<TCompletedTreatmentsResponse, null>({
      query: () => ({
        url: REPORTS.COMPLETED_TREATMENTS,
        method: API_METHODS.GET
      })
    }),
    addReportFile: builder.mutation({
      query: ({ file }) => ({
        url: REPORTS.FILES,
        method: API_METHODS.POST,
        headers: {
          'Content-type': undefined
        },
        body: file
      })
    }),
    deleteReportFile: builder.mutation({
      query: ({ id }) => ({
        url: `${REPORTS.FILES}${id}/`,
        method: API_METHODS.DELETE
      })
    }),
    patchReportFile: builder.mutation({
      query: ({ body, id }) => ({
        url: `${REPORTS.FILES}${id}/`,
        method: API_METHODS.PATCH,
        headers: {
          'Content-type': undefined
        },
        body
      })
    }),
    getCDBSFileBlob: builder.query<Blob, TGetCDBSFileBlobParams>({
      query: ({ id }) => ({
        url: `${REPORTS.FILES}${id}/download/`,
        method: API_METHODS.GET,
        responseHandler: (response) => response.blob()
      })
    })
  })
})

export const {
  useGetComletedTreatmentsQuery,
  useDeleteReportFileMutation,
  useAddReportFileMutation,
  useLazyGetCDBSFileBlobQuery,
  usePatchReportFileMutation
} = reportsApi
