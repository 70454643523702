/* eslint-disable max-len */
import {
  alpha,
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  Typography
} from '@mui/material'
import { FC } from 'react'

import { CheckIcon } from '@/assets/icons'
import { theme } from '@/theme'
import { IAcceptedFile } from '@/types'
import { FILES_COLORS } from '@/utils/constants'
import { dayjs } from '@/utils/lib'

import Actions, { IAction } from './components/Actions'

interface Props {
  file: IAcceptedFile
  checkboxLabel?: string
  CheckboxProps?: CheckboxProps
  actions?: IAction[]
  helperText?: string
}

const PreviewFileExtra: FC<Props> = (props: Props): JSX.Element => {
  const { file, CheckboxProps, actions, checkboxLabel, helperText } = props

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '100%',
          backgroundColor: theme.palette.grey100.main,
          padding: '8px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '4px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'fit-content',
                height: '32px',
                minWidth: '32px',
                borderRadius: '4px',
                backgroundColor: `${alpha(FILES_COLORS[file.metadata?.extension as keyof typeof FILES_COLORS]?.color || FILES_COLORS.default.color, 0.2)}`
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color:
                    FILES_COLORS[
                      file.metadata?.extension as keyof typeof FILES_COLORS
                    ]?.color || FILES_COLORS.default.color,
                  fontWeight: 500,
                  textTransform: 'uppercase'
                }}
              >
                {file.metadata?.extension}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                flexShrink: 20
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  wordBreak: 'break-word'
                }}
              >
                {file.metadata?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.secondary.main,
                  fontWeight: 400
                }}
              >
                {file.metadata?.size || 0} |{' '}
                {dayjs(file.metadata?.created_at).format('DD.MM.YYYY')}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: 'auto'
            }}
          >
            {actions?.length && <Actions actions={actions} />}
          </Box>
        </Box>
        {CheckboxProps ? (
          <Box flexGrow={1}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    icon={<></>}
                    checkedIcon={
                      <CheckIcon
                        sx={{
                          width: '12px',
                          height: '12px'
                        }}
                      />
                    }
                    {...CheckboxProps}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.secondary.main,
                      lineHeight: '20px'
                    }}
                  >
                    {checkboxLabel}
                  </Typography>
                }
              />
            </Box>
          </Box>
        ) : null}
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  )
}

export default PreviewFileExtra
