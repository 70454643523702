/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DropdownIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M16.692 7.94254L10.442 14.1925C10.384 14.2506 10.3151 14.2967 10.2392 14.3282C10.1633 14.3597 10.082 14.3758 9.99986 14.3758C9.91772 14.3758 9.8364 14.3597 9.76052 14.3282C9.68465 14.2967 9.61572 14.2506 9.55767 14.1925L3.30767 7.94254C3.1904 7.82526 3.12451 7.6662 3.12451 7.50035C3.12451 7.3345 3.1904 7.17544 3.30767 7.05816C3.42495 6.94088 3.58401 6.875 3.74986 6.875C3.91571 6.875 4.07477 6.94088 4.19205 7.05816L9.99986 12.8668L15.8077 7.05816C15.8657 7.00009 15.9347 6.95403 16.0105 6.9226C16.0864 6.89117 16.1677 6.875 16.2499 6.875C16.332 6.875 16.4133 6.89117 16.4892 6.9226C16.565 6.95403 16.634 7.00009 16.692 7.05816C16.7501 7.11623 16.7962 7.18517 16.8276 7.26104C16.859 7.33691 16.8752 7.41823 16.8752 7.50035C16.8752 7.58247 16.859 7.66379 16.8276 7.73966C16.7962 7.81553 16.7501 7.88447 16.692 7.94254Z"
        fill={props.fill || '#010800'}
      />
    </SvgIcon>
  )
}
