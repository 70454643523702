import { Typography, TypographyProps } from '@mui/material'
import { FC, ReactNode } from 'react'

import { DropdownIcon } from '@/assets/icons'
import { theme } from '@/theme'

interface Props extends TypographyProps {
  children: ReactNode
}

const Back: FC<Props> = (props: Props): JSX.Element => {
  const { children, sx, ...rest } = props

  return (
    <Typography
      variant="h2"
      component="p"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        color: theme.palette.primary.main,
        fontFamily: 'Figtree',
        fontSize: '16px',
        lineHeight: '24px',
        cursor: 'pointer',
        ...sx
      }}
      {...rest}
    >
      <DropdownIcon
        sx={{
          width: '20px',
          height: '20px',
          transform: 'rotate(90deg)',
          path: {
            fill: theme.palette.primary.main
          }
        }}
      />
      {children}
    </Typography>
  )
}

export default Back
