/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DrawerHandleIcon: React.FC<SvgIconProps & { open: boolean }> = (
  props: SvgIconProps & { open: boolean }
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        fill="transparent"
        stroke="#F0F3F7"
      />
      <path
        d="M6.96948 11.0306C7.11021 11.1713 7.30108 11.2504 7.5001 11.2504C7.69912 11.2504 7.89 11.1713 8.03073 11.0306C8.17146 10.8899 8.25052 10.699 8.25052 10.5C8.25052 10.301 8.17146 10.1101 8.03073 9.96936L6.31041 8.24999H19.5001C19.699 8.24999 19.8898 8.17097 20.0304 8.03032C20.1711 7.88967 20.2501 7.6989 20.2501 7.49999C20.2501 7.30108 20.1711 7.11031 20.0304 6.96966C19.8898 6.82901 19.699 6.74999 19.5001 6.74999H6.31041L8.03073 5.03061C8.17146 4.88988 8.25052 4.69901 8.25052 4.49999C8.25052 4.30097 8.17146 4.1101 8.03073 3.96936C7.89 3.82863 7.69912 3.74957 7.5001 3.74957C7.30108 3.74957 7.11021 3.82863 6.96948 3.96936L3.96948 6.96936C3.89974 7.03902 3.84443 7.12174 3.80668 7.21278C3.76894 7.30383 3.74951 7.40143 3.74951 7.49999C3.74951 7.59855 3.76894 7.69615 3.80668 7.7872C3.84443 7.87824 3.89974 7.96096 3.96948 8.03061L6.96948 11.0306Z"
        fill={props.open ? '#010800' : '#32B22B'}
      />
      <path
        d="M17.0306 20.0306L20.0306 17.0306C20.1004 16.961 20.1557 16.8782 20.1934 16.7872C20.2312 16.6961 20.2506 16.5986 20.2506 16.5C20.2506 16.4014 20.2312 16.3038 20.1934 16.2128C20.1557 16.1217 20.1004 16.039 20.0306 15.9694L17.0306 12.9694C16.8899 12.8286 16.699 12.7496 16.5 12.7496C16.301 12.7496 16.1101 12.8286 15.9694 12.9694C15.8286 13.1101 15.7496 13.301 15.7496 13.5C15.7496 13.699 15.8286 13.8899 15.9694 14.0306L17.6897 15.75H4.5C4.30109 15.75 4.11032 15.829 3.96967 15.9697C3.82902 16.1103 3.75 16.3011 3.75 16.5C3.75 16.6989 3.82902 16.8897 3.96967 17.0303C4.11032 17.171 4.30109 17.25 4.5 17.25H17.6897L15.9694 18.9694C15.8286 19.1101 15.7496 19.301 15.7496 19.5C15.7496 19.699 15.8286 19.8899 15.9694 20.0306C16.1101 20.1713 16.301 20.2504 16.5 20.2504C16.699 20.2504 16.8899 20.1713 17.0306 20.0306Z"
        fill={props.open ? '#32B22B' : '#010800'}
      />
    </SvgIcon>
  )
}
