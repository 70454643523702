import { Box, SvgIconProps, SxProps, Typography } from '@mui/material'
import { deepmerge } from '@mui/utils'
import {
  ChangeEvent,
  FC,
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
  useRef
} from 'react'

import { UploadIcon } from '@/assets/icons'
import { theme } from '@/theme'

export interface IUploadFilesProps {
  title?: string
  name?: string
  icon?: ReactElement
  IconProps?: SvgIconProps
  accept?: string
  multiple?: boolean
  sx?: SxProps
  onClick?: (e: MouseEvent<HTMLDivElement>, ...props: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  tooltip?: ReactNode
}

const DefaultIcon: FC = ({ sx, ...rest }: SvgIconProps): JSX.Element => (
  <UploadIcon
    sx={deepmerge(
      {
        width: '24px',
        height: '24px',
        path: {
          fill: theme.palette.primary.main
        }
      },
      sx
    )}
    {...rest}
  />
)

const UploadFiles = forwardRef(
  (props: IUploadFilesProps, ref: Ref<HTMLDivElement>): JSX.Element => {
    const {
      title = 'Upload file',
      name = 'uploadFiles',
      IconProps,
      icon = (
        <DefaultIcon
          sx={{
            width: '24px',
            height: '24px'
          }}
          {...IconProps}
        />
      ),
      accept,
      multiple = true,
      sx,
      onClick,
      onChange,
      tooltip
    } = props

    const inputRef = useRef<HTMLInputElement | null>(null)

    return (
      <Box
        onClick={onClick}
        sx={{
          display: 'flex'
        }}
      >
        <Box
          onClick={(e) => {
            const input = inputRef.current

            if (!input) return

            input.click()

            e.stopPropagation()

            // Allow to add already uploaded files
            input.value = ''
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '7px',
            cursor: 'pointer',
            ...sx
          }}
          ref={ref}
        >
          {title && (
            <Typography
              variant="h6"
              component="p"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600
              }}
            >
              {title}
            </Typography>
          )}
          {tooltip && (
            <Box
              sx={{
                alignSelf: 'flex-start',
                marginLeft: '-7px'
              }}
            >
              {tooltip}
            </Box>
          )}
          {icon && icon}
        </Box>
        <Box
          component="input"
          name={name}
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={onChange}
          display="none"
          ref={inputRef}
        />
      </Box>
    )
  }
)

export default UploadFiles
