import {
  EFormsNextRefreshResponse,
  TAddPatientFileParams,
  TAddPatientFileResponse,
  TDeletePatientEformsParams,
  TDeletePatientEformsResponse,
  TDeletePatientParams,
  TGetPatientParams,
  TGetPatientReportAutofill,
  TGetPatientResponse,
  TGetPatientsEformsParams,
  TGetPatientsEformsResponse,
  TGetPatientsParams,
  TGetPatientsResponse,
  TPatchUpdatePatientsEformsParams,
  TPatchUpdatePatientsEformsResponse,
  TPatchUpdatePatientsParams,
  TPatchUpdatePatientsResponse,
  TSendReportsParams,
  TSendReportsResponse,
  TServerResponse
} from '@/types'
import { API_METHODS, PATIENTS_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const patientsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query<TGetPatientsResponse, TGetPatientsParams>({
      query: ({ ...params }) => ({
        url: PATIENTS_API.PATIENTS,
        method: API_METHODS.GET,
        params
      }),
      providesTags: ['Patients']
    }),
    getPatient: builder.query<TGetPatientResponse, TGetPatientParams>({
      query: ({ id }) => ({
        url: `${PATIENTS_API.PATIENTS}${id}`,
        method: API_METHODS.GET
      }),
      providesTags: ['Patients']
    }),

    getPatientReportAutofill: builder.query<
      TGetPatientReportAutofill,
      TGetPatientParams
    >({
      query: ({ id }) => ({
        url: `${PATIENTS_API.PATIENTS}${id}/reports/autofill/`,
        method: API_METHODS.GET
      })
    }),
    getPatientPDF: builder.mutation<Blob, { id: string }>({
      query: ({ id }) => ({
        url: `${PATIENTS_API.PATIENTS}${id}/generate-pdf/`,
        method: API_METHODS.GET,
        responseHandler: (response: { blob: () => any }) => response.blob()
      })
    }),
    getPatientsEforms: builder.query<
      TGetPatientsEformsResponse,
      TGetPatientsEformsParams
    >({
      //school__names is just for a local purposes
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ school__names, ...params }) => ({
        url: PATIENTS_API.PATIENTS_EFORMS,
        method: API_METHODS.GET,
        params
      }),
      providesTags: ['PatientsEforms']
    }),
    patchUpdatePatientsEforms: builder.mutation<
      TPatchUpdatePatientsEformsResponse,
      TPatchUpdatePatientsEformsParams
    >({
      query: ({ id, ...body }) => ({
        url: `${PATIENTS_API.PATIENTS_EFORMS}${id}/`,
        method: API_METHODS.PATCH,
        body
      }),
      invalidatesTags: ['PatientsEforms']
    }),
    deletePatientEforms: builder.mutation<
      TDeletePatientEformsResponse,
      TDeletePatientEformsParams
    >({
      query: ({ id }) => ({
        url: `${PATIENTS_API.PATIENTS_EFORMS}${id}/`,
        method: API_METHODS.DELETE
      }),
      invalidatesTags: ['PatientsEforms', 'Patients']
    }),
    patchUpdatePatients: builder.mutation<
      TPatchUpdatePatientsResponse,
      TPatchUpdatePatientsParams
    >({
      query: ({ id, ...body }) => ({
        url: `${PATIENTS_API.PATIENTS}${id}/`,
        method: API_METHODS.PATCH,
        body
      }),
      invalidatesTags: ['Patients']
    }),
    deletePatient: builder.mutation<
      TServerResponse<void>,
      TDeletePatientParams
    >({
      query: ({ id }) => ({
        url: `${PATIENTS_API.PATIENTS}${id}/`,
        method: API_METHODS.DELETE
      }),
      invalidatesTags: ['Patients']
    }),
    refreshEforms: builder.mutation<TServerResponse<void>, void>({
      query: () => ({
        url: PATIENTS_API.REFRESH,
        method: API_METHODS.POST
      }),
      invalidatesTags: ['PatientsEforms', 'RefreshEForms']
    }),
    getNextRefreshEforms: builder.query<EFormsNextRefreshResponse, void>({
      query: () => ({
        url: PATIENTS_API.NEXT_REFRESH,
        method: API_METHODS.GET
      }),
      providesTags: ['RefreshEForms']
    }),
    sendReports: builder.mutation<TSendReportsResponse, TSendReportsParams>({
      query: ({ patient_ids }) => ({
        url: PATIENTS_API.PATIENTS_SEND_REPORTS,
        method: API_METHODS.POST,
        body: { patient_ids }
      })
    }),
    addPatientFile: builder.mutation<
      TAddPatientFileResponse,
      TAddPatientFileParams
    >({
      query: ({ name, size, file, confidential, patient, extension }) => {
        const formData = new FormData()

        formData.append('name', name)
        formData.append('patient', patient)
        formData.append('extension', extension)
        formData.append('size', size.toString())
        formData.append('file', file)
        formData.append('confidential', JSON.stringify(confidential))

        return {
          url: PATIENTS_API.FILES,
          method: API_METHODS.POST,
          body: formData
        }
      }
    })
  })
})

export const {
  useGetPatientsEformsQuery,
  usePatchUpdatePatientsEformsMutation,
  usePatchUpdatePatientsMutation,
  useGetPatientsQuery,
  useDeletePatientMutation,
  useGetPatientQuery,
  useGetPatientPDFMutation,
  useGetPatientReportAutofillQuery,
  useLazyGetPatientReportAutofillQuery,
  useRefreshEformsMutation,
  useGetNextRefreshEformsQuery,
  useDeletePatientEformsMutation,
  useSendReportsMutation,
  useAddPatientFileMutation
} = patientsApi
