import { API_METHODS, SCHOOLS_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const schoolsUploadDownload = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolTemplate: builder.query<Blob, void>({
      query: () => ({
        url: SCHOOLS_API.SCHOOLS_TEMPLATE_DOWNLOAD,
        method: API_METHODS.GET,
        responseHandler: (response: { blob: () => any }) => response.blob()
      })
    }),
    uploadSchool: builder.mutation<void, FormData>({
      query: (body) => ({
        url: SCHOOLS_API.SCHOOLS_UPLOAD,
        method: API_METHODS.POST,
        body
      }),
      invalidatesTags: ['Schools']
    })
  })
})

export const { useLazyGetSchoolTemplateQuery, useUploadSchoolMutation } =
  schoolsUploadDownload
