import { FieldErrors } from 'react-hook-form'
import { ToastPosition } from 'react-hot-toast'

import { TServerResponse } from '@/types'
import { toastError } from '@/utils/lib'

export const getHookFormErrorMessage = <T extends FieldErrors>(
  errors: T,
  id: keyof T
): string => (errors?.[id] ? (errors[id]?.message as string) : '')

const findErrorsRecursive = (obj: any, errors: string[] = []): string[] => {
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((error: any) => {
          if (typeof error === 'string') {
            errors.push(error)
          } else if (typeof error === 'object') {
            findErrorsRecursive(error, errors)
          }
        })
      } else if (typeof obj[key] === 'object') {
        findErrorsRecursive(obj[key], errors)
      } else if (typeof obj[key] === 'string') {
        errors.push(obj[key])
      }
    }
  }

  return errors
}

export const getServerErrorMessage = (
  errors: TServerResponse<any>['errors']
): string | undefined => {
  if (!Array.isArray(errors)) return 'Something went wrong!'

  const findErrors = findErrorsRecursive(errors)

  if (findErrors?.length) {
    return findErrors[0]
  }
}

export const showError = (
  e: TServerResponse<any>['errors'],
  position?: ToastPosition
) =>
  toastError({
    content: getServerErrorMessage(e) || 'Something went wrong!',
    position: position || 'top-right'
  })
