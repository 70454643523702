import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAppSelector } from '@/hooks'
import { useDefaultPage } from '@/hooks/useDefaultPage'

interface Props {
  component: React.ComponentType
}

export const PublicRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const isLogin = useAppSelector((state) => state.userStore.isLogin)
  const { getDefaultPagePath } = useDefaultPage()

  if (!isLogin) {
    return <RouteComponent />
  }

  return <Navigate to={getDefaultPagePath()} />
}
