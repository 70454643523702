import { IconButton, Menu, MenuItem, paperClasses, styled } from '@mui/material'
import { FC, useRef, useState } from 'react'

import { DotsThreeIcon, DownloadIcon, TrashIcon } from '@/assets/icons'
import { theme } from '@/theme'

export interface IAction {
  name: 'delete' | 'download'
  title: string
  onClick: () => void
}

interface Props {
  actions: IAction[]
}

const FileActionMenuItem = styled(MenuItem)({
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
})

const renderMenuItem = (
  { name, title, onClick }: IAction,
  noLabel?: boolean
) => {
  const item = title || name

  const iconSize = noLabel ? '24px' : '20px'
  const menuItemPadding = noLabel ? '2px' : '8px'

  const items = {
    download: (
      <FileActionMenuItem
        key={item}
        onClick={onClick}
        sx={{
          padding: menuItemPadding
        }}
      >
        <DownloadIcon
          sx={{
            width: iconSize,
            height: iconSize,
            path: {
              fill: 'currentColor'
            }
          }}
        />
        {!noLabel && item}
      </FileActionMenuItem>
    ),
    delete: (
      <FileActionMenuItem
        key={item}
        onClick={onClick}
        sx={{
          padding: menuItemPadding
        }}
      >
        <TrashIcon
          sx={{
            width: iconSize,
            height: iconSize,
            path: {
              fill: 'currentColor'
            }
          }}
        />
        {!noLabel && item}
      </FileActionMenuItem>
    )
  }

  return items[name as keyof typeof items]
}

const Actions: FC<Props> = (props: Props): JSX.Element => {
  const { actions } = props

  const actionButtonRef = useRef<HTMLButtonElement | null>(null)

  const [actionAnchorEl, setActionAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const isActionMenuOpen = Boolean(actionAnchorEl)

  const handleOpenActionMenu = () => setActionAnchorEl(actionButtonRef.current)

  const handleCloseActionMenu = () => setActionAnchorEl(null)

  if (actions.length === 1) {
    return renderMenuItem(actions[0], true)
  }

  return (
    <>
      <IconButton
        onClick={handleOpenActionMenu}
        sx={{
          width: '24px',
          height: '24px',
          borderRadius: '2px',
          backgroundColor: isActionMenuOpen
            ? theme.palette.primary.light
            : 'unset',
          color: isActionMenuOpen ? theme.palette.primary.main : 'unset',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main
          },
          svg: {
            path: {
              fill:
                actions.length > 0 ? 'currentcolor' : theme.palette.grey[300],
              transition: theme.transitions.create(['color'], {
                duration: theme.transitions.duration.shortest
              })
            }
          }
        }}
        ref={actionButtonRef}
      >
        <DotsThreeIcon
          sx={{
            width: '24px',
            height: '24px'
          }}
        />
      </IconButton>
      <Menu
        anchorEl={actionAnchorEl}
        id="action-menu"
        open={isActionMenuOpen}
        onClose={handleCloseActionMenu}
        onClick={handleCloseActionMenu}
        slotProps={{
          paper: {
            sx: {
              [`&.${paperClasses.root}`]: {
                minWidth: '162px',
                marginTop: '4px'
              }
            }
          }
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        hideBackdrop
      >
        {actions.map((action) => renderMenuItem(action))}
      </Menu>
    </>
  )
}

export default Actions
