import 'react-lazy-load-image-component/src/effects/blur.css'

import React from 'react'
import { Effect, LazyLoadImage } from 'react-lazy-load-image-component'

interface Props {
  src: string
  alt: string
  effect?: Effect
  style?: React.CSSProperties
}

export const Image: React.FC<Props> = ({
  src,
  alt,
  effect = 'blur',
  style
}) => {
  return (
    <LazyLoadImage
      style={style}
      height="100%"
      width="100%"
      src={src}
      alt={alt}
      loading="lazy"
      effect={effect}
      threshold={100}
    />
  )
}

export default Image
