import {
  Box,
  IconButton,
  inputBaseClasses,
  TextField,
  textFieldClasses,
  Typography
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { CheckIcon, CrossIcon, PencilIcon } from '@/assets/icons'
import { showError } from '@/helpers/functions'
import { usePatchUpdatePatientsMutation } from '@/redux/services'
import { IPatient } from '@/types'

interface Props {
  id: IPatient['id']
  parent_notes: string
  isHover: boolean
  isOnDetailPage?: boolean
}

const ParentNotes: FC<Props> = (props): JSX.Element => {
  const { id, parent_notes, isHover, isOnDetailPage } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')

  const [updatePatient, { isLoading: isPatchUpdatePatientsLoading }] =
    usePatchUpdatePatientsMutation()

  useEffect(() => {
    setValue(parent_notes)
  }, [parent_notes])

  const handleUpdateParentNotes = async () => {
    try {
      if (parent_notes !== value) {
        await updatePatient({
          id,
          parent_notes: value
        }).unwrap()
      }

      setIsEdit(false)
    } catch (e: any) {
      showError(e?.data?.errors)
    }
  }

  const isLoading = isPatchUpdatePatientsLoading

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
        minHeight: '44px'
      }}
    >
      {!isEdit && isHover && !isOnDetailPage && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      <Box flexGrow={1}>
        {isEdit ? (
          <TextField
            id="description"
            variant="outlined"
            color="primary"
            sx={{
              [`&.${textFieldClasses.root}`]: {
                [`& .${inputBaseClasses.root}`]: {
                  [`& .${inputBaseClasses.input}`]: {
                    lineHeight: '18px'
                  }
                }
              }
            }}
            multiline
            rows={3}
            fullWidth
            placeholder="Enter parent notes"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <Typography variant={isOnDetailPage ? 'h5' : 'body1'}>
            {parent_notes}
          </Typography>
        )}
      </Box>
      {!isEdit && isHover && isOnDetailPage && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      {isEdit && (
        <Box
          sx={{
            display: 'flex',
            gap: '4px'
          }}
        >
          <IconButton onClick={handleUpdateParentNotes} disabled={isLoading}>
            <CheckIcon
              sx={{
                width: '24px',
                height: '24px',
                path: {
                  fill: '#010800'
                }
              }}
            />
          </IconButton>
          <IconButton onClick={() => setIsEdit(false)} disabled={isLoading}>
            <CrossIcon
              sx={{
                width: '24px',
                height: '24px'
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default ParentNotes
