import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import { theme } from '@/theme'

interface Props {
  fullName: string
  parentFullName: string
  email: string
  balance: string
  dateofBirth: string
  phone: string
  address: string
}

export const PersonalInformation: React.FC<Props> = (props): JSX.Element => {
  const {
    fullName,
    parentFullName,
    address,
    balance,
    dateofBirth,
    email,
    phone
  } = props

  const personalInfo = [
    {
      name: 'Full name',
      value: fullName
    },
    {
      name: 'Parent fullname',
      value: parentFullName
    },
    {
      name: 'Email',
      value: email
    },
    {
      name: 'Balance',
      value: balance
    },
    {
      name: 'Date of birth',
      value: dateofBirth
    },
    {
      name: 'Phone number',
      value: phone
    },
    {
      name: 'Address',
      value: address
    }
  ]

  return (
    <Box mb={5}>
      <Box mb="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          Personal information
        </Typography>
      </Box>
      <Grid
        mb="20px"
        container
        xs={12}
        item
        spacing={2}
        display="flex"
        flexWrap="wrap"
      >
        {personalInfo.map((el) => (
          <Grid
            key={el.name}
            item
            md={3}
            sm={6}
            xs={12}
            gap={1}
            display="flex"
            minWidth="200px"
            flexDirection="column"
          >
            <Typography
              variant="body1"
              fontSize={'16px'}
              color={theme.palette.secondary.main}
            >
              {el.name}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                wordBreak: 'break-word'
              }}
            >
              {el.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
