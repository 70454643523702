import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

import { apiBaseUrl } from '@/helpers/functions'
import { API_METHODS, AUTH_API } from '@/utils/constants'
import { toastError } from '@/utils/lib'

import { logout, setAccessToken } from '../features/userSlice'
import { RootState } from '../store'

export const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Accept-Encoding': 'gzip, deflate, br, brotli'
  },
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).userStore.accessToken
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  }
})

export const baseQueryWithReauth = async (
  args: FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  let result = await baseQuery(args, api, extraOptions)

  const state = api.getState() as RootState

  const isError = result.error && result.error.status === 401

  const isUnauthorized = result.error && result.error.status === 403

  if (isUnauthorized) {
    toastError({
      content: 'No permissions to access this resource. Please log in again.',
      position: 'top-right'
    })
    api.dispatch(logout())
  }

  if (isError && args.url !== AUTH_API.TOKEN) {
    const refresh = state.userStore.refreshToken

    const newAccessToken = await baseQuery(
      {
        url: AUTH_API.REFRESH,
        method: API_METHODS.POST,
        body: { refresh }
      },
      api,
      extraOptions
    )
    if ('error' in newAccessToken) {
      toastError({
        content: 'Your session has expired. Please, sign in',
        position: 'top-right'
      })
      api.dispatch(logout())
    }

    // @ts-ignore
    if (newAccessToken?.data?.data?.access) {
      // @ts-ignore
      api.dispatch(setAccessToken(newAccessToken.data.data.access))
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
  tagTypes: [
    'School',
    'Schools',
    'PatientsEforms',
    'Schools_Filter',
    'Patients',
    'ProbonoReports',
    'CDBSReports',
    'Staff',
    'RefreshEForms'
  ]
})
