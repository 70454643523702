/* eslint-disable quotes */
/* eslint-disable max-len */
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { UseFormSetValue } from 'react-hook-form'

import firstBrushImage from '@/assets/images/howToBrush/firstImage.jpg'
import fourtBrushImage from '@/assets/images/howToBrush/fourtImage.jpg'
import secondBrushImage from '@/assets/images/howToBrush/secondImage.jpg'
import thirdBrushImage from '@/assets/images/howToBrush/thirdImage.jpg'
import fiftFlossImage from '@/assets/images/howToFloss/fiftImage.jpg'
import firstFlossImage from '@/assets/images/howToFloss/firstImage.jpg'
import fourtFlossImage from '@/assets/images/howToFloss/fourtImage.jpg'
import secondFlossImage from '@/assets/images/howToFloss/secondImage.jpg'
import thirdFlossImage from '@/assets/images/howToFloss/thirdImage.jpg'
import SkeletonLoadImage from '@/components/UI/SkeletonLoadImage'
import { theme } from '@/theme'

import { ProbonoReportData } from '../probono/components/ProbonoReport'
import { BrushingAreas } from './BrushingAreas'
import { DescriptionsOfDentalTerms } from './DescriptionsOfDentalTerms'

const howToFlossImageDimensions = {
  width: '200px',
  height: '141px'
}
const howToFlossItems = [
  {
    image: firstFlossImage,
    text: 'Use about 18 inches of floss wound around one of your middle fingers, with the rest wound around the opposite middle finger.'
  },
  {
    image: secondFlossImage,
    text: 'Hold the floss tightly between the thumbs and forefingers and gently insert it between the teeth.'
  },
  {
    image: thirdFlossImage,
    text: 'Curve the floss into a "C" shape against the side of the tooth.'
  },
  {
    image: fourtFlossImage,
    text: "Rub the floss gently up and down, keeping it pressed against the tooth. Don't jerk or snap the floss."
  },
  {
    image: fiftFlossImage,
    text: "Floss all your teeth. Don't forget to floss behind your back teeth."
  }
]

const howToBrushImageDimensions = {
  width: '200px',
  height: '163px'
}
const howToBrushItems = [
  {
    image: firstBrushImage,
    text: 'Place the toothbrush at a 45-degree angle to the gums.'
  },
  {
    image: secondBrushImage,
    text: 'Move the brush back and forth gently in short strokes. \n Brush the outer surfaces, the inside surfaces and the chewing surfaces of all'
  },
  {
    image: thirdBrushImage,
    text: 'Brush the outer surfaces, the inside surfaces and the chewing surfaces of all teeth.'
  },
  {
    image: fourtBrushImage,
    text: 'Brush your tongue to remove bacteria and keep your breath fresh.'
  }
]

interface Props {
  focus_brushing_areas: string[]
  setValue: UseFormSetValue<ProbonoReportData>
}

export const BrushingFocusBlock: React.FC<Props> = (props): JSX.Element => {
  const { focus_brushing_areas, setValue } = props
  return (
    <Box>
      <Box mb={5} display="flex" flexDirection="column" gap="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          Areas to focus on while brushing
        </Typography>
        <Typography fontSize={16}>
          Every child should have a comprehensive oral health check up at least
          once a year or as advised by their regular dental clinician. Today’s
          limited dental health screening and treatment advice provided does not
          replace a comprehensive oral health checkup with your regular dental
          clinician.
        </Typography>
        <BrushingAreas
          setValue={(value) => setValue('focus_brushing_areas', value)}
          focus_brushing_areas={focus_brushing_areas}
        />
      </Box>
      <DescriptionsOfDentalTerms />
      <Grid mt={5} container columnSpacing={4}>
        <Grid xs={12} lg={6} item>
          <Typography color={theme.palette.primary.main} variant="h5">
            How to brush
          </Typography>
          <Box display="flex" mt={4} gap={4} flexDirection="column">
            {howToBrushItems.map((el) => (
              <Box
                display="flex"
                gap="20px"
                alignItems="center"
                justifyContent="flex-start"
                key={el.text}
              >
                <Box
                  sx={{
                    minWidth: { xs: '100px', sm: '200px' },
                    maxWidth: { xs: '100px', sm: '200px' },
                    width: '100%'
                  }}
                >
                  <SkeletonLoadImage
                    src={el.image}
                    alt={el.text}
                    width={howToBrushImageDimensions.width}
                    height={howToBrushImageDimensions.height}
                  />
                </Box>
                <Typography fontSize={18}>{el.text}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid xs={12} lg={6} item>
          <Typography color={theme.palette.primary.main} variant="h5">
            How to floss
          </Typography>
          <Box display="flex" mt={4} gap={4} flexDirection="column">
            {howToFlossItems.map((el) => (
              <Box
                display="flex"
                gap="20px"
                alignItems="center"
                justifyContent="flex-start"
                key={el.text}
              >
                <Box
                  sx={{
                    minWidth: { xs: '100px', sm: '200px' },
                    maxWidth: { xs: '100px', sm: '200px' },
                    width: '100%'
                  }}
                >
                  <SkeletonLoadImage
                    src={el.image}
                    alt={el.text}
                    width={howToFlossImageDimensions.width}
                    height={howToFlossImageDimensions.height}
                  />
                </Box>
                <Typography fontSize={18}>{el.text}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
