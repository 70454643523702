import { Paper } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useParams } from 'react-router-dom'

import { PatientDetailsSkeleton } from '@/components/Skeletons'
import { useGetPatientQuery } from '@/redux/services'
import { IGetPatient } from '@/types'
import {
  BACKEND_DATE_FORMAT,
  BACKEND_MM_YYYY_DATE_FORMAT,
  SYSTEM_DATE_FORMAT,
  SYSTEM_MM_YYYY_DATE_FORMAT
} from '@/utils/constants'

import {
  HealthAndConsentInformation,
  MedicareInformation,
  NotesInformation,
  PersonalInformation,
  SchoolInformation,
  StatusInformation,
  TopBlock
} from './index'

const PatientDetails: React.FC = (): JSX.Element => {
  const { patientId } = useParams()

  const { data, isFetching } = useGetPatientQuery(
    { id: patientId || '' },
    { skip: !patientId }
  )

  if (!data || isFetching) return <PatientDetailsSkeleton />

  return (
    <Paper sx={{ p: '52px 32px', maxWidth: 'xl', mx: 'auto' }}>
      <TopBlock
        id={patientId || ''}
        fullName={`${data.data.first_name} ${data.data.last_name}`}
      />
      <PersonalInformation
        address={data.data.address || '---'}
        balance={`$ ${data.data.balance || '0'}`}
        dateofBirth={dayjs(data.data.date_of_birth, BACKEND_DATE_FORMAT).format(
          SYSTEM_DATE_FORMAT
        )}
        email={data.data.email}
        fullName={`${data.data.first_name} ${data.data.last_name}`}
        parentFullName={data.data.parent_name}
        phone={data.data.phone}
      />
      <SchoolInformation school_info={data.data.school_info} />
      <StatusInformation
        type={data.data.type}
        id={patientId || ''}
        dental_status={data.data.dental_status}
      />
      <NotesInformation
        id={patientId || ''}
        admin_notes={data.data.admin_notes || ''}
        parent_notes={data.data.parent_notes || ''}
      />
      <HealthAndConsentInformation
        health_info={data.data.health_info}
        consent_info={convertConsentInfoToFE(data.data.consent_info)}
      />
      <MedicareInformation
        cardNumber={data.data.medicare_info.card_number}
        expiry={dayjs(
          data.data.medicare_info.expiration_date,
          BACKEND_MM_YYYY_DATE_FORMAT
        ).format(SYSTEM_MM_YYYY_DATE_FORMAT)}
        numberNextToName={data.data.medicare_info.irn_next_number}
        nameOfChild={data.data.medicare_info.name_of_child}
      />
    </Paper>
  )
}

const convertConsentInfoToFE = (consent_info: IGetPatient['consent_info']) => {
  return consent_info.map((el) => {
    let result = el.consent_answer || ''

    if (result.toLowerCase().trim().length === 0) {
      result = 'Yes'
    }
    return {
      [`${CONSENT_QUESTION_ID_TO_NAME[el.medicare_consent_question]}`]: result
    }
  })
}

const CONSENT_QUESTION_ID_TO_NAME: Record<
  IGetPatient['consent_info'][0]['medicare_consent_question'],
  string
> = {
  '764aabbf-5dfd-4674-bf26-6ff6ef043d9b': 'Consent examination and clean',
  '4925c693-2f5d-42c3-abc3-06088ff94723': 'Consent X rays',
  'b604f0d5-a13b-477c-8c1d-56c80b8e9ed2': 'Consent Fissure sealants'
}

export default PatientDetails
