type TExtensionMap = {
  [key: string]: string
}

const extensionMap: TExtensionMap = {
  jpg: 'jpg',
  eps: 'eps',
  pdf: 'pdf',
  txt: 'txt',
  xsl: 'xsl',
  png: 'png',
  tiff: 'tiff',
  ai: 'ai',
  psd: 'psd',
  dxf: 'dxf',
  svg: 'svg',
  '3dm': '3dm',
  max: 'max',
  obj: 'obj',
  fbx: 'fbx',
  dwg: 'dwg',
  mov: 'mov'
}

export const fileExtensionResolver = (
  extension: string,
  name: string
): string => {
  if (extension === 'zzother') {
    const fileExt = name.split('.').pop() || ''

    return extensionMap[fileExt] || 'other'
  }

  return extension
}
