import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  RegionEnum,
  TGetPatientsEformsParams,
  TGetPatientsParams,
  TGetSchoolsParams
} from '@/types'
import { TGetStaffSearchParams } from '@/types/Staff'
import { ITEMS_PER_PAGE } from '@/utils/constants'

interface InitialState {
  isSidebarOpen: boolean
  tableResultsPerPage: {
    eforms: number
    'treatment-codes': number
  }
  region: Lowercase<keyof typeof RegionEnum>
  schoolsSearchParams: TGetSchoolsParams
  eformsSearchParams: TGetPatientsEformsParams
  patientsSearchParams: TGetPatientsParams
  staffSearchParams: TGetStaffSearchParams
}

const initialState: InitialState = {
  isSidebarOpen: true,
  tableResultsPerPage: {
    eforms: ITEMS_PER_PAGE[0],
    'treatment-codes': ITEMS_PER_PAGE[0]
  },
  region: 'victoria',
  schoolsSearchParams: {
    page: 1,
    page_size: ITEMS_PER_PAGE[0],
    search: ''
  },
  eformsSearchParams: {
    page: 1,
    page_size: ITEMS_PER_PAGE[0],
    school_ids: '',
    school__names: ''
  },
  patientsSearchParams: {
    page: 1,
    page_size: ITEMS_PER_PAGE[0],
    search: '',
    school__name: ''
  },
  staffSearchParams: {
    page: 1,
    page_size: ITEMS_PER_PAGE[0],
    search: '',
    role: []
  }
}

const preferenceSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload
    },
    updateTableResultsPerPage: (
      state,
      action: PayloadAction<{
        page: keyof InitialState['tableResultsPerPage']
        resultsPerPage: number
      }>
    ) => {
      const { page, resultsPerPage } = action.payload

      state.tableResultsPerPage[page] = resultsPerPage
    },
    setRegion: (
      state,
      action: PayloadAction<Lowercase<keyof typeof RegionEnum>>
    ) => {
      state.region = action.payload
    },
    updateSchoolsSearchParams: (
      state,
      action: PayloadAction<InitialState['schoolsSearchParams']>
    ) => {
      state.schoolsSearchParams = {
        ...state.schoolsSearchParams,
        ...action.payload
      }
    },
    clearSchoolsSearchParams: (state) => {
      state.schoolsSearchParams = initialState.schoolsSearchParams
    },
    updateEformsSearchParams: (
      state,
      action: PayloadAction<InitialState['eformsSearchParams']>
    ) => {
      state.eformsSearchParams = {
        ...state.eformsSearchParams,
        ...action.payload
      }
    },
    clearEformsSearchParams: (
      state,
      action: PayloadAction<InitialState['eformsSearchParams'] | null>
    ) => {
      state.eformsSearchParams = action?.payload
        ? { ...initialState.eformsSearchParams, ...action?.payload }
        : initialState.eformsSearchParams
    },
    updatePatientsSearchParams: (
      state,
      action: PayloadAction<InitialState['patientsSearchParams']>
    ) => {
      state.patientsSearchParams = {
        ...state.patientsSearchParams,
        ...action.payload
      }
    },
    clearPatientsSearchParams: (
      state,
      action: PayloadAction<InitialState['patientsSearchParams'] | null>
    ) => {
      state.patientsSearchParams = action.payload
        ? {
            ...initialState.patientsSearchParams,
            ...action?.payload
          }
        : initialState.patientsSearchParams
    },
    updateStaffSearchParams: (
      state,
      action: PayloadAction<InitialState['staffSearchParams']>
    ) => {
      state.staffSearchParams = {
        ...state.staffSearchParams,
        ...action.payload
      }
    },
    clearStaffSearchParams: (state) => {
      state.staffSearchParams = initialState.staffSearchParams
    }
  }
})

export const {
  setIsSidebarOpen,
  updateTableResultsPerPage,
  setRegion,
  updateStaffSearchParams,
  clearStaffSearchParams,
  updatePatientsSearchParams,
  clearPatientsSearchParams,
  updateSchoolsSearchParams,
  clearSchoolsSearchParams,
  updateEformsSearchParams,
  clearEformsSearchParams
} = preferenceSlice.actions

export default preferenceSlice.reducer
