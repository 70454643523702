import {
  TGetReportParams,
  TMouttPartsResponse,
  TProbonoReportParams,
  TProbonoReportResponse
} from '@/types'
import { API_METHODS, PATIENTS_API, REPORTS } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const probonoReportsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMouthParts: builder.query<TMouttPartsResponse, null>({
      query: () => ({
        url: REPORTS.MOUTH_PARTS,
        method: API_METHODS.GET
      })
    }),
    getProbonoReport: builder.query<TProbonoReportResponse, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/probono-reports/${reportId}/`,
        method: API_METHODS.GET
      }),
      providesTags: ['ProbonoReports']
    }),
    sendProbonoReport: builder.mutation<void, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/probono-reports/${reportId}/send_report/`,
        method: API_METHODS.GET
      })
    }),
    downloadProbonoReport: builder.mutation<Blob, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/probono-reports/${reportId}/download_report/`,
        method: API_METHODS.GET,
        responseHandler: (response: { blob: () => any }) => response.blob()
      })
    }),
    deleteProbonoReport: builder.mutation<void, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/probono-reports/${reportId}/`,
        method: API_METHODS.DELETE
      }),
      invalidatesTags: ['Patients']
    }),
    addProbonoReport: builder.mutation<
      TProbonoReportResponse & { id: string },
      TProbonoReportParams
    >({
      query: (body) => ({
        url: `${PATIENTS_API.PATIENTS}${body.patient}/probono-reports/`,
        method: API_METHODS.POST,
        body
      }),
      invalidatesTags: ['ProbonoReports']
    }),
    editProbonoReport: builder.mutation<
      TProbonoReportResponse & { id: string },
      TProbonoReportParams
    >({
      query: (body) => ({
        url: `${PATIENTS_API.PATIENTS}${body.patient}/probono-reports/${body.id}/`,
        method: API_METHODS.PUT,
        body
      }),
      invalidatesTags: ['ProbonoReports']
    })
  })
})

export const {
  useAddProbonoReportMutation,
  useGetMouthPartsQuery,
  useGetProbonoReportQuery,
  useEditProbonoReportMutation,
  useSendProbonoReportMutation,
  useDeleteProbonoReportMutation,
  useDownloadProbonoReportMutation
} = probonoReportsApi
