import React from 'react'

import { PatientWrapper } from './components'
import PatientDetails from './components/PatientDetails'

const PatientDetailsPage: React.FC = (): JSX.Element => {
  return (
    <PatientWrapper>
      <PatientDetails />
    </PatientWrapper>
  )
}
export default PatientDetailsPage
