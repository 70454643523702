import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from '@/hooks'
import MobileScreen from '@/pages/other/mobile-screen'
import { mobileSupportedPages, PAGES } from '@/routes/pages-routes'
import { theme } from '@/theme'

import AllPagesWrapper from './AllPagesWrapper'
import PrivatePagesWrapperWithSidebar from './PrivatePagesWrapper'
import PublicPagesWrapper from './PublicPagesWrapper'

interface Props {
  children: React.ReactNode
}

const pagesWithSidebar = [
  PAGES.SCHOOLS,
  PAGES.EFORMS,
  PAGES.SETTINGS,
  PAGES.PATIENTS,
  PAGES.STAFF
]

const pagesForAllUsers = [PAGES.EFORM, PAGES.EFORM_SUBMITED]

export const AppWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const location = useLocation()
  const isLogin = useAppSelector((state) => state.userStore.isLogin)
  const me = useAppSelector((state) => state.userStore.me)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isNeedSidebar = pagesWithSidebar.includes(location.pathname as PAGES)

  if (isMobile && !mobileSupportedPages.includes(location.pathname as PAGES))
    return <MobileScreen />

  if (isLogin && !me)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100dvh"
      >
        <CircularProgress size={80} />
      </Box>
    )

  if (pagesForAllUsers.includes(location.pathname as PAGES) && !isLogin)
    return <AllPagesWrapper>{children}</AllPagesWrapper>

  if (!isLogin || location.pathname === PAGES.CHANGE_PASSWORD)
    return <PublicPagesWrapper>{children}</PublicPagesWrapper>

  return (
    <PrivatePagesWrapperWithSidebar sidebar={isNeedSidebar}>
      {children}
    </PrivatePagesWrapperWithSidebar>
  )
}
