import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { CheckIcon, CrossIcon, DropdownIcon, PencilIcon } from '@/assets/icons'
import { getEnumKeyByValue, showError } from '@/helpers/functions'
import { usePatchUpdatePatientsMutation } from '@/redux/services'
import { IPatientsEforms, PatientTypesEnum } from '@/types'

interface Props {
  id: IPatientsEforms['id']
  type: IPatientsEforms['type']
  isHover: boolean
  isOnDetailPage?: boolean
}

const patientTypesOptions = [
  PatientTypesEnum.NEW_CDBS,
  PatientTypesEnum.NEW_PROBONO,
  PatientTypesEnum.WITHDRAWN,
  PatientTypesEnum.MISSING_INFORMATION,
  PatientTypesEnum.RESUBMITTED_FORMS,
  PatientTypesEnum.FORMS_AWAITING_RESPONSE,
  PatientTypesEnum.COMPLETE_CDBS,
  PatientTypesEnum.COMPLETE_PROBONO,
  PatientTypesEnum.CDBS_FURTHER_TREATMENT,
  PatientTypesEnum.INCOMPLETE,
  PatientTypesEnum.NEW_FORM
]

const patientTypesColor = {
  [PatientTypesEnum.NEW_CDBS]: '#00A1D5',
  [PatientTypesEnum.NEW_PROBONO]: '#32B22B',
  [PatientTypesEnum.WITHDRAWN]: '#7B8A99',
  [PatientTypesEnum.MISSING_INFORMATION]: '#A90000',
  [PatientTypesEnum.RESUBMITTED_FORMS]: '#7400E9',
  [PatientTypesEnum.FORMS_AWAITING_RESPONSE]: '#FF9900',
  [PatientTypesEnum.COMPLETE_CDBS]: '#117D0C',
  [PatientTypesEnum.COMPLETE_PROBONO]: '#007297',
  [PatientTypesEnum.CDBS_FURTHER_TREATMENT]: '#E4B300',
  [PatientTypesEnum.INCOMPLETE]: '#E66322'
}

const PatientType: FC<Props> = (props: Props): JSX.Element => {
  const { id, type, isHover, isOnDetailPage } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [value, setValue] = useState<IPatientsEforms['type'] | null>(null)

  const [updatePatient, { isLoading: isPatchUpdatePatientsLoading }] =
    usePatchUpdatePatientsMutation()

  useEffect(() => {
    setValue(type)
  }, [type])

  const handleUpdateType = async () => {
    try {
      if (type !== value) {
        await updatePatient({
          id,
          type: value!
        }).unwrap()
      }

      setIsEdit(false)
    } catch (e: any) {
      showError(e?.data?.errors)
    }
  }

  const getFormattedPatientType = (type: IPatientsEforms['type'] | null) =>
    type
      ? PatientTypesEnum[type.toUpperCase() as keyof typeof PatientTypesEnum]
      : null

  const isLoading = isPatchUpdatePatientsLoading

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
        minHeight: '44px'
      }}
    >
      {!isEdit && isHover && !isOnDetailPage && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      <Box flexGrow={1}>
        {isEdit ? (
          <Autocomplete
            id="type"
            options={patientTypesOptions}
            popupIcon={<DropdownIcon />}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select type"
                variant="outlined"
              />
            )}
            clearIcon={
              <CrossIcon
                sx={{
                  width: '20px',
                  height: '20px'
                }}
              />
            }
            value={getFormattedPatientType(value)}
            onChange={(_, data) => {
              if (!data) {
                setValue(null)
                return
              }
              const type = getEnumKeyByValue(PatientTypesEnum, data as string)

              if (type) {
                const lowerCase = type.toLowerCase() as IPatientsEforms['type']

                setValue(lowerCase)
              }
            }}
          />
        ) : (
          <Typography
            variant={isOnDetailPage ? 'h5' : 'body1'}
            color={
              patientTypesColor[
                getFormattedPatientType(type) as keyof typeof patientTypesColor
              ]
            }
          >
            {getFormattedPatientType(type)}
          </Typography>
        )}
      </Box>
      {!isEdit && isHover && isOnDetailPage && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      {isEdit && (
        <Box
          sx={{
            display: 'flex',
            gap: '4px'
          }}
        >
          <IconButton onClick={handleUpdateType} disabled={isLoading}>
            <CheckIcon
              sx={{
                width: '24px',
                height: '24px',
                path: {
                  fill: '#010800'
                }
              }}
            />
          </IconButton>
          <IconButton onClick={() => setIsEdit(false)} disabled={isLoading}>
            <CrossIcon
              sx={{
                width: '24px',
                height: '24px'
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default PatientType
