export interface PendingMutation {
  type:
    | 'AddProbonoReport'
    | 'EditProbonoReport'
    | 'AddCdbsReport'
    | 'EditCdbsReport'
  data: any
  timestamp: number
}

export const STORAGE_KEY = 'pendingReports'

export const saveMutationToStorage = (mutation: PendingMutation) => {
  const pendingMutations = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]')
  pendingMutations.push(mutation)
  localStorage.setItem(STORAGE_KEY, JSON.stringify(pendingMutations))
}

export const removeMutationFromStorage = (timestamp: number) => {
  const pendingMutations = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]')
  const filteredMutations = pendingMutations.filter(
    (mutation: PendingMutation) => mutation.timestamp !== timestamp
  )
  localStorage.setItem(STORAGE_KEY, JSON.stringify(filteredMutations))
}
