export const isCursorOverElement = (
  x: number,
  y: number,
  element: HTMLElement
): boolean => {
  const elementRect = element.getBoundingClientRect()

  return (
    x >= elementRect.left &&
    x <= elementRect.right &&
    y >= elementRect.top &&
    y <= elementRect.bottom
  )
}
