import { Box } from '@mui/material'
import React, { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ResetPasswordBackgroundImage } from '@/assets/images/ResetPasswordBackgroundImage'
import { SignInBackgroundImage } from '@/assets/images/SignInBackgroundImage'
import { PAGES } from '@/routes/pages-routes'
import { theme } from '@/theme'

interface Props {
  children: ReactNode
}

const PublicPagesWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const location = useLocation()

  const BackgroundImage = useMemo(() => {
    switch (location.pathname) {
      case PAGES.LOGIN:
        return SignInBackgroundImage
      case PAGES.RESET_PASSWORD:
        return ResetPasswordBackgroundImage
      default:
        return SignInBackgroundImage
    }
  }, [location.pathname])

  const BackgroundImageMarginLeft: string = useMemo(() => {
    switch (location.pathname) {
      case PAGES.LOGIN:
        return '5vw'
      case PAGES.RESET_PASSWORD:
        return '0.8vw'
      default:
        return '5vw'
    }
  }, [location.pathname])

  return (
    <Box display="flex" alignItems="center" width="100%" minHeight="100dvh">
      <Box
        position="relative"
        width="50vw"
        overflow="hidden"
        maxHeight="100dvh"
        display={{ xs: 'none', lg: 'flex' }}
      >
        <Box
          sx={{
            // eslint-disable-next-line max-len
            background: `linear-gradient(180deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
            width: '28.2vw',
            height: '100dvh'
          }}
        />
        <Box
          marginLeft={BackgroundImageMarginLeft}
          position="absolute"
          top="calc(50dvh - 20vw)"
        >
          <BackgroundImage sx={{ width: '40vw', height: '40vw' }} />
        </Box>
      </Box>
      <Box width={{ xs: '100vw', lg: '50vw' }}>{children}</Box>
    </Box>
  )
}

export default PublicPagesWrapper
