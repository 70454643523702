import {
  Box,
  CircularProgress,
  IconButton,
  tooltipClasses,
  Typography
} from '@mui/material'
import React from 'react'

import { DownloadIcon } from '@/assets/icons'
import { TooltipLight } from '@/components/UI'
import { downloadFileFromBlob, showError } from '@/helpers/functions'
import { useGetPatientPDFMutation } from '@/redux/services'
import { theme } from '@/theme'

interface Props {
  fullName: string
  id: string
}

export const TopBlock: React.FC<Props> = ({ fullName, id }): JSX.Element => {
  const [getPdf, { isLoading }] = useGetPatientPDFMutation()

  const downloadPdf = async () => {
    try {
      const data = await getPdf({ id }).unwrap()

      const blob = new Blob([data], {
        type: 'text/pdf'
      })

      downloadFileFromBlob(blob, `${fullName}.pdf`)
    } catch (e: any) {
      showError(e?.data.errors)
    }
  }

  return (
    <Box
      mb="28px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
    >
      <Typography variant="h4" fontWeight={500}>
        {fullName}
      </Typography>
      <TooltipLight
        title={
          <Typography
            sx={{
              lineHeight: '30px',
              whiteSpace: 'pre-line'
            }}
          >
            Download PDF
          </Typography>
        }
        placement="top"
        color="white"
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none'
          }
        }}
        arrow
      >
        <Box>
          {isLoading && (
            <Box
              sx={{
                width: '40px',
                height: '40px'
              }}
            >
              <CircularProgress size={40} color="primary" />
            </Box>
          )}
          {!isLoading && (
            <IconButton
              disabled={isLoading}
              onClick={downloadPdf}
              sx={{
                background: theme.palette.primary.light,
                width: '40px',
                height: '40px',
                borderRadius: '4px',
                display: 'flex',
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                  background: theme.palette.primary.light
                }
              }}
            >
              <DownloadIcon
                sx={{ width: '28px', height: '28px' }}
                fill={theme.palette.primary.main}
              />
            </IconButton>
          )}
        </Box>
      </TooltipLight>
    </Box>
  )
}
