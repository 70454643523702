import { Box, Typography } from '@mui/material'
import React from 'react'

import { theme } from '@/theme'

export const DescriptionsOfDentalTerms: React.FC = (): JSX.Element => {
  return (
    <Box mb={5}>
      <Box display="flex" gap={2} flexDirection="column">
        <Typography color={theme.palette.primary.main} variant="h5">
          Descriptions of dental terms
        </Typography>
        <Typography fontSize={16}>
          <Typography component="span" fontSize={16} fontWeight={600}>
            Plaque:&nbsp;
          </Typography>
          is a sticky, usually invisible film of bacteria that constantly forms
          on our teeth and along the gum line. Some of the bacteria in plaque
          cause cavities and gum disease. Everyone will always have some plaque
          in their mouth – having a mild level is good.
        </Typography>
        <Typography fontSize={16}>
          <Typography component="span" fontSize={16} fontWeight={600}>
            Calculus:&nbsp;
          </Typography>
          is hardened plaque that attaches to your teeth. It contributes to gum
          disease. Not everyone has calculus in their mouth – having none is
          good.
        </Typography>
        <Typography fontSize={16}>
          {' '}
          <Typography component="span" fontSize={16} fontWeight={600}>
            Holes/Cavities/Decay:&nbsp;
          </Typography>
          refers to tooth decay, which occurs when plaque turns sugars from food
          into acid that then dissolves the surface of the tooth. Some decays
          can be fixed with a filling. Avoiding getting a filling may result in
          a tooth becoming painful, infected, and/or needing an extraction.
        </Typography>
        <Typography fontSize={16}>
          <Typography component="span" fontSize={16} fontWeight={600}>
            Bite-wing X-rays:&nbsp;
          </Typography>
          help dental clinicians find holes/decay between teeth that can’t be
          found during an exam visually and with a dental probe. Typically, we
          take two small X-rays, one on each side of the mouth
        </Typography>
        <Typography fontSize={16}>
          <Typography component="span" fontSize={16} fontWeight={600}>
            Fissure sealants:&nbsp;
          </Typography>
          are a safe and painless way of protecting your child's teeth from
          holes/decay. It is a protective coating which can be placed to cover
          the natural grooves on teeth to help prevent food and bacteria getting
          stuck there. We typically place fissure seals on the four back teeth,
          however any tooth may require sealing
        </Typography>
        <Typography fontSize={16}>
          <Typography component="span" fontSize={16} fontWeight={600}>
            Fillings:&nbsp;
          </Typography>
          help restore teeth damaged by caries/decay back to their normal
          function and can prevent further decay developing. If a filling is
          required, the caries will be removed, and the affected area will be
          cleaned. The cleaned-out cavity will then be filled with an
          appropriate dental material.
        </Typography>
        <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
      </Box>
    </Box>
  )
}
