import dayjs from 'dayjs'
import * as yup from 'yup'

import { byteConverter } from '@/helpers/functions'

import {
  MAX_NAME_LENGTH,
  MAX_UPLOAD_FILE_SIZE,
  MIN_UPLOAD_FILE_SIZE
} from '../constants'

export type TMedicareAnswer = yup.InferType<typeof medicareAnswerObject>

export const medicareAnswerObject = yup.object({
  card_number: yup
    .string()
    .matches(/^\d{10}$/, 'Card number must be exactly 10 digits')
    .typeError('Card number must be a valid number')
    .required('Card number is a required field'),
  name_of_child: yup
    .string()
    .max(MAX_NAME_LENGTH, 'Max amount of symbols is 30')
    .required('Name of child is a required field'),
  name_of_signing_person: yup
    .string()
    .max(MAX_NAME_LENGTH, 'Max amount of symbols is 30')
    .required('Name of signing person is a required field'),
  irn_next_number: yup
    .string()
    .matches(/^\d{1}$/, 'Number next to name must be exactly 1 digits')
    .typeError('Number next to name must be a valid number')
    .required('Number next to name is a required field'),
  expiration_date: yup
    .string()
    .test('is-date-not-expired', 'The date is expired', (value) => {
      if (value) {
        return dayjs(value).isAfter(dayjs())
      }
    })
    .required('Expiration date is a required field')
})

export const eFormSchema = yup.object({
  eform_id: yup.string().required(),
  region: yup.string().required(),
  answers: yup.array().of(
    yup.object({
      question: yup.string().required(),
      answer_text: yup.string(),
      selected_choices: yup.array().of(
        yup.object({
          id: yup.string().required()
        })
      ),
      grid_answers: yup.array().of(
        yup.object({
          grid_question: yup.string().required(),
          selected_choices: yup.array().of(
            yup.object({
              id: yup.string().required()
            })
          )
        })
      )
    })
  ),
  medicare_answer: medicareAnswerObject,
  signature: yup.string(),
  medicare_consent_answer: yup.array().of(
    yup.object({
      medicare_consent_question: yup.string().required(),
      consent_given: yup.boolean(),
      consent_answer: yup.string()
    })
  )
})

export const patientFilesValidation = yup.array().of(
  yup.lazy((value) =>
    yup.object({
      id: yup.string(),
      name: yup.string(),
      patient: yup.string(),
      extension: yup.string(),
      size: yup.string(),
      confidential: yup.boolean(),
      file: yup.mixed<File>().when([], {
        is: () => value.file instanceof File,
        then: (schema) =>
          schema.test(
            'test-file-size',
            (params) => {
              const size = params.value.size

              const condition =
                size < MIN_UPLOAD_FILE_SIZE
                  ? 'less'
                  : size > MAX_UPLOAD_FILE_SIZE && 'more'

              return `This file size is ${condition} than ${byteConverter(
                condition === 'less'
                  ? MIN_UPLOAD_FILE_SIZE
                  : MAX_UPLOAD_FILE_SIZE
              )}`
            },
            async () => {
              const file = value.file

              return !(
                file.size < MIN_UPLOAD_FILE_SIZE ||
                file.size > MAX_UPLOAD_FILE_SIZE
              )
            }
          ),
        otherwise: (schema) => schema
      })
    })
  )
)

export const eFormSchemaWithFiles = eFormSchema.shape({
  files: patientFilesValidation
})

export type TEFormSchemaWithFiles = yup.InferType<typeof eFormSchemaWithFiles>
