import { Box, Modal, Paper } from '@mui/material'
import React from 'react'

interface Props {
  children: React.ReactNode
  style?: React.CSSProperties
  open: boolean
  onClose: () => void
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 2,
  outline: 'none'
}

const CustomModal: React.FC<Props> = (props) => {
  const { children, style, open, onClose } = props
  return (
    <Modal
      sx={{ zIndex: 999999, overflow: 'auto' }}
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          ...modalStyle,
          ...style
        }}
      >
        <Paper tabIndex={-1}>{children}</Paper>
      </Box>
    </Modal>
  )
}

export default CustomModal
