import { Box, CssBaseline } from '@mui/material'
import React from 'react'

import { PrivatePagesHeader, Sidebar } from '@/components'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { setIsSidebarOpen } from '@/redux/features/preferenceSlice'
import { theme } from '@/theme'

interface Props {
  children: React.ReactNode
  sidebar?: boolean
}

const PrivatePagesWrapperWithSidebar: React.FC<Props> = ({
  children,
  sidebar = true
}): JSX.Element => {
  const openDrawer = useAppSelector(
    (state) => state.preferenceStore.isSidebarOpen
  )
  const dispatch = useAppDispatch()

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: theme.palette.grey[100],
        height: '100dvh'
      }}
    >
      <CssBaseline />
      <PrivatePagesHeader
        openDrawer={openDrawer}
        sidebar={sidebar}
        handleDrawer={() => dispatch(setIsSidebarOpen(!openDrawer))}
      />
      {sidebar && <Sidebar open={openDrawer} />}
      <Box
        component="main"
        sx={{
          width: '100%',
          mt: '80px',
          paddingTop: '32px',
          overflowX: 'hidden',
          overflowY: 'auto',
          height: 'calc(100dvh - 80px)'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default PrivatePagesWrapperWithSidebar
