import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Typography
} from '@mui/material'
import React, {
  ChangeEvent,
  UIEvent,
  useEffect,
  useMemo,
  useState
} from 'react'

import { CheckIcon, CrossIcon, PencilIcon } from '@/assets/icons'
import AsyncAutocomplete from '@/components/UI/AsyncAutocomplete'
import { cleanObject } from '@/helpers/functions'
import { filterDuplicatesByKey } from '@/helpers/functions/filterDuplicatesByKey'
import { DEBOUNCE_MS, useDebouncedCallback } from '@/hooks'
import { useGetAllTreatmentCodesQuery } from '@/redux/services/treatmentCodes'
import {
  TGetAllTreatmentCodesParams,
  TTreatmentCode,
  TTreatmentPlanItem
} from '@/types/TreatmentCodes'
import { ITEMS_PER_PAGE } from '@/utils/constants'

interface Props {
  isHover: boolean
  item: TTreatmentPlanItem
  handleTreatmentCodeChange: (
    id: string,
    value: { treatment_code: string; price: number; description: string }
  ) => void
}

const initialTreatmentCodesSearchParams: TGetAllTreatmentCodesParams = {
  page: 1,
  page_size: ITEMS_PER_PAGE[0],
  search: ''
}

export const TreatmentTableItemNumber: React.FC<Props> = (
  props
): JSX.Element => {
  const { isHover, item, handleTreatmentCodeChange } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<{
    treatment_code: string
    price: number
    description: string
  }>({
    treatment_code: item.treatment_code,
    price: item.price,
    description: item.description || ''
  })
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false)
  const [codesSearchValue, setCodesSearchValue] = useState<string>('')
  const [loadedCodes, setLoadedCodes] = useState<TTreatmentCode[]>([])
  const [treatmentCodesSearchParams, setTreatmentCodesSearchParams] =
    useState<TGetAllTreatmentCodesParams>({
      ...initialTreatmentCodesSearchParams
    })

  const { data, isFetching } = useGetAllTreatmentCodesQuery(
    {
      ...cleanObject(treatmentCodesSearchParams)
    },
    { refetchOnMountOrArgChange: true }
  )

  const codes = data?.data

  const handleChangeTreatmentCodesSearchParams = (
    key: string,
    value: string | number
  ) => {
    setTreatmentCodesSearchParams((prev) => ({
      ...prev,
      page: 1,
      [key]: value
    }))
  }

  const handleLoadMoreCodes = (e: UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement

    if (
      !isFetching &&
      loadedCodes.length < codes!.count &&
      (element.scrollHeight === element.scrollTop + element.clientHeight ||
        element.scrollHeight + 0.5 === element.scrollTop + element.clientHeight)
    ) {
      handleChangeTreatmentCodesSearchParams(
        'page',
        ++treatmentCodesSearchParams.page!
      )
    }
  }

  const applySearchDebounce = useDebouncedCallback((value) => {
    handleChangeTreatmentCodesSearchParams('search', value)
  }, DEBOUNCE_MS)

  const handleSearchCodes = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCodesSearchValue(e.target.value)
    applySearchDebounce(e.target.value)
  }

  const onCodeClick = (codeItem: TTreatmentCode) => {
    setCodesSearchValue(codeItem.code)
    setSelectedItem({
      price: Number(codeItem.price),
      treatment_code: codeItem.code,
      description: codeItem.description
    })
    setIsAutocompleteOpen(false)
  }

  const codesFormatted = useMemo(() => {
    return loadedCodes.filter((p) =>
      treatmentCodesSearchParams.search
        ? codes?.results.find((r) => r.id === p.id)
        : true
    )
  }, [loadedCodes, codes?.results, treatmentCodesSearchParams.search])

  const onSubmit = () => {
    handleTreatmentCodeChange(item.id, {
      price: Number(selectedItem?.price),
      treatment_code: selectedItem?.treatment_code || '',
      description: selectedItem?.description || ''
    })
    setIsEdit(false)
  }

  const onCancel = () => {
    handleTreatmentCodeChange(item.id, {
      price: Number(item.price),
      treatment_code: item.treatment_code,
      description: item.description || ''
    })

    setIsEdit(false)
  }
  useEffect(() => {
    if (codes?.results.length) {
      setLoadedCodes((prev) =>
        filterDuplicatesByKey([...prev, ...codes.results], 'id')
      )
    }
  }, [codes?.results, treatmentCodesSearchParams.search])

  useEffect(() => {
    if (item.treatment_code && !item.price) {
      const code = loadedCodes.find((l) => l.id === item.treatment_code)
      if (code) {
        handleTreatmentCodeChange(item.id, {
          price: Number(code.price),
          treatment_code: item.treatment_code,
          description: item.description || ''
        })
      }
    }
  }, [
    handleTreatmentCodeChange,
    item.description,
    item.id,
    item.price,
    item.treatment_code,
    loadedCodes
  ])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%'
      }}
    >
      {!isEdit && isHover && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      <Box flexGrow={1} minWidth="100px">
        {isEdit ? (
          <AsyncAutocomplete
            open={isAutocompleteOpen}
            PaperProps={{ onScroll: handleLoadMoreCodes }}
            inputValue={codesSearchValue}
            placeholder="Code"
            selected={
              loadedCodes.find((l) => l.code === selectedItem?.treatment_code)
                ?.code
            }
            onOpen={() => setIsAutocompleteOpen(true)}
            onClose={() => setIsAutocompleteOpen(false)}
            onClear={() =>
              setSelectedItem({
                price: 0,
                treatment_code: '',
                description: ''
              })
            }
            onInputChange={handleSearchCodes}
          >
            {isFetching ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '25px 0'
                }}
              >
                <CircularProgress
                  sx={{
                    width: '15px',
                    height: '15px'
                  }}
                />
              </Box>
            ) : (
              codesFormatted.map((code) => (
                <MenuItem key={code.id} onClick={() => onCodeClick(code)}>
                  {code.code}
                </MenuItem>
              ))
            )}
            {!codes?.results.length && !isFetching && (
              <Box
                sx={{
                  padding: '25px 10px',
                  textAlign: 'center'
                }}
              >
                <Typography>No codes were found</Typography>
              </Box>
            )}
          </AsyncAutocomplete>
        ) : (
          <Typography>{selectedItem?.treatment_code}</Typography>
        )}
      </Box>

      {isEdit && (
        <Box
          sx={{
            display: 'flex',
            gap: '4px'
          }}
        >
          <IconButton onClick={onSubmit}>
            <CheckIcon
              sx={{
                width: '24px',
                height: '24px',
                path: {
                  fill: '#010800'
                }
              }}
            />
          </IconButton>
          <IconButton onClick={onCancel}>
            <CrossIcon
              sx={{
                width: '24px',
                height: '24px'
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}
