import { Box, CircularProgress } from '@mui/material'
import toast, { CheckmarkIcon, ToastOptions } from 'react-hot-toast'

import { IToast } from '@/utils/lib'

export default function ToastPromiseLoader() {
  return (
    <Box
      sx={{
        width: '20px',
        height: '20px'
      }}
    >
      <CircularProgress size={20} />
    </Box>
  )
}

export const toastPromise = (
  promise: Promise<any>,
  content: { loading: string; success: string; error: string },
  props?: Partial<ToastOptions>
) =>
  toast.promise(
    promise,
    {
      loading: content.loading,
      success: content.success,
      error: content.error
    },
    {
      ...props,
      style: { fontFamily: 'Inter' },
      icon: <ToastPromiseLoader />,
      success: {
        icon: props?.icon || <CheckmarkIcon />
      }
    }
  )

export const toastLoading = ({ content, ...rest }: IToast) =>
  toast.loading(content, {
    ...rest,
    style: { fontFamily: 'Inter' },
    icon: rest.icon || <ToastPromiseLoader />
  })
