/* eslint-disable max-len */
import { FileSizes } from '@/types'

export const byteConverter = (bytes: number, decimals = 1) => {
  const K_UNIT = 1024

  if (bytes === 0) return `0 ${FileSizes.Bytes}`

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT))
  const resp = `${parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals))} ${Object.values(FileSizes)[i]}`

  return resp
}
