export enum ERole {
  Director = 'Director',
  Manager = 'Manager',
  Admin = 'Admin',
  Clinician = 'Clinician',
  School = 'School'
}

export enum EPermission {
  // SCHOOLS
  VIEW_SCHOOLS = 'VIEW_SCHOOLS',
  ACTION_SCHOOLS_EDIT = 'ACTION_SCHOOLS_EDIT',
  ACTION_SCHOOLS_ARCHIVE = 'ACTION_SCHOOLS_ARCHIVE',
  ACTION_SCHOOLS_DELETE = 'ACTION_SCHOOLS_DELETE',
  ACTION_SCHOOLS_ADD = 'ACTION_SCHOOLS_ADD',
  ACTION_SCHOOLS_3D_DOWNLOAD_TEMPLATE = 'ACTION_SCHOOLS_3D_DOWNLOAD_TEMPLATE',
  ACTION_SCHOOLS_3D_MASS_UPLOAD = 'ACTION_SCHOOLS_3D_MASS_UPLOAD',
  ACTION_SCHOOLS_3D_DOWNLOAD = 'ACTION_SCHOOLS_3D_DOWNLOAD',

  ACTION_SCHOOL_FILES_ADD = 'ACTION_SCHOOL_FILES_ADD',
  VIEW_SCHOOL_CONFIDENTIAL_FILES = 'VIEW_SCHOOL_CONFIDENTIAL_FILES',
  ACTION_SCHOOL_FILES_MARK_AS_CONFIDENTIAL = 'ACTION_SCHOOL_FILES_MARK_AS_CONFIDENTIAL',
  VIEW_SCHOOL_DETAILS = 'VIEW_SCHOOL_DETAILS',
  VIEW_SCHOOL_INFORMATION_SIDEBARTAB = 'VIEW_SCHOOL_INFORMATION_SIDEBARTAB',
  ACTION_SCHOOL_EDIT_DENTAL_INFORMATION = 'ACTION_SCHOOL_EDIT_DENTAL_INFORMATION',
  SEE_SCHOOL_ADMIN_NOTES = 'SEE_SCHOOL_ADMIN_NOTES',
  SEE_SCHOOL_CREDENTIALS = 'SEE_SCHOOL_CREDENTIALS',
  SEE_SCHOOL_GENERAL_NOTES = 'SEE_SCHOOL_GENERAL_NOTES',
  ACTION_SCHOOL_EDIT_STATUS = 'ACTION_SCHOOL_EDIT_STATUS',

  SEE_SCHOOLS_TABLE_CELL_SCHOOL = 'SEE_SCHOOLS_TABLE_CELL_SCHOOL',
  SEE_SCHOOLS_TABLE_CELL_CONTACT_PERSON = 'SEE_SCHOOLS_TABLE_CELL_CONTACT_PERSON',
  SEE_SCHOOLS_TABLE_CELL_DENTAL_VISIT_DATE = 'SEE_SCHOOLS_TABLE_CELL_DENTAL_VISIT_DATE',
  SEE_SCHOOLS_TABLE_CELL_FORM_SUBMISSION = 'SEE_SCHOOLS_TABLE_CELL_FORM_SUBMISSION',
  SEE_SCHOOLS_TABLE_CELL_STATUS = 'SEE_SCHOOLS_TABLE_CELL_STATUS',
  SEE_SCHOOLS_TABLE_CELL_VALUE = 'SEE_SCHOOLS_TABLE_CELL_VALUE',

  // EFORMS
  VIEW_EFORMS = 'VIEW_EFORMS',
  ACTION_EFORMS_DELETE = 'ACTION_EFORMS_DELETE',
  ACTION_EFORMS_DOWNLOAD = 'ACTION_EFORMS_DOWNLOAD',

  // PATIENTS
  VIEW_PATIENTS = 'VIEW_PATIENTS',
  ACTION_PATIENTS_VIEW = 'ACTION_PATIENTS_VIEW',
  ACTION_PATIENTS_DOWNLOAD = 'ACTION_PATIENTS_DOWNLOAD',
  ACTION_PATIENTS_DELETE = 'ACTION_PATIENTS_DELETE',
  ACTION_PATIENTS_3D_ADD_NEW_SUBLIST = 'ACTION_PATIENTS_3D_ADD_NEW_SUBLIST',
  ACTION_PATIENTS_3D_SEND_REPORTS = 'ACTION_PATIENTS_3D_SEND_REPORTS',
  ACTION_PATIENTS_3D_DOWNLOAD_PATIENTS_LIST = 'ACTION_PATIENTS_3D_DOWNLOAD_PATIENTS_LIST',
  ACTION_PATIENTS_3D_PATIENTS_ADD = 'ACTION_PATIENTS_3D_PATIENTS_ADD',
  SEE_PATIENTS_TABLE_CELL_PATIENT = 'SEE_PATIENTS_TABLE_CELL_PATIENT',
  SEE_PATIENTS_TABLE_CELL_PARENT_CONTACT = 'SEE_PATIENTS_TABLE_CELL_PARENT_CONTACT',
  SEE_PATIENTS_TABLE_CELL_SCHOOL = 'SEE_PATIENTS_TABLE_CELL_SCHOOL',
  SEE_PATIENTS_TABLE_CELL_YEAR_LEVEL = 'SEE_PATIENTS_TABLE_CELL_YEAR_LEVEL',
  SEE_PATIENTS_TABLE_CELL_CLASS = 'SEE_PATIENTS_TABLE_CELL_CLASS',
  SEE_PATIENTS_TABLE_CELL_BALANCE = 'SEE_PATIENTS_TABLE_CELL_BALANCE',
  SEE_PATIENTS_TABLE_CELL_PATIENT_TYPE = 'SEE_PATIENTS_TABLE_CELL_PATIENT_TYPE',
  SEE_PATIENTS_TABLE_CELL_DENTAL_STATUS = 'SEE_PATIENTS_TABLE_CELL_DENTAL_STATUS',
  SEE_PATIENTS_TABLE_CELL_REPORT_SENT = 'SEE_PATIENTS_TABLE_CELL_REPORT_SENT',
  SEE_PATIENTS_TABLE_CELL_ADMIN_NOTES = 'SEE_PATIENTS_TABLE_CELL_ADMIN_NOTES',
  SEE_PATIENTS_TABLE_CELL_PARENT_NOTES = 'SEE_PATIENTS_TABLE_CELL_PARENT_NOTES',
  SEE_PATIENTS_TABLE_CELL_ACTION = 'SEE_PATIENTS_TABLE_CELL_ACTION',
  ACTION_PATIENTS_TABLE_CELL_EDIT_DENTAL_STATUS = 'ACTION_PATIENTS_TABLE_CELL_EDIT_DENTAL_STATUS',
  ACTION_PATIENTS_TABLE_CELL_EDIT_PATIENT_TYPE = 'ACTION_PATIENTS_TABLE_CELL_EDIT_PATIENT_TYPE',
  // SETTINGS
  VIEW_SETTINGS = 'VIEW_SETTINGS',

  // STAFF
  VIEW_STAFF = 'VIEW_STAFF',
  ACTION_STAFF_DELETE = 'ACTION_STAFF_DELETE',
  ACTION_STAFF_ACTIVATE = 'ACTION_STAFF_ACTIVATE',
  ACTION_STAFF_DEACTIVATE = 'ACTION_STAFF_DEACTIVATE',
  ACTION_STAFF_RESEND_INVITE_DIRECTOR = 'ACTION_STAFF_RESEND_INVITE_DIRECTOR',
  ACTION_STAFF_RESEND_INVITE_MANAGER = 'ACTION_STAFF_RESEND_INVITE_MANAGER',
  ACTION_STAFF_RESEND_INVITE_ADMIN = 'ACTION_STAFF_RESEND_INVITE_ADMIN',
  ACTION_STAFF_RESEND_INVITE_CLINICIAN = 'ACTION_STAFF_RESEND_INVITE_CLINICIAN',
  ACTION_STAFF_AE_DIRECTOR = 'ACTION_STAFF_AE_DIRECTOR',
  ACTION_STAFF_AE_MANAGER = 'ACTION_STAFF_AE_MANAGER',
  ACTION_STAFF_AE_ADMIN = 'ACTION_STAFF_AE_ADMIN',
  ACTION_STAFF_AE_CLINICIAN = 'ACTION_STAFF_AE_CLINICIAN'
}

const viewPermissions: Record<keyof typeof EPermission, ERole[]> = {
  // SCHOOL
  ACTION_SCHOOLS_ADD: [ERole.Director, ERole.Manager, ERole.Admin],
  ACTION_SCHOOL_FILES_ADD: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  VIEW_SCHOOL_CONFIDENTIAL_FILES: [ERole.Director, ERole.Manager, ERole.Admin],
  ACTION_SCHOOL_FILES_MARK_AS_CONFIDENTIAL: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin
  ],
  VIEW_SCHOOL_DETAILS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  VIEW_SCHOOL_INFORMATION_SIDEBARTAB: [ERole.School],
  ACTION_SCHOOLS_EDIT: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.School
  ],
  ACTION_SCHOOLS_ARCHIVE: [ERole.Director, ERole.Manager, ERole.Admin],
  ACTION_SCHOOLS_DELETE: [ERole.Director, ERole.Manager, ERole.Admin],
  VIEW_SCHOOLS: [ERole.Director, ERole.Manager, ERole.Admin, ERole.Clinician],
  ACTION_SCHOOLS_3D_DOWNLOAD_TEMPLATE: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin
  ],
  ACTION_SCHOOLS_3D_MASS_UPLOAD: [ERole.Director, ERole.Manager, ERole.Admin],
  ACTION_SCHOOLS_3D_DOWNLOAD: [ERole.Director, ERole.Manager, ERole.Admin],

  ACTION_SCHOOL_EDIT_DENTAL_INFORMATION: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin
  ],

  SEE_SCHOOL_CREDENTIALS: [ERole.Director, ERole.Manager, ERole.Admin],
  SEE_SCHOOL_ADMIN_NOTES: [ERole.Director, ERole.Manager, ERole.Admin],
  SEE_SCHOOL_GENERAL_NOTES: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  ACTION_SCHOOL_EDIT_STATUS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_SCHOOLS_TABLE_CELL_SCHOOL: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_SCHOOLS_TABLE_CELL_CONTACT_PERSON: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_SCHOOLS_TABLE_CELL_DENTAL_VISIT_DATE: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_SCHOOLS_TABLE_CELL_FORM_SUBMISSION: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_SCHOOLS_TABLE_CELL_STATUS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_SCHOOLS_TABLE_CELL_VALUE: [ERole.Director, ERole.Manager],

  // EFORMS
  VIEW_EFORMS: [ERole.Director, ERole.Manager, ERole.Admin, ERole.Clinician],
  ACTION_EFORMS_DOWNLOAD: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  ACTION_EFORMS_DELETE: [ERole.Director],

  // PATIENTS
  VIEW_PATIENTS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  ACTION_PATIENTS_DELETE: [ERole.Director],
  ACTION_PATIENTS_VIEW: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  ACTION_PATIENTS_DOWNLOAD: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],

  ACTION_PATIENTS_3D_ADD_NEW_SUBLIST: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin
  ],
  ACTION_PATIENTS_3D_DOWNLOAD_PATIENTS_LIST: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  ACTION_PATIENTS_3D_SEND_REPORTS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  ACTION_PATIENTS_3D_PATIENTS_ADD: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_PATIENTS_TABLE_CELL_PATIENT: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  SEE_PATIENTS_TABLE_CELL_PARENT_CONTACT: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  SEE_PATIENTS_TABLE_CELL_SCHOOL: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  SEE_PATIENTS_TABLE_CELL_YEAR_LEVEL: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  SEE_PATIENTS_TABLE_CELL_CLASS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  SEE_PATIENTS_TABLE_CELL_BALANCE: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_PATIENTS_TABLE_CELL_PATIENT_TYPE: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  SEE_PATIENTS_TABLE_CELL_DENTAL_STATUS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician,
    ERole.School
  ],
  SEE_PATIENTS_TABLE_CELL_REPORT_SENT: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_PATIENTS_TABLE_CELL_ADMIN_NOTES: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_PATIENTS_TABLE_CELL_PARENT_NOTES: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  SEE_PATIENTS_TABLE_CELL_ACTION: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  ACTION_PATIENTS_TABLE_CELL_EDIT_DENTAL_STATUS: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],
  ACTION_PATIENTS_TABLE_CELL_EDIT_PATIENT_TYPE: [
    ERole.Director,
    ERole.Manager,
    ERole.Admin,
    ERole.Clinician
  ],

  // SETTINGS
  VIEW_SETTINGS: [ERole.Director],

  // STAFF
  VIEW_STAFF: [ERole.Director, ERole.Manager, ERole.Admin],
  ACTION_STAFF_DELETE: [ERole.Director],
  ACTION_STAFF_ACTIVATE: [ERole.Director],
  ACTION_STAFF_DEACTIVATE: [ERole.Director],
  ACTION_STAFF_RESEND_INVITE_DIRECTOR: [ERole.Director],
  ACTION_STAFF_RESEND_INVITE_MANAGER: [ERole.Director, ERole.Manager],
  ACTION_STAFF_RESEND_INVITE_ADMIN: [ERole.Director, ERole.Manager],
  ACTION_STAFF_RESEND_INVITE_CLINICIAN: [ERole.Director, ERole.Manager],
  ACTION_STAFF_AE_DIRECTOR: [ERole.Director],
  ACTION_STAFF_AE_MANAGER: [ERole.Director, ERole.Manager],
  ACTION_STAFF_AE_ADMIN: [ERole.Director, ERole.Manager],
  ACTION_STAFF_AE_CLINICIAN: [ERole.Director, ERole.Manager, ERole.Admin]
} as const

export const canI = (myRole: keyof typeof ERole, view: EPermission) => {
  const result = viewPermissions[view]?.includes(ERole[myRole])
  return !!result
}

export const getCorrectAEStaffPermission = (roleToAE: ERole) => {
  const switcher = {
    [ERole.Director]: EPermission.ACTION_STAFF_AE_DIRECTOR,
    [ERole.Manager]: EPermission.ACTION_STAFF_AE_MANAGER,
    [ERole.Admin]: EPermission.ACTION_STAFF_AE_ADMIN,
    [ERole.Clinician]: EPermission.ACTION_STAFF_AE_CLINICIAN
  }
  return switcher[roleToAE as keyof typeof switcher]
}

export const getCorrectInviteStaffPermission = (roleToAE: ERole) => {
  const switcher = {
    [ERole.Director]: EPermission.ACTION_STAFF_RESEND_INVITE_DIRECTOR,
    [ERole.Manager]: EPermission.ACTION_STAFF_RESEND_INVITE_MANAGER,
    [ERole.Admin]: EPermission.ACTION_STAFF_RESEND_INVITE_ADMIN,
    [ERole.Clinician]: EPermission.ACTION_STAFF_RESEND_INVITE_CLINICIAN
  }
  return switcher[roleToAE as keyof typeof switcher]
}

export const getInviteableRoles = (myRole: keyof typeof ERole) => {
  const switcher = {
    Director: [ERole.Director, ERole.Manager, ERole.Admin, ERole.Clinician],
    Manager: [ERole.Manager, ERole.Admin, ERole.Clinician],
    Admin: [ERole.Clinician]
  }
  return switcher[myRole as keyof typeof switcher]
}
