import {
  TLoginParams,
  TLoginResponse,
  TRefreshToken,
  TRefreshTokenResponse
} from '@/types/Auth'
import { API_METHODS, AUTH_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const authApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    loginUser: build.mutation<TLoginResponse, TLoginParams>({
      query: (credentials) => ({
        url: AUTH_API.TOKEN,
        method: API_METHODS.POST,
        body: credentials
      })
    }),
    refreshToken: build.mutation<TRefreshTokenResponse, TRefreshToken>({
      query: (refresh) => ({
        url: AUTH_API.REFRESH,
        method: API_METHODS.POST,
        body: refresh
      })
    })
  })
})

export const { useLoginUserMutation, useRefreshTokenMutation } = authApi
