import {
  TCDBSReportParams,
  TCDBSReportResponse,
  TGetReportParams,
  TProbonoCDBSResponse
} from '@/types'
import { API_METHODS, PATIENTS_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const cdbsReportsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCdbsReport: builder.mutation<TCDBSReportResponse, TCDBSReportParams>({
      query: ({ patientId, ...rest }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/cdbs-reports/`,
        method: API_METHODS.POST,
        body: rest
      }),
      invalidatesTags: ['CDBSReports']
    }),
    getCDBSReport: builder.query<TProbonoCDBSResponse, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/cdbs-reports/${reportId}/`,
        method: API_METHODS.GET
      }),
      providesTags: ['CDBSReports']
    }),
    sendCDBSReport: builder.mutation<void, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/cdbs-reports/${reportId}/send_report/`,
        method: API_METHODS.GET
      })
    }),
    downloadCDBSReport: builder.mutation<Blob, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/cdbs-reports/${reportId}/download_report/`,
        method: API_METHODS.GET,
        responseHandler: (response: { blob: () => any }) => response.blob()
      })
    }),
    deleteCDBSReport: builder.mutation<void, TGetReportParams>({
      query: ({ patientId, reportId }) => ({
        url: `${PATIENTS_API.PATIENTS}${patientId}/cdbs-reports/${reportId}/`,
        method: API_METHODS.DELETE
      }),
      invalidatesTags: ['Patients']
    }),
    editCdbsReport: builder.mutation<TCDBSReportResponse, TCDBSReportParams>({
      query: (body) => ({
        url: `${PATIENTS_API.PATIENTS}${body.patientId}/cdbs-reports/${body.reportId}/`,
        method: API_METHODS.PUT,
        body
      })
    })
  })
})

export const {
  useAddCdbsReportMutation,
  useGetCDBSReportQuery,
  useEditCdbsReportMutation,
  useSendCDBSReportMutation,
  useDeleteCDBSReportMutation,
  useDownloadCDBSReportMutation
} = cdbsReportsApi
