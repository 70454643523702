import { useEffect, useRef } from 'react'

import { useUpdateCdbsFiles } from '@/hooks/useUpdateCdbsFiles'
import {
  useAddCdbsReportMutation,
  useEditCdbsReportMutation
} from '@/redux/services/cdbsReportsApi'
import {
  useAddProbonoReportMutation,
  useEditProbonoReportMutation
} from '@/redux/services/probonoReportsApi'
import { toastError, toastSuccess } from '@/utils/lib'
import {
  PendingMutation,
  removeMutationFromStorage,
  STORAGE_KEY
} from '@/utils/lib/offlineStorage'

export const OfflineSync = () => {
  const [addProbonoReport] = useAddProbonoReportMutation()

  const [editProbonoReport] = useEditProbonoReportMutation()

  const [addCdbsReport] = useAddCdbsReportMutation()

  const [editCdbsReport] = useEditCdbsReportMutation()

  const { handleFileChanges } = useUpdateCdbsFiles()

  const isProcessing = useRef(false)

  const timeoutRef = useRef<number>()

  const debounce = (fn: () => void, delay: number) => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = window.setTimeout(() => {
      fn()
    }, delay)
  }

  useEffect(() => {
    const processPendingMutations = async () => {
      // If already processing, skip
      if (isProcessing.current) return

      const pendingMutations: PendingMutation[] = JSON.parse(
        localStorage.getItem(STORAGE_KEY) || '[]'
      )
      if (pendingMutations.length === 0) return

      isProcessing.current = true

      for (const mutation of pendingMutations) {
        const isAddMutation = mutation.type.includes('Add')

        try {
          if (mutation.type === 'AddProbonoReport') {
            await addProbonoReport(mutation.data).unwrap()
          } else if (mutation.type === 'EditProbonoReport') {
            await editProbonoReport(mutation.data).unwrap()
          } else if (mutation.type === 'AddCdbsReport') {
            await addCdbsReport(mutation.data).unwrap()
          } else if (mutation.type === 'EditCdbsReport') {
            await editCdbsReport(mutation.data).unwrap()
          }

          toastSuccess({
            content: `Offline ${
              isAddMutation ? 'created' : 'updated'
            } report has been synchronized`
          })

          removeMutationFromStorage(mutation.timestamp)
        } catch (error: any) {
          toastError({
            content: `Failed to sync offline ${
              isAddMutation ? 'created' : 'updated'
            } report`
          })
        } finally {
          isProcessing.current = false
        }
      }
    }

    const handleOnline = () => {
      toastSuccess({ content: 'You are back online' })
      debounce(processPendingMutations, 1000)
    }
    window.addEventListener('online', handleOnline)

    const handleOffline = () => {
      toastSuccess({ content: 'You are now offline' })
    }
    window.addEventListener('offline', handleOffline)

    if (navigator.onLine) {
      debounce(processPendingMutations, 1000)
    }

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)

      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current)
      }
    }
  }, [
    addCdbsReport,
    addProbonoReport,
    editCdbsReport,
    editProbonoReport,
    handleFileChanges
  ])

  return null
}
