import { Box, SvgIconProps, Typography } from '@mui/material'
import React from 'react'

import { theme } from '@/theme'

interface Props {
  active: boolean
  Icon: React.FC<SvgIconProps>
  title: string
}

const TabItem: React.FC<Props> = ({ active, title, Icon }): JSX.Element => {
  return (
    <Box
      sx={{
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        cursor: 'pointer',
        borderRadius: '4px',
        background: active
          ? theme.palette.secondary.light
          : theme.palette.grey[200]
      }}
    >
      <Icon
        sx={{
          width: '20px',
          height: '20px'
        }}
        fill={active ? theme.palette.primary.main : ''}
      />
      <Typography color={active ? theme.palette.primary.main : ''}>
        {title}
      </Typography>
    </Box>
  )
}

export default TabItem
