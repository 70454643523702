import { RefObject, useState } from 'react'

import { isCursorOverElement } from '@/helpers/functions'

import useEventListener from './useEventListener'

interface IInitialOptions {
  useMousemove?: boolean
  hideIsElementOutAfterClick?: boolean
}

const initialOptions: IInitialOptions = {
  useMousemove: false,
  hideIsElementOutAfterClick: false
}

export function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
  options?: IInitialOptions
): boolean {
  const [value, setValue] = useState<boolean>(false)

  options = {
    ...initialOptions,
    ...options
  }

  const handleMouseEnter = () => {
    setValue(true)
  }
  const handleMouseLeave = () => {
    setValue(false)
  }

  useEventListener({
    type: 'mouseenter',
    listener: handleMouseEnter,
    element: elementRef
  })

  useEventListener({
    type: 'mouseleave',
    listener: handleMouseLeave,
    element: elementRef
  })

  useEventListener({
    type: 'mousemove',
    listener: (e) => {
      if (!elementRef.current?.contains(e?.target as Node | null)) {
        setValue(false)
      }
    },
    element: value && options.useMousemove ? document : null
  })

  useEventListener({
    type: 'mouseup',
    listener: (e) => {
      const isOver = isCursorOverElement(
        e.clientX,
        e.clientY,
        elementRef.current!
      )

      !isOver && setValue(false)
    },
    element: value && options.hideIsElementOutAfterClick ? document : null
  })

  return value
}
