import * as Sentry from '@sentry/react'
import { toast } from 'react-hot-toast'

const notify = (text: string) => toast.error(text)

export const requestErrorLogger = () => (next: any) => (action: any) => {
  if (action?.payload?.originalStatus >= 500) {
    const message = action?.payload?.data?.error?.message || 'Server error!'
    Sentry.captureMessage(`[redux-mw] Error: ${message}`)
    notify(message)
  }

  return next(action)
}
