import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { requestErrorLogger } from '@/helpers/middlewares/requestErrorLoger'

import eFormSlice from '../features/eFormSlice'
import preferenceSlice from '../features/preferenceSlice'
import userSlice from '../features/userSlice'
import { apiSlice } from '../services/apiSlice'

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  userStore: persistReducer({ key: 'user', storage }, userSlice),
  preferenceStore: persistReducer(
    { key: 'userPreference', storage },
    preferenceSlice
  ),
  eFormStore: persistReducer(
    {
      key: 'eform',
      storage
    },
    eFormSlice
  )
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: import.meta.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Hide blob error - Ignore all paths that might contain Blobs
        ignoredActionPaths: [
          'payload',
          'meta.arg.body',
          'meta.baseQueryMeta.response',
          'meta.baseQueryMeta.request'
        ],
        // Hide blob error - Ignore all API queries data paths
        ignoredPaths: [
          `${apiSlice.reducerPath}.queries`,
          `${apiSlice.reducerPath}.mutations`
        ]
      }
    }).concat([apiSlice.middleware, requestErrorLogger])
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
