import { TableCell } from '@mui/material'
import { useState } from 'react'

import { TableBodyItem } from '@/types'

type Props<T, C extends TableBodyItem<T>, G extends T> = {
  row: T | G
  cell: C
  isRowHovered: boolean
  index: number
  onCellClick?: (row: T | G, cell: C) => void
}

export default function HoverableTableCell<
  T,
  C extends TableBodyItem<T>,
  G extends T
>(props: Props<T, C, G>) {
  const { row, cell, isRowHovered, index, onCellClick } = props
  const [isHover, setIsHover] = useState<boolean>(false)

  return (
    <TableCell
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      key={cell.name}
      onClick={() =>
        cell.clickable
          ? typeof onCellClick === 'function' && onCellClick(row, cell)
          : null
      }
      sx={{
        paddingLeft: '12px',
        paddingRight: '12px',
        ...(typeof onCellClick === 'function' &&
          cell.clickable && {
            cursor: 'pointer'
          })
      }}
      align={cell.align || 'left'}
    >
      {cell.render(row, isRowHovered, isHover, index)}
    </TableCell>
  )
}
