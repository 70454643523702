import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import { theme } from '@/theme'

interface Props {
  cardNumber: string
  numberNextToName: number
  nameOfChild: string
  expiry: string
}

export const MedicareInformation: React.FC<Props> = (props): JSX.Element => {
  const { cardNumber, numberNextToName, nameOfChild, expiry } = props

  const medicareInfo = [
    {
      name: 'Card number',
      value: cardNumber
    },
    {
      name: 'Number next to name',
      value: numberNextToName
    },
    {
      name: 'Name of child',
      value: nameOfChild
    },
    {
      name: 'Expiry',
      value: expiry
    }
  ]

  return (
    <>
      <Box mb="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          Medicare information
        </Typography>
      </Box>
      <Grid
        mb="20px"
        container
        lg={6}
        xs={12}
        item
        spacing={2}
        display="flex"
        flexWrap="wrap"
      >
        {medicareInfo.map((el) => (
          <Grid
            item
            key={el.name}
            sm={6}
            xs={12}
            gap={1}
            display="flex"
            minWidth="200px"
            flexDirection="column"
          >
            <Typography
              variant="body1"
              fontSize={'16px'}
              color={theme.palette.secondary.main}
            >
              {el.name}
            </Typography>
            <Typography variant="h5">{el.value}</Typography>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
