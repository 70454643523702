import { CountryIso2 } from 'react-international-phone'

import { SchoolStatusEnum } from '@/types'

export const SIDEBAR_DRAWER_WIDTH = 257

export const DISABLED_ELEMENT_OPACITY = 0.38

export const DEFAULT_PHONE_NUMBER_COUNTRY: CountryIso2 = 'au'

export const SYSTEM_DATE_FORMAT = 'DD/MM/YYYY'

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'

export const SYSTEM_MM_YYYY_DATE_FORMAT = 'MM/YYYY'

export const BACKEND_MM_YYYY_DATE_FORMAT = 'MM-YYYY'

export const FILES_COLORS = {
  png: {
    color: '#005FAD'
  },
  jpg: {
    color: '#00A08C'
  },
  txt: {
    color: '#0072FF'
  },
  eps: {
    color: '#FF9908'
  },
  pdf: {
    color: '#B70000'
  },
  xsl: {
    color: '#00C650'
  },
  tiff: {
    color: '#245423'
  },
  ai: {
    color: '#C46927'
  },
  psd: {
    color: '#2D4677'
  },
  dxf: {
    color: '#273C9E'
  },
  svg: {
    color: '#EDB250'
  },
  '3dm': {
    color: '#2B2925'
  },
  max: {
    color: '#19B5BF'
  },
  obj: {
    color: '#1244C5'
  },
  fbx: {
    color: '#1284C5'
  },
  dwg: {
    color: '#FFB800'
  },
  mov: {
    color: '#51B4F8'
  },
  default: {
    color: '#32B22B'
  }
}

export const SCHOOL_STATUS = {
  [SchoolStatusEnum.READY_TO_BE_SEEN]: {
    colorName: 'primary'
  },
  [SchoolStatusEnum.PENDING_DENTAL_FORMS_TO_BE_PROCESSED_AND_ASSIGNED_TO_CDBS_PROBONO]:
    {
      colorName: 'warning'
    },
  [SchoolStatusEnum.CANCELLED]: {
    colorName: 'error'
  },
  [SchoolStatusEnum.CONTINATION_VISIT]: {
    colorName: 'richElectricBlue'
  }
}

// In Kilobytes
// 1048576000 KB = 1GB / 1000MB (1000 * 1024 * 1024)

export const MIN_UPLOAD_FILE_SIZE = 0

export const MAX_UPLOAD_FILE_SIZE = 1048576000

// in bytes
export const MAX_UPLOAD_FILE_SIZE_AVATAR = 1000000

export const ITEMS_PER_PAGE = [10, 20, 40, 50, 80, 100]

export const ASYNCAUTOCOMPLETE_MIN_ITEM_HEIGHT = 45

export const SCHOOLS_TEMPLATE_BYTES_SIZE = 594

export const SCHOOL_ACCEPT_FILES =
  '.jpg,.jpeg,.png,.mov,.mp4,.avi,.doc,.docx,.xls,.xlsx,.csv,.pdf'

export const EFORM_ID = 'a2c7fdcb-8086-4377-9fa8-019b383834d8'

export const PASSWORD_TOOLTIP_TEXT =
  // eslint-disable-next-line max-len
  'Your password must be at least 8 characters long, contain at least one capital letter, one regular letter, one number and one special symbol.'

export const MAX_NAME_LENGTH = 30
