import * as yup from 'yup'

import { EMAIL_REGEXP } from '../constants'

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEXP, 'Email is not valid')
    .required('Email is a required field')
})
