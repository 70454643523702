import { Box, Typography } from '@mui/material'
import React from 'react'

import { theme } from '@/theme'

export const HealthScreening: React.FC = (): JSX.Element => {
  return (
    <Box mb={5}>
      <Typography color={theme.palette.primary.main} variant="h5">
        Free dental health screening completed today
      </Typography>
      <Typography fontSize={18}>
        Please visit your local dental clinic for further treatment as indicated
        below
      </Typography>
      <Box mt="20px" borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
