import { tableRowClasses } from '@mui/material'

import { theme } from '@/theme'

export const applyStickyFirstColumnStyles = (
  isFirstColumnSticky: boolean,
  scrollLeft: number
) => {
  if (!isFirstColumnSticky) return {}
  return {
    [`.${tableRowClasses.root}`]: {
      '& > th:first-of-type, & > td:first-of-type': {
        position: 'sticky',
        left: 0,
        backgroundColor: '#ffffff',
        width: '100%',
        zIndex: 3,
        borderRight:
          scrollLeft === 0 ? '1px solid transparent' : '1px solid #F0F3F7',
        transition: `opacity ${theme.transitions.duration.short}ms ease`
      }
    }
  }
}

export const getBeforeBlurStyles = (
  scrollWidth: number,
  containerWidth: number,
  scrollLeft: number,
  isFirstColumnSticky?: boolean
) => {
  if (isFirstColumnSticky) return {}
  return {
    opacity: scrollWidth <= containerWidth || scrollLeft === 0 ? 0 : 1,
    content: '""',
    position: 'absolute',
    width: '40px',
    height: 'calc(100% - 27px)',
    top: 0,
    background:
      'linear-gradient(90deg, rgba(145,154,180,0.16) 0%, rgba(145,154,180,0) 100%)',
    backdropFilter: 'blur(2px)',
    mask: 'linear-gradient(-90deg, transparent, black 50%)',
    transition: `opacity ${theme.transitions.duration.short}ms ease`,
    pointerEvents: 'none',
    zIndex: 4
  }
}

export const getAfterBlurStyles = (
  scrollWidth: number,
  containerWidth: number,
  scrollLeft: number
) => {
  return {
    opacity:
      scrollWidth <= containerWidth ||
      scrollLeft + containerWidth >= scrollWidth
        ? 0
        : 1,
    content: '""',
    position: 'absolute',
    width: '40px',
    height: 'calc(100% - 27px)',
    top: 0,
    right: 0,
    background:
      'linear-gradient(-90deg, rgba(145,154,180,0.16) 0%, rgba(145,154,180,0) 100%)',
    backdropFilter: 'blur(2px)',
    mask: 'linear-gradient(90deg, transparent, black 50%)',
    transition: `opacity ${theme.transitions.duration.short}ms ease`,
    pointerEvents: 'none',
    zIndex: 4
  }
}
