import {
  Box,
  ClickAwayListener,
  InputAdornment,
  inputBaseClasses,
  InputProps,
  Paper,
  PaperProps,
  TextField,
  TextFieldProps,
  TextFieldVariants,
  tooltipClasses,
  Typography
} from '@mui/material'
import { deepmerge } from '@mui/utils'
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'

import { CrossIcon, DropdownIcon } from '@/assets/icons'
import { mergeRefs } from '@/helpers/functions/mergeRefs'
import { useTruncatedWords } from '@/hooks'
import { useHover } from '@/hooks/useHover'
import { theme } from '@/theme'
import {
  ASYNCAUTOCOMPLETE_MIN_ITEM_HEIGHT,
  ITEMS_PER_PAGE
} from '@/utils/constants'

import { TooltipLight } from '../Tooltips'

type Props<T extends TextFieldVariants = 'outlined'> = TextFieldProps<T> & {
  variant?: T
  children: ReactNode
  open: boolean
  PaperProps?: PaperProps
  InputProps?: InputProps
  inputValue: string
  selected?: string
  placeholder?: string
  startAdornment?: ReactNode
  paperMaxWidth?: string
  onOpen: () => void
  onClose: () => void
  onClear?: () => void
  onInputChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  onInputBlur?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  endAdornmentMarginRight?: string
}

function AsyncAutocomplete<T extends TextFieldVariants>(props: Props<T>) {
  const {
    children,
    variant = 'outlined',
    sx,
    open,
    PaperProps,
    InputProps,
    inputValue,
    selected,
    placeholder = '',
    startAdornment,
    paperMaxWidth,
    onOpen,
    onClose,
    onClear,
    onInputChange,
    onInputBlur,
    endAdornmentMarginRight = '-6px',
    ...rest
  } = props

  const inputRef = useRef<HTMLInputElement | null>(null)

  const blockRef = useRef<HTMLInputElement | null>(null)

  const [isFocused, setIsFocused] = useState<boolean>(false)

  const [truncatedWords, elementRef, recalculateTruncatedWords] =
    useTruncatedWords({
      regExp: /, /
    })

  const isHover = useHover(inputRef)

  useEffect(() => {
    recalculateTruncatedWords()
  }, [open, selected, recalculateTruncatedWords])

  useEffect(() => {
    if (isFocused) {
      onOpen()
    }
  }, [isFocused, onOpen])

  const getMaxPaperHeight = () => {
    const viewportHeight = window.innerHeight * 0.4 // 40dvh
    // max 9 items so scroll is seen, so we can scroll to load more
    const maxHeight =
      (ITEMS_PER_PAGE[0] - 1) * ASYNCAUTOCOMPLETE_MIN_ITEM_HEIGHT
    return `${viewportHeight > maxHeight ? maxHeight : viewportHeight}px`
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose()
        setIsFocused(false)
      }}
    >
      <Box ref={blockRef}>
        <TooltipLight
          title={
            <Typography
              sx={{
                lineHeight: '30px',
                whiteSpace: 'pre-line'
              }}
            >
              {truncatedWords.join(', ')}
            </Typography>
          }
          open={truncatedWords.length ? isHover : false}
          placement="top-start"
          color="white"
          sx={{
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: 'none'
            }
          }}
          arrow
        >
          <TextField
            focused={isFocused}
            onFocus={() => setIsFocused(true)}
            variant={variant}
            color="primary"
            autoComplete="off"
            placeholder={placeholder}
            onChange={onInputChange}
            onBlur={(e) => {
              onInputBlur && onInputBlur(e)
              setIsFocused(false)
            }}
            fullWidth
            sx={deepmerge(
              {
                [`& .${inputBaseClasses.root}`]: {
                  [`& .${inputBaseClasses.input}`]: {
                    padding: '14px 12px',
                    textOverflow: open ? 'unset' : 'ellipsis'
                  }
                }
              },
              sx
            )}
            InputProps={{
              startAdornment: startAdornment && (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    marginLeft: 'unset',
                    marginRight: endAdornmentMarginRight
                  }}
                >
                  {selected && !open ? (
                    <CrossIcon
                      onClick={onClear}
                      sx={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer'
                      }}
                    />
                  ) : (
                    <DropdownIcon
                      onClick={() => (open ? onClose() : onOpen())}
                      sx={{
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px',
                        transform: open ? 'rotate(-0.5turn)' : 'rotate(0)'
                      }}
                    />
                  )}
                </InputAdornment>
              ),
              ...InputProps
            }}
            value={open ? inputValue : (selected ?? inputValue)}
            inputRef={mergeRefs(inputRef, elementRef)}
            {...rest}
          />
        </TooltipLight>
        {open && (
          <Paper
            elevation={0}
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              maxWidth:
                paperMaxWidth || blockRef?.current?.clientWidth || '100%',
              width: '100%',
              outline: 'none',
              zIndex: 5,

              padding: '8px 4px 8px 8px',
              marginTop: '4px',
              borderRadius: '4px !important',
              border: '1px solid  #F0F3F7 !important',
              backgroundColor: '#FFF !important',
              boxShadow: '0px 4px 12px 0px rgba(224, 228, 233, 0.40) !important'
            }}
            {...PaperProps}
          >
            <Box
              onScroll={PaperProps?.onScroll}
              sx={{
                maxHeight: getMaxPaperHeight(),
                overflowY: 'auto',
                paddingRight: '4px',
                '&::-webkit-scrollbar': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-track': {
                  WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  backgroundColor: theme.palette.grey[200],
                  marginRight: '4px',
                  borderRadius: '100px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '100px',
                  cursor: 'pointer'
                }
              }}
              component={'ul'}
            >
              {children}
            </Box>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default AsyncAutocomplete
