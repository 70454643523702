/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EyeOpenIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M19.3211 10.275C19.2937 10.2132 18.632 8.74526 17.1609 7.27417C15.2008 5.31401 12.725 4.27808 9.99999 4.27808C7.27499 4.27808 4.79921 5.31401 2.83905 7.27417C1.36796 8.74526 0.703118 10.2156 0.678899 10.275C0.643362 10.3549 0.625 10.4414 0.625 10.5289C0.625 10.6163 0.643362 10.7028 0.678899 10.7828C0.706243 10.8445 1.36796 12.3117 2.83905 13.7828C4.79921 15.7421 7.27499 16.7781 9.99999 16.7781C12.725 16.7781 15.2008 15.7421 17.1609 13.7828C18.632 12.3117 19.2937 10.8445 19.3211 10.7828C19.3566 10.7028 19.375 10.6163 19.375 10.5289C19.375 10.4414 19.3566 10.3549 19.3211 10.275ZM9.99999 15.5281C7.5953 15.5281 5.49452 14.6539 3.75546 12.9304C3.0419 12.2208 2.43483 11.4116 1.95312 10.5281C2.4347 9.64444 3.04179 8.83525 3.75546 8.12573C5.49452 6.40229 7.5953 5.52808 9.99999 5.52808C12.4047 5.52808 14.5055 6.40229 16.2445 8.12573C16.9595 8.83508 17.5679 9.64426 18.0508 10.5281C17.4875 11.5796 15.0336 15.5281 9.99999 15.5281ZM9.99999 6.77808C9.25831 6.77808 8.53329 6.99801 7.9166 7.41007C7.29992 7.82212 6.81927 8.40779 6.53544 9.09301C6.25162 9.77824 6.17735 10.5322 6.32205 11.2597C6.46674 11.9871 6.82389 12.6553 7.34834 13.1797C7.87279 13.7042 8.54097 14.0613 9.2684 14.206C9.99583 14.3507 10.7498 14.2765 11.4351 13.9926C12.1203 13.7088 12.7059 13.2281 13.118 12.6115C13.5301 11.9948 13.75 11.2698 13.75 10.5281C13.749 9.53383 13.3535 8.58061 12.6505 7.87757C11.9475 7.17453 10.9942 6.77911 9.99999 6.77808ZM9.99999 13.0281C9.50554 13.0281 9.02219 12.8815 8.61107 12.6068C8.19994 12.332 7.87951 11.9416 7.69029 11.4848C7.50107 11.028 7.45157 10.5253 7.54803 10.0404C7.64449 9.5554 7.88259 9.10994 8.23222 8.76031C8.58186 8.41068 9.02731 8.17258 9.51227 8.07611C9.99722 7.97965 10.4999 8.02916 10.9567 8.21838C11.4135 8.4076 11.804 8.72803 12.0787 9.13915C12.3534 9.55027 12.5 10.0336 12.5 10.5281C12.5 11.1911 12.2366 11.827 11.7678 12.2958C11.2989 12.7647 10.663 13.0281 9.99999 13.0281Z"
        fill="#010800"
      />
    </SvgIcon>
  )
}
