import {
  Box,
  Paper,
  SxProps,
  Table as MuiTable,
  TableBody,
  TableCell,
  tableCellClasses,
  tableClasses,
  TableContainer,
  tableContainerClasses,
  TableRow,
  Theme,
  Typography
} from '@mui/material'
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react'

import { useDebouncedCallback } from '@/hooks'
import useEventListener from '@/hooks/useEventListener'
import { theme } from '@/theme'
import { TableBodyItem, TableHeadCell, TableHeadCells } from '@/types'

import TableHead from './Head'
import {
  applyStickyFirstColumnStyles,
  getAfterBlurStyles,
  getBeforeBlurStyles
} from './helpers'
import HoverableTableCell from './HoverableTableCell'

interface IGroupedData<G> {
  grouped_by: string
  items: G[]
}

interface Props<T, C, G> {
  data: T[]
  dataGrouped?: IGroupedData<G>[]
  tableHeadCells: TableHeadCells
  tableBodyItems: C[]
  keyId?: string
  sortBy?: string
  view?: 'default' | 'grouped'
  hover?: boolean
  renderGroup?: (group: IGroupedData<G>) => ReactNode
  onCellClick?: (row: T | G, cell: C) => void
  onSort?: (name: TableHeadCell<T>['name']) => void
  tableContainerStyles?: SxProps<Theme>
  emptyDataText?: string
  isEmptyData?: boolean
  shouldStickFirstColumn?: boolean
}

function Table<T, C extends TableBodyItem<T>, G extends T>(
  props: Props<T, C, G>
): JSX.Element {
  const {
    tableHeadCells,
    tableBodyItems,
    data,
    dataGrouped,
    keyId = 'id',
    sortBy,
    view = 'default',
    hover,
    renderGroup,
    onCellClick,
    onSort,
    tableContainerStyles = {},
    emptyDataText = '',
    isEmptyData = false,
    shouldStickFirstColumn = false
  } = props

  const tableContainerRef = useRef<null | HTMLDivElement>(null)

  const [isHover, setIsHover] = useState<null | number>(null)

  const handleSort = (name: TableHeadCell<T>['name']) => {
    let sort = ''

    if (sortBy) {
      if (name.replace('-', '') !== sortBy.replace('-', '')) {
        sort = name
      } else {
        sort = sortBy[0] === '-' ? '' : `-${name}`
      }
    } else {
      sort = name
    }

    onSort?.(sort)
  }

  const [scrollWidth, setScrollWidth] = useState<number>(0)
  const [containerWidth, setContainerWidth] = useState<number>(0)
  const [scrollLeft, setScrollLeft] = useState<number>(0)

  const updateDimensions = () => {
    const element = tableContainerRef.current

    if (element) {
      setScrollWidth(element.scrollWidth)
      setContainerWidth(element.offsetWidth)
    }
  }

  useEffect(() => {
    setTimeout(() => updateDimensions(), 1)
  }, [])

  useEventListener({
    type: 'resize',
    element: window,
    listener: updateDimensions
  })

  const applyScrollLeftDebounce = useDebouncedCallback(
    (value: number) => setScrollLeft(value),
    150
  )

  if (isEmptyData)
    return (
      <Paper
        sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <Typography
          color={theme.palette.primary.dark}
          variant="h4"
          textAlign="center"
        >
          {emptyDataText}
        </Typography>
      </Paper>
    )

  return (
    <Box
      sx={{
        position: 'relative',
        '&::before': getBeforeBlurStyles(
          scrollWidth,
          containerWidth,
          scrollLeft,
          shouldStickFirstColumn
        ),
        '&::after': getAfterBlurStyles(scrollWidth, containerWidth, scrollLeft)
      }}
    >
      <TableContainer
        sx={{
          position: 'relative',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          [`&.${tableContainerClasses.root}`]: {
            ...applyStickyFirstColumnStyles(shouldStickFirstColumn, scrollLeft),
            [`.${tableClasses.root}`]: {
              backgroundColor: theme.palette.common.white,
              borderRadius: '8px',
              [`.${tableCellClasses.root}`]: {
                padding: '12px 24px'
              }
            },
            paddingBottom: '22px',
            backgroundColor: theme.palette.grey100.main,
            '&::-webkit-scrollbar': {
              height: '4px'
            },
            '&::-webkit-scrollbar-track': {
              WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              backgroundColor: theme.palette.common.white,
              padding: '2px'
            },
            ...tableContainerStyles
          }
        }}
        ref={tableContainerRef}
        onScroll={(e) =>
          applyScrollLeftDebounce(
            Math.abs((e.target as HTMLDivElement).scrollLeft)
          )
        }
      >
        <MuiTable
          stickyHeader
          aria-label="sticky table"
          sx={{
            minWidth: 450
          }}
          size="medium"
        >
          <TableHead
            handleSort={handleSort}
            sortBy={sortBy}
            rows={tableBodyItems}
            cells={tableHeadCells}
          />
          <TableBody>
            {view === 'default'
              ? data.map((row, index) => (
                  <TableRow
                    onMouseOver={() => setIsHover(index)}
                    onMouseLeave={() => setIsHover(null)}
                    // @ts-ignore
                    key={`${row[keyId]}-${index}`}
                    hover={hover}
                  >
                    {tableBodyItems.map((cell) =>
                      (
                        typeof cell.visible === 'function'
                          ? cell.visible(row)
                          : true
                      ) ? (
                        <HoverableTableCell
                          key={cell.name}
                          cell={cell}
                          row={row}
                          index={index}
                          isRowHovered={index === isHover}
                          onCellClick={onCellClick}
                        />
                      ) : null
                    )}
                  </TableRow>
                ))
              : dataGrouped?.map((row, index) => (
                  <Fragment key={row.grouped_by || index}>
                    <TableRow hover={hover}>
                      <TableCell
                        sx={{
                          padding: '8px 13px'
                        }}
                        colSpan={tableBodyItems.length}
                      >
                        {typeof renderGroup === 'function'
                          ? renderGroup(row)
                          : row.grouped_by}
                      </TableCell>
                    </TableRow>
                    {row.items.map((item) => (
                      <TableRow
                        // @ts-ignore
                        key={item[keyId] || index}
                        hover={hover}
                        // @ts-ignore
                        onMouseOver={() => setIsHover(item[keyId])}
                        onMouseLeave={() => setIsHover(null)}
                      >
                        {tableBodyItems.map((cell) =>
                          (
                            typeof cell.visible === 'function'
                              ? cell.visible(item)
                              : true
                          ) ? (
                            <HoverableTableCell
                              key={cell.name}
                              cell={cell}
                              row={item}
                              index={index}
                              // @ts-ignore
                              isRowHovered={isHover === item[keyId]}
                              onCellClick={onCellClick}
                            />
                          ) : null
                        )}
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Box>
  )
}

export default Table
