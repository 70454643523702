/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PencilIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.7586 5.73262L14.268 2.24122C14.1519 2.12511 14.0141 2.03301 13.8624 1.97018C13.7107 1.90734 13.5482 1.875 13.384 1.875C13.2198 1.875 13.0572 1.90734 12.9056 1.97018C12.7539 2.03301 12.6161 2.12511 12.5 2.24122L2.86641 11.8756C2.74983 11.9912 2.65741 12.1289 2.59451 12.2806C2.5316 12.4323 2.49948 12.595 2.50001 12.7592V16.2506C2.50001 16.5821 2.6317 16.9001 2.86612 17.1345C3.10054 17.3689 3.41849 17.5006 3.75001 17.5006H7.24141C7.40563 17.5011 7.5683 17.469 7.71999 17.4061C7.87168 17.3432 8.00935 17.2508 8.12501 17.1342L17.7586 7.50059C17.8747 7.38452 17.9668 7.2467 18.0296 7.09503C18.0925 6.94335 18.1248 6.78078 18.1248 6.61661C18.1248 6.45243 18.0925 6.28986 18.0296 6.13819C17.9668 5.98651 17.8747 5.8487 17.7586 5.73262ZM4.0086 12.5006L10.625 5.88419L11.9289 7.18809L5.31251 13.8037L4.0086 12.5006ZM3.75001 14.0092L5.99141 16.2506H3.75001V14.0092ZM7.50001 15.992L6.1961 14.6881L12.8125 8.07169L14.1164 9.37559L7.50001 15.992ZM15 8.492L11.5086 5.00059L13.3836 3.12559L16.875 6.61622L15 8.492Z"
        fill="#1C211B"
      />
    </SvgIcon>
  )
}
