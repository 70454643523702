import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { CrossIcon, SignatureIcon } from '@/assets/icons'
import { CustomModal } from '@/components/UI'
import Image from '@/components/UI/Image'
import { theme } from '@/theme'

interface Props {
  sign: (signatureImageString: string) => void
  signedImage: string
}

export const AddSignature: React.FC<Props> = ({
  sign,
  signedImage
}): JSX.Element => {
  const sigCanvas = useRef<SignatureCanvas>(null)

  const [openSignatureModal, setOpenSignatureModal] = useState<boolean>(false)
  const [isEmptyCanvas, setIsEmptyCanvas] = useState<boolean>(true)

  const clear = () => {
    sigCanvas.current?.clear()
    setIsEmptyCanvas(true)
  }

  const save = async () => {
    if (sigCanvas.current) {
      const signatureData = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL('image/png')

      sign(signatureData)
      setIsEmptyCanvas(true)
      setOpenSignatureModal(false)
    }
  }

  return (
    <Box mt={5}>
      <Box display="flex" gap={2} alignItems="center">
        {signedImage ? (
          <Box sx={{ width: '150px', height: '36px' }}>
            <Image style={{ objectFit: 'contain' }} src={signedImage} alt="" />
          </Box>
        ) : (
          <Box display="flex">
            <Button
              type="button"
              onClick={() =>
                signedImage ? sign(signedImage) : setOpenSignatureModal(true)
              }
            >
              <Typography variant="h6" color={theme.palette.primary.main}>
                Add a clinician's signature
              </Typography>
              <SignatureIcon
                sx={{
                  width: '24px',
                  height: '24px'
                }}
              />
            </Button>
            <Typography
              component="span"
              variant="inherit"
              sx={{
                color: theme.palette.error.main
              }}
            >
              *
            </Typography>
          </Box>
        )}
      </Box>

      <CustomModal
        open={openSignatureModal}
        onClose={() => setOpenSignatureModal(false)}
      >
        <Box display="flex" flexDirection="column" gap={3} p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">Create signature</Typography>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => setOpenSignatureModal(false)}
            >
              <CrossIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography>You need to create a signature</Typography>
          </Box>
          <Box>
            <Box
              borderRadius="4px"
              // eslint-disable-next-line max-len
              border={`1px solid ${isEmptyCanvas ? theme.palette.primary.main : theme.palette.grey[300]}`}
            >
              <SignatureCanvas
                ref={sigCanvas}
                onBegin={() => setIsEmptyCanvas(false)}
                canvasProps={{
                  width: 300,
                  height: 100,
                  className: 'signatureCanvas'
                }}
                penColor="black"
                maxWidth={1}
                minWidth={1}
              />
            </Box>
            <Box display="flex" mt={1} justifyContent="flex-end">
              <Button type="button" disabled={isEmptyCanvas} onClick={clear}>
                Clear
              </Button>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" gap={4}>
            <Button
              fullWidth
              type="button"
              onClick={() => setOpenSignatureModal(false)}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              type="button"
              onClick={save}
              disabled={isEmptyCanvas}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  )
}
