import { AppBar, Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { Logo } from '@/assets/icons'
import { useAppSelector } from '@/hooks'
import { PAGES } from '@/routes/pages-routes'
import { theme } from '@/theme'

interface Props {
  children: ReactNode
}

const AllPagesWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const navigate = useNavigate()
  const isLogin = useAppSelector((state) => state.userStore.isLogin)

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: theme.palette.grey[100],
        height: '100dvh'
      }}
    >
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: theme.palette.common.white,
          borderBottom: `1px solid ${theme.palette.grey200}`,
          px: '45px',
          display: 'flex',
          justifyContent: 'center',
          py: 2,
          height: '67px',
          width: '100%'
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" flexGrow={1} gap="44px">
            <Box
              py="20px"
              minHeight="80px"
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                isLogin ? () => navigate(PAGES.SCHOOLS) : () => {}
              }
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                gap="10px"
                height="35px"
              >
                <Logo
                  sx={{
                    width: '44px',
                    height: '40px'
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    width: '88px'
                  }}
                >
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                      width: '88px'
                    }}
                    width="100%"
                    maxWidth="88px"
                    variant="h6"
                  >
                    Australian Dental Health
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </AppBar>
      <Box
        component="main"
        sx={{
          width: '100%',
          mt: '60px',
          paddingTop: '32px',
          overflowX: 'hidden',
          overflowY: 'auto',
          height: 'calc(100dvh - 80px)'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default AllPagesWrapper
