import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
  total: number
}

export const TotalAmount: React.FC<Props> = (props): JSX.Element => {
  const { total } = props
  return (
    <Box mb={2}>
      <Typography variant="h5">
        Total amount: {total.toLocaleString('en-US').replace(/,/g, ' ')}
      </Typography>
    </Box>
  )
}
