import { Box, Grid, Paper, Skeleton } from '@mui/material'
import React from 'react'

export const PatientDetailsSkeleton: React.FC = (): JSX.Element => {
  return (
    <Box mt="24px">
      <Paper sx={{ p: '52px 32px', maxWidth: 'xl', mx: 'auto' }}>
        <Box
          height="68px"
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          gap={4}
        >
          <Skeleton
            sx={{
              maxWidth: '350px',
              width: '100%',
              minHeight: '40px'
            }}
          />
          <Skeleton
            sx={{ minHeight: '40px', height: 'inherit', minWidth: '40px' }}
          />
        </Box>
        {[1, 2, 3, 4, 5].map((e) => (
          <Box mb={5} key={e}>
            <Box mb="20px">
              <Skeleton
                sx={{ maxWidth: '350px', width: '100%', height: '27px' }}
              />
            </Box>
            <Grid
              mb="20px"
              container
              xs={12}
              item
              spacing={2}
              display="flex"
              flexWrap="wrap"
            >
              {[1, 2, 3, 4].map((el) => (
                <Grid
                  key={el}
                  item
                  md={3}
                  xs={12}
                  gap={1}
                  display="flex"
                  minWidth="200px"
                  flexDirection="column"
                >
                  <Skeleton />
                  <Skeleton />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Paper>
    </Box>
  )
}
