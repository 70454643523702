import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { theme } from '@/theme'

import { CdbsReportData } from '../cdbs/components/CdbsReport'

interface Props {
  control: Control<CdbsReportData>
}

const oralHealthOptions = [
  {
    title: 'Teeth type present:',
    required: true,
    key: 'teeth_type_present',
    options: [
      { name: 'Milk teeth only', value: 'milk' },
      { name: 'Mixed', value: 'mixed' },
      { name: 'Adult teeth only', value: 'adult' }
    ]
  },
  {
    title: 'Plaque build up:',
    required: true,
    key: 'plaque_buildup',
    options: [
      { name: 'None', value: 'none' },
      { name: 'Mild', value: 'mild' },
      { name: 'Moderate/Heavy', value: 'moderate' }
    ]
  },
  {
    title: 'Calculus build up:',
    required: true,
    key: 'calculus_buildup',
    options: [
      { name: 'None', value: 'none' },
      { name: 'Mild', value: 'mild' },
      { name: 'Moderate/Heavy', value: 'moderate' }
    ]
  },
  {
    title: 'Holes/Decay present:',
    required: true,
    key: 'holes_decay_present',
    options: [
      { name: 'No holes', value: 'no_holes' },
      { name: 'Holes 1-2 teeth', value: 'few_holes' },
      { name: 'Holes 3 teeth or more', value: 'many_holes' }
    ]
  },
  {
    title: 'Ability to brush own teeth:',
    required: true,
    key: 'ability_to_brush',
    options: [
      { name: 'Yes', value: 'yes' },
      { name: 'Needs supervision', value: 'supervision' },
      { name: 'Needs help', value: 'help' }
    ]
  },
  {
    title: 'Orthodontic consult advised:',
    required: true,
    key: 'ortho_consult_advised',
    options: [
      { name: 'Yes', value: 'yes' },
      { name: 'No', value: 'no' }
    ]
  }
]

export const CdbsOralHealth: React.FC<Props> = (props): JSX.Element => {
  const { control } = props
  return (
    <Grid mb={5}>
      <Typography color={theme.palette.primary.main} variant="h5">
        Your child's oral health
      </Typography>
      <Box>
        {oralHealthOptions.map((el) => (
          <Controller
            key={el.key}
            control={control}
            name={el.key as keyof CdbsReportData}
            render={({ field }) => (
              <Grid
                container
                item
                width="100%"
                xs={12}
                lg={12}
                columnSpacing={4}
                display="flex"
                alignItems="center"
                mt="20px"
              >
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">
                    {el.title}{' '}
                    {el.required && (
                      <Typography
                        component="span"
                        variant="inherit"
                        sx={{
                          color: theme.palette.error.main
                        }}
                      >
                        *
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <RadioGroup
                    row
                    sx={{
                      gap: { xs: 2, lg: 6 },
                      mt: 1,
                      display: 'flex',
                      width: '100%'
                    }}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {el.options.map((r, i) => (
                      <Grid key={i} item xs={12} lg={3}>
                        <FormControlLabel
                          value={r.value}
                          control={<Radio />}
                          label={r.name}
                          sx={{
                            gap: 1,
                            color: '#000',
                            fontWeight: 400,
                            fontSize: '18px'
                          }}
                        />
                      </Grid>
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
          />
        ))}
      </Box>
      <Box mt="20px" borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Grid>
  )
}
