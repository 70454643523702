import { SvgIconProps } from '@mui/material'
import { ComponentType, LazyExoticComponent } from 'react'

import { PAGES } from '@/routes/pages-routes'
import { EPermission } from '@/utils/permissions/permissions'

export enum RegionEnum {
  VICTORIA = 'Victoria',
  NEW_SOUTH_WALES = 'New South Wales'
}

export interface Pages {
  path: PAGES | string
  component: LazyExoticComponent<React.FC> | ComponentType
}
export interface PrivatePages extends Pages {
  permissionName: null | EPermission
}

type TServerStatus = 'success' | 'failed'

export type TServerResponse<T> = {
  data: T
  errors?: Record<string, string>[]
  status: TServerStatus
}

export type TServerResponsePagination<T> = {
  count: number
  next: string | null
  previous: string | null
  results: T
}

export interface IQueryParams {
  ordering?: string
  page?: number
  page_size?: number
  search?: string
  sessionId?: number
}

export enum FileSizes {
  Bytes = 'Bytes',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
  PB = 'PB'
}

interface IAcceptedFileMetadata {
  name: string
  extension: string
  size: string
  description: string
  created_at: string
  confidential: boolean
}

export interface IAcceptedFile {
  id: string
  file: File | string
  metadata?: Partial<IAcceptedFileMetadata>
}

export type Prettify<T> = {
  [K in keyof T]: T[K]
} & unknown

export type THandleChangeSearchParams<T extends IQueryParams> = (
  key: keyof T,
  value: T[keyof T]
) => void

export type CustomFile = {
  id?: string
  file: File
  name: string
  extension: string
  size: number
  created_at: string
  confidential: boolean
}

export type TabOption<T> = {
  icon: React.FC<SvgIconProps>
  title: string
  id: T
}

export type TActionItem = {
  id: string
}
export type TActionItemWithName = Prettify<TActionItem & { name: string }>

// export type TFiles = {
//   files?: any[]
// }
