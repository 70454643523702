/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DownloadIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.5 11.875V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V11.875C2.5 11.7092 2.56585 11.5503 2.68306 11.4331C2.80027 11.3158 2.95924 11.25 3.125 11.25C3.29076 11.25 3.44973 11.3158 3.56694 11.4331C3.68415 11.5503 3.75 11.7092 3.75 11.875V16.25H16.25V11.875C16.25 11.7092 16.3158 11.5503 16.4331 11.4331C16.5503 11.3158 16.7092 11.25 16.875 11.25C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875Z"
        fill={props.fill || '#1C211B'}
      />
      <path
        d="M9.37486 10.3659L7.31705 8.30732C7.19977 8.19004 7.04071 8.12416 6.87486 8.12416C6.70901 8.12416 6.54995 8.19004 6.43267 8.30732C6.3154 8.4246 6.24951 8.58366 6.24951 8.74951C6.24951 8.91536 6.3154 9.07442 6.43267 9.1917L9.55767 12.3167C9.61572 12.3748 9.68465 12.4209 9.76052 12.4524C9.8364 12.4838 9.91772 12.5 9.99986 12.5C10.082 12.5 10.1633 12.4838 10.2392 12.4524C10.3151 12.4209 10.384 12.3748 10.442 12.3167L13.567 9.1917C13.6843 9.07442 13.7502 8.91536 13.7502 8.74951C13.7502 8.58366 13.6843 8.4246 13.567 8.30732C13.4498 8.19004 13.2907 8.12416 13.1249 8.12416C12.959 8.12416 12.7999 8.19004 12.6827 8.30732L10.6249 10.3659V3.12451C10.6249 2.95875 10.559 2.79978 10.4418 2.68257C10.3246 2.56536 10.1656 2.49951 9.99986 2.49951C9.8341 2.49951 9.67513 2.56536 9.55792 2.68257C9.44071 2.79978 9.37486 2.95875 9.37486 3.12451V10.3659Z"
        fill={props.fill || '#010800'}
      />
    </SvgIcon>
  )
}
