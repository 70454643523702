import {
  TAddSchoolParams,
  TAddSchoolResponse,
  TDeleteSchoolParams,
  TDeleteSchoolResponse,
  TGetFilterSchoolsResponse,
  TGetSchoolClassesResponse,
  TGetSchoolFileBlobParams,
  TGetSchoolParams,
  TGetSchoolResponse,
  TGetSchoolsParams,
  TGetSchoolsResponse,
  TPatchUpdateSchoolParams,
  TUpdateSchoolResponse
} from '@/types'
import {
  TPatchArchiveSchoolParams,
  TPatchArchiveSchoolResponse
} from '@/types/SchoolsContactPerson'
import { API_METHODS, SCHOOLS_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const schoolsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchool: builder.query<TGetSchoolResponse, TGetSchoolParams>({
      query: ({ id, status }) => ({
        url: `${SCHOOLS_API.SCHOOLS}${id}/`,
        method: API_METHODS.GET,
        params: status ? { status } : undefined
      }),
      providesTags: ['School']
    }),
    getSchoolClasses: builder.query<
      TGetSchoolClassesResponse,
      TGetSchoolParams
    >({
      query: ({ id }) => ({
        url: `${SCHOOLS_API.SCHOOLS_QUESTIONS}${id}/classes`,
        method: API_METHODS.GET
      })
    }),
    getSchools: builder.query<TGetSchoolsResponse, TGetSchoolsParams>({
      query: (params) => ({
        url: SCHOOLS_API.SCHOOLS,
        method: API_METHODS.GET,
        params
      }),
      providesTags: ['Schools']
    }),
    addSchool: builder.mutation<TAddSchoolResponse, TAddSchoolParams>({
      query: (body) => ({
        url: SCHOOLS_API.SCHOOLS,
        method: API_METHODS.POST,
        body
      }),
      invalidatesTags: ['Schools']
    }),
    patchUpdateSchool: builder.mutation<
      TUpdateSchoolResponse,
      TPatchUpdateSchoolParams
    >({
      query: ({ id, isSchoolCancelled, status, ...body }) => {
        return {
          url: `${SCHOOLS_API.SCHOOLS}${id}/${isSchoolCancelled ? '?status=Cancelled' : ''}`,
          method: API_METHODS.PATCH,
          body: { status, ...body }
        }
      },
      invalidatesTags: ['School', 'Schools']
    }),
    patchArchiveSchool: builder.mutation<
      TPatchArchiveSchoolResponse,
      TPatchArchiveSchoolParams
    >({
      query: ({ id, ...body }) => ({
        url: `${SCHOOLS_API.SCHOOLS}${id}/archive/`,
        method: API_METHODS.PATCH,
        body
      }),
      invalidatesTags: ['School', 'Schools']
    }),
    deleteSchool: builder.mutation<TDeleteSchoolResponse, TDeleteSchoolParams>({
      query: ({ id, password }) => ({
        url: `${SCHOOLS_API.SCHOOLS}${id}/${password ? '?password=' + password : ''}`,
        method: API_METHODS.DELETE
      }),
      invalidatesTags: ['School', 'Schools']
    }),
    getFilterSchools: builder.query<
      TGetFilterSchoolsResponse,
      TGetSchoolsParams
    >({
      query: (params) => ({
        url: SCHOOLS_API.SCHOOLS_FILTER,
        method: API_METHODS.GET,
        params
      }),
      providesTags: ['Schools_Filter']
    }),
    addSchoolFile: builder.mutation({
      query: ({ file }) => ({
        url: SCHOOLS_API.SCHOOLS_FILES,
        method: API_METHODS.POST,
        headers: {
          'Content-type': undefined
        },
        body: file
      })
    }),
    deleteSchoolFile: builder.mutation({
      query: ({ id }) => ({
        url: `${SCHOOLS_API.SCHOOLS_FILES}${id}/`,
        method: API_METHODS.DELETE
      })
    }),
    patchSchoolFile: builder.mutation({
      query: ({ body, id }) => ({
        url: `${SCHOOLS_API.SCHOOLS_FILES}${id}/`,
        method: API_METHODS.PATCH,
        headers: {
          'Content-type': undefined
        },
        body
      })
    }),
    getSchoolFileBlob: builder.query<Blob, TGetSchoolFileBlobParams>({
      query: ({ id }) => ({
        url: `${SCHOOLS_API.SCHOOLS_FILES}${id}/download/`,
        method: API_METHODS.GET,
        responseHandler: (response) => response.blob()
      })
    })
  })
})

export const {
  useGetSchoolQuery,
  useLazyGetSchoolQuery,
  useGetSchoolsQuery,
  useAddSchoolMutation,
  usePatchUpdateSchoolMutation,
  useDeleteSchoolMutation,
  useGetFilterSchoolsQuery,
  useGetSchoolClassesQuery,
  usePatchArchiveSchoolMutation,
  useAddSchoolFileMutation,
  useDeleteSchoolFileMutation,
  useLazyGetSchoolFileBlobQuery,
  usePatchSchoolFileMutation
} = schoolsApi
