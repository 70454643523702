import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { RootState } from '../store'

interface InitialState {
  schoolNameAndId: { name: string; id: string }
  eFormSignedDocs: {
    1: boolean
    2: boolean
    3: boolean
    4: boolean
  }
  schoolsSearchValue: string
  eFormStep: 1 | 2 | 3 | 4
  classIndex: number
  schoolIndex: number
}

const initialState: InitialState = {
  schoolNameAndId: { name: '', id: '' },
  eFormSignedDocs: {
    1: false,
    2: false,
    3: false,
    4: false
  },
  schoolsSearchValue: '',
  eFormStep: 1,
  classIndex: -1,
  schoolIndex: -1
}

const eFormSlice = createSlice({
  name: 'eForm',
  initialState,
  reducers: {
    setSchoolNameAndId: (
      state,
      action: PayloadAction<{ name: string; id: string }>
    ) => {
      state.schoolNameAndId = action.payload
    },
    setEFormSignedDocs: (state, action: PayloadAction<1 | 2 | 3 | 4>) => {
      state.eFormSignedDocs[action.payload] = true
    },
    setSchoolsSearchValue: (state, action: PayloadAction<string>) => {
      state.schoolsSearchValue = action.payload
    },
    setEFormStep: (state, action: PayloadAction<1 | 2 | 3 | 4>) => {
      state.eFormStep = action.payload
    },
    setClassIndex: (state, action: PayloadAction<number>) => {
      state.classIndex = action.payload
    },
    setSchoolIndex: (state, action: PayloadAction<number>) => {
      state.schoolIndex = action.payload
    },
    resetEformSliceToInitialState: () => initialState
  }
})

const getEFormSignedDocs = (state: RootState) =>
  state.eFormStore.eFormSignedDocs

const getSchoolsSearchValue = (state: RootState) =>
  state.eFormStore.schoolsSearchValue

const getEformStep = (state: RootState) => state.eFormStore.eFormStep

const getSchoolNameAndId = (state: RootState) =>
  state.eFormStore.schoolNameAndId
export const memoizedGetSchoolNameAndId = createSelector(
  getSchoolNameAndId,
  (schoolNameAndId) => schoolNameAndId
)

export const memoizedGetSchoolsSearchValue = createSelector(
  getSchoolsSearchValue,
  (schoolsSearchValue) => schoolsSearchValue
)

export const memoizedGetEFromStep = createSelector(
  getEformStep,
  (eFormStep) => eFormStep
)

export const memoizedGetEFormSignedDocs = createSelector(
  getEFormSignedDocs,
  (eFormSignedDocs) => eFormSignedDocs
)

export const memoizedGetClassIndex = createSelector(
  (state: RootState) => state.eFormStore.classIndex,
  (classIndex) => classIndex
)

export const memoizedSchoolIndex = createSelector(
  (state: RootState) => state.eFormStore.schoolIndex,
  (schoolIndex) => schoolIndex
)

export const {
  setSchoolNameAndId,
  setEFormSignedDocs,
  setSchoolsSearchValue,
  setEFormStep,
  setClassIndex,
  setSchoolIndex,
  resetEformSliceToInitialState
} = eFormSlice.actions

export default eFormSlice.reducer
