import './index.css'

import { ThemeProvider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App.tsx'
import { OfflineSync } from './helpers/components/OfflineSync.tsx'
import { store } from './redux/store/index.ts'
import { theme } from './theme.ts'
import { initializeSentry } from './utils/lib/sentry.ts'

export const persistor = persistStore(store)

const environment = import.meta.env.MODE
if (window.location.hostname !== 'localhost') {
  initializeSentry(environment)
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        //@ts-ignore
        <div>An error occurred: {error?.message}</div>
      )}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <OfflineSync />
                <App />
                <Toaster position="top-right" />
              </PersistGate>
            </Provider>
          </ThemeProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
