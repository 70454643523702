import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'

import { theme } from '@/theme'
import { IGetPatient } from '@/types'

import DentalStatus from '../../patients/components/DentalStatus'
import PatientType from '../../patients/components/PatientType'

interface Props {
  type: IGetPatient['type']
  dental_status: IGetPatient['dental_status']
  id: string
}

export const StatusInformation: React.FC<Props> = (props): JSX.Element => {
  const { type, dental_status, id } = props

  const [isPatientTypeHover, setIsPatientTypeHover] = useState<boolean>(false)
  const [isDentalStatusHover, setIsDentalStatusHover] = useState<boolean>(false)

  return (
    <Box mb={5}>
      <Box mb="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          Status information
        </Typography>
      </Box>
      <Grid
        mb="20px"
        container
        xs={12}
        item
        spacing={2}
        display="flex"
        flexWrap="wrap"
      >
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          gap={1}
          display="flex"
          minWidth="200px"
          flexDirection="column"
        >
          <Typography
            variant="body1"
            fontSize={'16px'}
            color={theme.palette.secondary.main}
          >
            Patient type
          </Typography>
          <Box
            minHeight="27px"
            onMouseOver={() => setIsPatientTypeHover(true)}
            onMouseLeave={() => setIsPatientTypeHover(false)}
          >
            <PatientType
              isOnDetailPage
              id={id}
              type={type}
              isHover={isPatientTypeHover}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={5}
          xs={12}
          gap={1}
          display="flex"
          minWidth="200px"
          flexDirection="column"
        >
          <Typography
            variant="body1"
            fontSize={'16px'}
            color={theme.palette.secondary.main}
          >
            Dental status
          </Typography>
          <Box
            minHeight="27px"
            onMouseOver={() => setIsDentalStatusHover(true)}
            onMouseLeave={() => setIsDentalStatusHover(false)}
          >
            <DentalStatus
              isOnDetailPage
              id={id}
              type={dental_status}
              isHover={isDentalStatusHover}
            />
          </Box>
        </Grid>
      </Grid>
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
