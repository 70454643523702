/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DotsThreeIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        d="M15.3125 14C15.3125 14.2596 15.2355 14.5133 15.0913 14.7292C14.9471 14.945 14.7421 15.1133 14.5023 15.2126C14.2624 15.3119 13.9985 15.3379 13.7439 15.2873C13.4893 15.2366 13.2555 15.1116 13.0719 14.9281C12.8884 14.7445 12.7634 14.5107 12.7127 14.2561C12.6621 14.0015 12.6881 13.7376 12.7874 13.4977C12.8867 13.2579 13.055 13.0529 13.2708 12.9087C13.4867 12.7645 13.7404 12.6875 14 12.6875C14.3481 12.6875 14.6819 12.8258 14.9281 13.0719C15.1742 13.3181 15.3125 13.6519 15.3125 14ZM21.4375 12.6875C21.1779 12.6875 20.9242 12.7645 20.7083 12.9087C20.4925 13.0529 20.3242 13.2579 20.2249 13.4977C20.1256 13.7376 20.0996 14.0015 20.1502 14.2561C20.2009 14.5107 20.3259 14.7445 20.5094 14.9281C20.693 15.1116 20.9268 15.2366 21.1814 15.2873C21.436 15.3379 21.6999 15.3119 21.9398 15.2126C22.1796 15.1133 22.3846 14.945 22.5288 14.7292C22.673 14.5133 22.75 14.2596 22.75 14C22.75 13.6519 22.6117 13.3181 22.3656 13.0719C22.1194 12.8258 21.7856 12.6875 21.4375 12.6875ZM6.5625 12.6875C6.30291 12.6875 6.04915 12.7645 5.83331 12.9087C5.61748 13.0529 5.44925 13.2579 5.34991 13.4977C5.25057 13.7376 5.22458 14.0015 5.27522 14.2561C5.32586 14.5107 5.45087 14.7445 5.63442 14.9281C5.81798 15.1116 6.05184 15.2366 6.30644 15.2873C6.56104 15.3379 6.82494 15.3119 7.06477 15.2126C7.3046 15.1133 7.50959 14.945 7.6538 14.7292C7.79802 14.5133 7.875 14.2596 7.875 14C7.875 13.6519 7.73672 13.3181 7.49058 13.0719C7.24444 12.8258 6.9106 12.6875 6.5625 12.6875Z"
        fill="#1C211B"
      />
    </SvgIcon>
  )
}
