import {
  Box,
  IconButton,
  tableBodyClasses,
  tableCellClasses,
  tableClasses,
  tableContainerClasses,
  Typography
} from '@mui/material'
import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

import { PlusIcon, TrashIcon } from '@/assets/icons'
import { Table } from '@/components/UI'
import { useIsTouchDevice } from '@/hooks/useIsTouchDevice'
import { theme } from '@/theme'
import { TableBodyItems, TableHeadCells } from '@/types'
import { TTreatmentPlanItem } from '@/types/TreatmentCodes'

import { CdbsReportData } from '../cdbs/components/CdbsReport'
import { Quantity } from './Quantity'
import { ThoothNumber } from './ThoothNumber'
import { TreatmentTableDescription } from './TreatmentTableDescription'
import { TreatmentTableItemNumber } from './TreatmentTableItemNumber'

interface TTableHeadCells {
  name: string
  label: string
  minWidth: string
  width: string
}

interface Props {
  treatment_plan: TTreatmentPlanItem[]
  setValue: UseFormSetValue<CdbsReportData>
}

const tableHeadCells: TableHeadCells<TTableHeadCells> = [
  {
    name: 'treatment_code',
    label: 'Item Number',
    minWidth: '200px',
    width: '100%'
  },
  {
    name: 'price',
    label: 'Medicare Coverage Per item',
    minWidth: '200px',
    width: '100%'
  },
  {
    name: 'description',
    label: 'Description of Treatment/Service',
    minWidth: '200px',
    width: '100%'
  },

  {
    name: 'quantity',
    label: 'Quantity',
    minWidth: '200px',
    width: '100%'
  },
  {
    name: 'tooth_number',
    label: 'Tooth Number',
    minWidth: '200px',
    width: '100%'
  },
  {
    name: 'action',
    label: 'Action'
  }
]

export const TreatmentTable: React.FC<Props> = (props): JSX.Element => {
  const { treatment_plan, setValue } = props

  const isTouchDevice = useIsTouchDevice()

  const handleAddTreatmentCode = () => {
    setValue('treatment_plan', [
      ...treatment_plan,
      {
        id: uuidv4(),
        treatment_code: '',
        price: 0,
        description: '',
        quantity: 1,
        tooth_number: 0
      }
    ])
  }

  const handleTreatmentPlanChange = (
    id: string,
    key: string,
    value: string | number | undefined | null
  ) => {
    const updatedArray = treatment_plan.map((el) =>
      el.id === id ? { ...el, [key]: value } : el
    )
    setValue('treatment_plan', updatedArray)
  }

  const handleTreatmentCodeChange = (
    id: string,
    value: { treatment_code: string; price: number; description: string }
  ) => {
    const updatedArray = treatment_plan.map((el) =>
      el.id === id ? { ...el, ...value } : el
    )
    setValue('treatment_plan', updatedArray)
  }

  const handleTreatmentPlanDelete = (id: string) => {
    const updatedArray = treatment_plan.filter((el) => el.id !== id)
    setValue('treatment_plan', updatedArray)
  }

  const tableBodyItems: TableBodyItems<TTreatmentPlanItem> = [
    {
      name: 'treatment_code',
      render: (item, _isHover, isCellHover) => (
        <TreatmentTableItemNumber
          item={item}
          handleTreatmentCodeChange={handleTreatmentCodeChange}
          isHover={isTouchDevice || isCellHover}
        />
      )
    },
    {
      name: 'price',
      render: ({ price }) => (
        <Box>
          <Typography
            variant={'body1'}
          >{`${price ? `$${price}` : ''}`}</Typography>
        </Box>
      )
    },
    {
      name: 'description',
      render: ({ description }) => (
        <TreatmentTableDescription description={description || ''} />
      )
    },
    {
      name: 'quantity',
      render: ({ quantity, id, treatment_code }, _isHover, isCellHover) => (
        <Quantity
          id={id}
          handleTreatmentPlanChange={handleTreatmentPlanChange}
          isHover={isTouchDevice || isCellHover}
          quantity={quantity}
          treatment_code={treatment_code}
        />
      )
    },
    {
      name: 'tooth_number',
      render: ({ tooth_number, id }, _isHover, isCellHover) => (
        <ThoothNumber
          id={id}
          handleTreatmentPlanChange={handleTreatmentPlanChange}
          isHover={isTouchDevice || isCellHover}
          tooth_number={tooth_number}
        />
      )
    },
    {
      name: 'action',
      align: 'right',
      render: ({ id }) => (
        <IconButton onClick={() => handleTreatmentPlanDelete(id)}>
          <TrashIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )
    }
  ]
  return (
    <Box>
      <Typography color={theme.palette.primary.main}>
        THIS IS NOT AN INVOICE - any necessary follow-up treatment is listed
        below
      </Typography>
      <Box
        sx={{
          mt: 2,
          position: 'relative'
        }}
      >
        <Table
          tableContainerStyles={{
            [`&.${tableContainerClasses.root}`]: {
              position: 'static',
              [`.${tableClasses.root}`]: {
                border: `1px solid ${theme.palette.grey200.main}`,

                [`.${tableCellClasses.root}`]: {
                  padding: '12px 24px !important'
                },
                [`.${tableBodyClasses.root}`]: {
                  [`.${tableCellClasses.root}`]: {
                    borderRight: `1px solid ${theme.palette.grey200.main}`
                  }
                }
              }
            }
          }}
          data={treatment_plan}
          tableBodyItems={tableBodyItems}
          tableHeadCells={tableHeadCells}
        />

        <Box
          sx={{
            position: 'absolute',
            top: '50px',
            left: '-12px',
            zIndex: theme.zIndex.drawer + 1
          }}
        >
          <IconButton
            onClick={handleAddTreatmentCode}
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
              width: '24px',
              height: '24px',
              '&:hover': {
                backgroundColor: theme.palette.primary.main
              }
            }}
          >
            <PlusIcon
              sx={{
                fill: theme.palette.common.white,
                width: '24px',
                height: '24px'
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
