import {
  backdropClasses,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  paperClasses
} from '@mui/material'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  DotsThreeIcon,
  DownloadIcon,
  EFormsicon,
  TrashIcon
} from '@/assets/icons'
import { downloadFileFromBlob, showError } from '@/helpers/functions'
import {
  useDeleteCDBSReportMutation,
  useDownloadCDBSReportMutation,
  useSendCDBSReportMutation
} from '@/redux/services/cdbsReportsApi'
import {
  useDeleteProbonoReportMutation,
  useDownloadProbonoReportMutation,
  useSendProbonoReportMutation
} from '@/redux/services/probonoReportsApi'
import { PAGES } from '@/routes/pages-routes'
import { theme } from '@/theme'
import { toastSuccess } from '@/utils/lib'

interface Props {
  isProbono?: boolean
  fullName: string
}

const Actions: FC<Props> = (props): JSX.Element => {
  const { isProbono, fullName } = props

  const { patientId, reportId } = useParams()
  const actionButtonRef = useRef<HTMLButtonElement | null>(null)
  const [actionAnchorEl, setActionAnchorEl] =
    useState<HTMLButtonElement | null>(null)
  const isActionMenuOpen = Boolean(actionAnchorEl)
  const handleOpenActionMenu = () => setActionAnchorEl(actionButtonRef.current)
  const handleCloseActionMenu = () => setActionAnchorEl(null)

  const [sendCDBSReport, { isLoading: isSendCDBSLoading }] =
    useSendCDBSReportMutation()
  const [sendProbonoReport, { isLoading: isSendProbonoLoading }] =
    useSendProbonoReportMutation()
  const [deleteProbonoReport, { isLoading: isDeleteProbonoReportLoading }] =
    useDeleteProbonoReportMutation()
  const [deleteCdbsReport, { isLoading: isDeleteCDBSReportLoading }] =
    useDeleteCDBSReportMutation()
  const [downloadProbonoReport, { isLoading: isDownloadProbonoReportLoading }] =
    useDownloadProbonoReportMutation()
  const [downloadCDBSReport, { isLoading: isDownloadCDBSReportLoading }] =
    useDownloadCDBSReportMutation()

  const navigate = useNavigate()

  const sendReport = useCallback(async () => {
    const sendFunction = isProbono ? sendProbonoReport : sendCDBSReport
    try {
      if (patientId && reportId) {
        await sendFunction({ patientId, reportId }).unwrap()
        toastSuccess({ content: 'Report sent successfully' })
      }
    } catch (error: any) {
      showError(error?.data?.errors)
    }
  }, [isProbono, patientId, reportId, sendCDBSReport, sendProbonoReport])

  const deleteReport = useCallback(async () => {
    const deleteFunction = isProbono ? deleteProbonoReport : deleteCdbsReport
    try {
      if (patientId && reportId) {
        await deleteFunction({ patientId, reportId }).unwrap()
        navigate(PAGES.PATIENTS)
        toastSuccess({ content: 'Report deleted successfully' })
      }
    } catch (error: any) {
      showError(error?.data?.errors)
    }
  }, [
    deleteCdbsReport,
    deleteProbonoReport,
    isProbono,
    navigate,
    patientId,
    reportId
  ])

  const downloadPdf = useCallback(async () => {
    const downloadFunction = isProbono
      ? downloadProbonoReport
      : downloadCDBSReport
    try {
      if (patientId && reportId) {
        const data = await downloadFunction({ patientId, reportId }).unwrap()

        const blob = new Blob([data], {
          type: 'text/pdf'
        })

        downloadFileFromBlob(blob, `${fullName}-report.pdf`)
      }
    } catch (e: any) {
      showError(e?.data.errors)
    }
  }, [
    downloadCDBSReport,
    downloadProbonoReport,
    isProbono,
    patientId,
    reportId,
    fullName
  ])

  const options = useMemo(
    () => [
      {
        content: (
          <>
            <EFormsicon
              sx={{
                width: '20px',
                height: '20px',
                marginRight: '8px',
                path: {
                  fill: 'currentcolor'
                }
              }}
            />
            Send report
          </>
        ),
        disabled: isSendCDBSLoading || isSendProbonoLoading,
        isAllowed: true, // TODO add permisions
        action: () => sendReport()
      },
      {
        title: 'Download template',
        content: (
          <>
            {!isDownloadProbonoReportLoading && !isDownloadCDBSReportLoading ? (
              <DownloadIcon
                sx={{
                  width: '20px',
                  height: '20px',
                  marginRight: '8px',
                  path: {
                    fill: 'currentcolor'
                  }
                }}
              />
            ) : (
              <Box marginRight={'8px'}>
                <CircularProgress size={20} color="primary" />
              </Box>
            )}
            Download report as PDF
          </>
        ),
        disabled: isDownloadProbonoReportLoading || isDownloadCDBSReportLoading,
        isAllowed: true, // TODO add permisions
        action: () => downloadPdf()
      },
      {
        content: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.error.main
            }}
          >
            <TrashIcon
              sx={{
                width: '20px',
                height: '20px',
                marginRight: '8px',
                path: {
                  fill: 'currentcolor'
                }
              }}
            />
            Delete
          </Box>
        ),
        action: deleteReport,
        disabled: isDeleteProbonoReportLoading || isDeleteCDBSReportLoading,
        isAllowed: true // TODO add permisions
      }
    ],
    [
      isSendCDBSLoading,
      isSendProbonoLoading,
      isDownloadProbonoReportLoading,
      isDownloadCDBSReportLoading,
      deleteReport,
      isDeleteProbonoReportLoading,
      isDeleteCDBSReportLoading,
      sendReport,
      downloadPdf
    ]
  )

  return (
    <Box display={{ xs: 'flex' }}>
      <IconButton
        onClick={handleOpenActionMenu}
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '4px',
          backgroundColor: isActionMenuOpen
            ? theme.palette.primary.light
            : '#F0F3F7',
          color: isActionMenuOpen ? theme.palette.primary.main : 'unset',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main
          },
          svg: {
            path: {
              fill: 'currentcolor',
              transition: theme.transitions.create(['color'], {
                duration: theme.transitions.duration.shortest
              })
            }
          }
        }}
        ref={actionButtonRef}
      >
        <DotsThreeIcon
          sx={{
            width: '28px',
            height: '28px'
          }}
        />
      </IconButton>
      <Menu
        anchorEl={actionAnchorEl}
        id="action-menu"
        open={isActionMenuOpen}
        onClose={handleCloseActionMenu}
        sx={{
          [`& .${backdropClasses.root}`]: {
            backgroundColor: 'transparent'
          }
        }}
        slotProps={{
          paper: {
            variant: 'outlined',
            sx: {
              [`&.${paperClasses.root}`]: {
                width: '100%',
                maxWidth: '250px',
                marginTop: '4px',
                boxShadow: theme.shadows[1]
              }
            }
          }
        }}
        disablePortal
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
      >
        {options
          .filter((option) => option.isAllowed)
          .map(({ action, content, ...rest }, index) => (
            <MenuItem key={index} onClick={action} {...rest}>
              {content}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  )
}

export default Actions
