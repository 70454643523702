import {
  TCreateTreatmentCodeParams,
  TCreateTreatmentCodeResponse,
  TDeleteTreatmentCodeParams,
  TDeleteTreatmentCodeResponse,
  TGetAllTreatmentCodesParams,
  TGetAllTreatmentCodesResponse,
  TGetTreatmentCodeParams,
  TGetTreatmentCodeResponse,
  TUpdateTreatmentCodeParams,
  TUpdateTreatmentCodeResponse
} from '@/types/TreatmentCodes'
import { API_METHODS, TREATMENT_CODES_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const treatmentCodesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTreatmentCodes: builder.query<
      TGetAllTreatmentCodesResponse,
      TGetAllTreatmentCodesParams
    >({
      query: (params) => ({
        url: TREATMENT_CODES_API.ALL,
        method: API_METHODS.GET,
        params
      })
    }),
    createTreatmentCode: builder.mutation<
      TCreateTreatmentCodeResponse,
      TCreateTreatmentCodeParams
    >({
      query: (body) => ({
        url: TREATMENT_CODES_API.ALL,
        method: API_METHODS.POST,
        body: body
      })
    }),
    getTreatmentCodeById: builder.query<
      TGetTreatmentCodeResponse,
      TGetTreatmentCodeParams
    >({
      query: ({ id }) => ({
        url: `${TREATMENT_CODES_API.ALL}${id}/`,
        method: API_METHODS.GET
      })
    }),
    updateTreatmentCode: builder.mutation<
      TUpdateTreatmentCodeResponse,
      TUpdateTreatmentCodeParams
    >({
      query: ({ id, ...body }) => ({
        url: `${TREATMENT_CODES_API.ALL}${id}/`,
        method: API_METHODS.PATCH,
        body: body
      })
    }),
    deleteTreatmentCodeById: builder.mutation<
      TDeleteTreatmentCodeResponse,
      TDeleteTreatmentCodeParams
    >({
      query: ({ id }) => ({
        url: `${TREATMENT_CODES_API.ALL}${id}/`,
        method: API_METHODS.DELETE
      })
    })
  })
})

export const {
  useGetAllTreatmentCodesQuery,
  useCreateTreatmentCodeMutation,
  useGetTreatmentCodeByIdQuery,
  useUpdateTreatmentCodeMutation,
  useDeleteTreatmentCodeByIdMutation
} = treatmentCodesApi
