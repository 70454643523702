import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'

import { theme } from '@/theme'

import AdminNotes from '../../patients/components/AdminNotes'
import ParentNotes from '../../patients/components/ParentNotes'

interface Props {
  admin_notes: string
  parent_notes: string
  id: string
}

export const NotesInformation: React.FC<Props> = (props): JSX.Element => {
  const { admin_notes, parent_notes, id } = props

  const [isAdminNotesHover, setIsAdminNotesHover] = useState<boolean>(false)
  const [isParentNotesHover, setIsParentNotesHover] = useState<boolean>(false)

  return (
    <Box mb={5}>
      <Box mb="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          Notes information
        </Typography>
      </Box>
      <Grid
        mb="20px"
        container
        xs={12}
        item
        spacing={2}
        display="flex"
        flexWrap="wrap"
      >
        <Grid
          item
          xs={12}
          sm={6}
          gap={1}
          display="flex"
          minWidth="200px"
          flexDirection="column"
        >
          <Typography
            variant="body1"
            fontSize={'16px'}
            color={theme.palette.secondary.main}
          >
            Admin notes
          </Typography>
          <Box
            maxHeight="140px"
            minHeight="27px"
            overflow="auto"
            onMouseOver={() => setIsAdminNotesHover(true)}
            onMouseLeave={() => setIsAdminNotesHover(false)}
          >
            <AdminNotes
              isOnDetailPage
              id={id}
              admin_notes={admin_notes}
              isHover={isAdminNotesHover}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          gap={1}
          display="flex"
          minWidth="200px"
          flexDirection="column"
        >
          <Typography
            variant="body1"
            fontSize={'16px'}
            color={theme.palette.secondary.main}
          >
            Parent notes
          </Typography>
          <Box
            maxHeight="140px"
            minHeight="27px"
            overflow="auto"
            onMouseOver={() => setIsParentNotesHover(true)}
            onMouseLeave={() => setIsParentNotesHover(false)}
          >
            <ParentNotes
              isOnDetailPage
              id={id}
              isHover={isParentNotesHover}
              parent_notes={parent_notes}
            />
          </Box>
        </Grid>
      </Grid>
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
