import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
  description: string
}

export const TreatmentTableDescription: React.FC<Props> = (
  props
): JSX.Element => {
  const { description } = props

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%'
      }}
    >
      <Box flexGrow={1}>
        <Box
          maxHeight="84px"
          maxWidth="230px"
          textOverflow="ellipsis"
          overflow="auto"
          sx={{ wordBreak: 'break-all' }}
        >
          <Typography variant={'body1'} px={0.5}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
