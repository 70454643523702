import {
  TAddSchoolContactPersonParams,
  TAddSchoolContactPersonResponse,
  TDeleteSchoolContactPersonParams,
  TDeleteSchoolContactPersonResponse,
  TPatchUpdateSchoolContactPersonParams,
  TPatchUpdateSchoolContactPersonResponse
} from '@/types/SchoolsContactPerson'
import { API_METHODS, SCHOOLS_API } from '@/utils/constants'

import { apiSlice } from './apiSlice'

export const schoolsContactPerson = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSchoolContactPerson: builder.mutation<
      TAddSchoolContactPersonResponse,
      TAddSchoolContactPersonParams
    >({
      query: (body) => ({
        url: SCHOOLS_API.SCHOOLS_CONTACTS,
        method: API_METHODS.POST,
        body
      })
    }),
    patchUpdateSchoolContactPerson: builder.mutation<
      TPatchUpdateSchoolContactPersonResponse,
      TPatchUpdateSchoolContactPersonParams
    >({
      query: ({ id, ...body }) => ({
        url: `${SCHOOLS_API.SCHOOLS_CONTACTS}${id}/`,
        method: API_METHODS.PATCH,
        body
      })
    }),
    deleteSchoolContactPerson: builder.mutation<
      TDeleteSchoolContactPersonResponse,
      TDeleteSchoolContactPersonParams
    >({
      query: ({ id }) => ({
        url: `${SCHOOLS_API.SCHOOLS_CONTACTS}${id}/`,
        method: API_METHODS.DELETE
      })
    })
  })
})

export const {
  useAddSchoolContactPersonMutation,
  usePatchUpdateSchoolContactPersonMutation,
  useDeleteSchoolContactPersonMutation
} = schoolsContactPerson
