import { toast, ToastOptions } from 'react-hot-toast'

export interface IToast extends Partial<ToastOptions> {
  content: string
}

const toastSuccess = ({ content, ...rest }: IToast) =>
  toast.success(content, { ...rest, style: { fontFamily: 'Inter' } })

const toastError = ({ content, ...rest }: IToast) =>
  toast.error(content, {
    ...rest,
    style: { fontFamily: 'Inter' }
  })

export { toastError, toastSuccess }
