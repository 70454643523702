import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'

import { theme } from '@/theme'

import { ProbonoReportData } from '../probono/components/ProbonoReport'

interface Props {
  register: UseFormRegister<ProbonoReportData>
  control: Control<ProbonoReportData>
  disabled: boolean
}

const treatmentAdvisedOptions = [
  {
    title: 'X-rays:',
    required: true,
    key: 'xrays',
    options: [
      {
        name: 'Yes',
        value: 'yes'
      },
      {
        name: 'No',
        value: 'no'
      },
      {
        name: 'Yes if no X-rays within last 6 months',
        value: 'conditional'
      }
    ]
  },
  {
    title: 'Scale and clean:',
    required: true,
    key: 'scale_and_clean',
    options: [
      {
        name: 'Yes',
        value: 'yes'
      },
      {
        name: 'No',
        value: 'no'
      },
      {
        name: 'Advised at next check-up',
        value: 'next_checkup'
      }
    ]
  },
  {
    title: 'Fluoride:',
    required: true,
    key: 'fluoride',
    options: [
      {
        name: 'Yes',
        value: 'yes'
      },
      {
        name: 'No',
        value: 'no'
      },
      {
        name: 'Completed today',
        value: 'completed'
      }
    ]
  },
  {
    title: 'Fissure sealants:',
    required: true,
    key: 'fissure_sealants',
    hasCommnet: true,
    commentKey: 'fissure_sealants_comment',
    options: [
      {
        name: 'Yes',
        value: 'yes'
      },
      {
        name: 'No',
        value: 'no'
      }
    ]
  },
  {
    title: 'Fillings:',
    required: true,
    key: 'fillings',
    hasCommnet: true,
    commentKey: 'fillings_comment',
    options: [
      {
        name: 'Yes',
        value: 'yes'
      },
      {
        name: 'No',
        value: 'no'
      }
    ]
  },
  {
    title: 'Orthodontic consult:',
    required: true,
    key: 'ortho_consult_advised',
    hasCommnet: true,
    commentKey: 'ortho_consult_comment',
    options: [
      {
        name: 'Yes',
        value: 'yes'
      },
      {
        name: 'No',
        value: 'no'
      }
    ]
  },
  {
    title: 'Next dental visit:',
    required: true,
    key: 'next_dental_visit',
    options: [
      {
        name: 'As Soon As Possible',
        value: 'urgent'
      },
      {
        name: 'Not Urgent',
        value: 'not_urgent'
      }
    ]
  }
]

export const TreatmentAdvised: React.FC<Props> = (props): JSX.Element => {
  const { control, register, disabled } = props

  return (
    <Box mb={5}>
      <Typography color={theme.palette.primary.main} variant="h5">
        Treatment advised by dental clinician
      </Typography>
      <Box>
        {treatmentAdvisedOptions.map((el) => (
          <Controller
            control={control}
            name={el.key as keyof ProbonoReportData}
            key={el.key}
            render={({ field }) => (
              <Grid
                container
                item
                width="100%"
                xs={12}
                lg={12}
                columnSpacing={4}
                display="flex"
                alignItems="center"
                mt="20px"
              >
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">
                    {el.title}
                    {el.required && (
                      <Typography
                        component="span"
                        variant="inherit"
                        sx={{
                          color: theme.palette.error.main
                        }}
                      >
                        *
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <RadioGroup
                    row
                    sx={{
                      gap: { xs: 2, lg: 6 },
                      mt: 1,
                      display: 'flex'
                    }}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {el.options.map((r, i) => (
                      <Grid key={i} item xs={12} lg={3}>
                        <FormControlLabel
                          value={r.value}
                          control={<Radio />}
                          label={r.name}
                          sx={{
                            gap: 1,
                            color: '#000',
                            fontWeight: 400,
                            fontSize: '18px'
                          }}
                        />
                      </Grid>
                    ))}
                    {el.hasCommnet && (
                      <TextField
                        placeholder="Add comment"
                        disabled={disabled}
                        {...register(el.commentKey as keyof ProbonoReportData)}
                      />
                    )}
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
          />
        ))}
      </Box>
      <Box mt="20px" borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
