import {
  IQueryParams,
  TServerResponse,
  TServerResponsePagination
} from './Common'

export enum PatientTypesEnum {
  NEW_CDBS = 'New CDBS',
  NEW_PROBONO = 'New Probono',
  WITHDRAWN = 'Withdrawn',
  MISSING_INFORMATION = 'Missing Information',
  RESUBMITTED_FORMS = 'Re-submitted forms',
  FORMS_AWAITING_RESPONSE = 'Forms awaiting parent response',
  COMPLETE_CDBS = 'Complete cdbs',
  COMPLETE_PROBONO = 'Complete Probono',
  CDBS_FURTHER_TREATMENT = 'CDBS further treatment',
  INCOMPLETE = 'Incomplete',
  NEW_FORM = 'New Form'
}
export enum SchoolPatientTypesEnum {
  NEW_CDBS = 'New CDBS',
  NEW_PROBONO = 'New Probono',
  COMPLETE_CDBS = 'Complete cdbs',
  COMPLETE_PROBONO = 'Complete Probono',
  CDBS_FURTHER_TREATMENT = 'CDBS further treatment'
}

export enum DentalStatusEnum {
  NOT_YET_SEEN = 'Not yet seen',
  SEEN_NO_CONSENT = 'Seen, needs further work with ADH - NO parent consent',
  SEEN_YES_CONSENT = 'Seen, needs further work with ADH - YES parent consent',
  ABSENT = 'Absent',
  COMPLETE_SEEN = 'Complete, seen'
}

export enum ReportTypeEnum {
  PROBONO = 'probono',
  CDBS = 'cdbs'
}

export interface IReportInfo {
  is_report_sent: boolean
  report_id: string | null
  report_type: ReportTypeEnum | null
}

export interface IReportSchoolInfo {
  class: string
  name: string
  year_level: string
}
export interface IMedicareInfo {
  card_number: string
  expiration_date: string
  irn_next_number: number
  name_of_child: string
  name_of_signing_person: string
  patient: string
}
export interface IPatientsEforms {
  id: string
  first_name: string
  last_name: string
  date_of_birth: string
  type: Lowercase<keyof typeof PatientTypesEnum>
  created_at: string
  is_late_form: boolean
  balance: string
  admin_notes: string
  school: {
    id: string
    name: string
  }
  medicare_info: IMedicareInfo
  form_submit_year: number
}

export interface IPatient {
  id: string
  first_name: string
  last_name: string
  phone: string
  school: PatientSchool
  year_level: string
  class_name: string
  balance: string | null
  type: Lowercase<keyof typeof PatientTypesEnum>
  dental_status: Lowercase<keyof typeof DentalStatusEnum> | null
  report_sent: boolean
  admin_notes: string | null
  parent_notes: string | null
  report_info: IReportInfo
  parent_contact: {
    full_name: string
    phone: string
  }
}

export interface MedicareInfo {
  card_number: string
  expiration_date: string
  irn_next_number: number
  name_of_child: string
  name_of_signing_person: string
  patient: string
}

export interface IGetPatient {
  admin_notes: string | null
  parent_notes: string | null
  balance: string | null
  dental_status: Lowercase<keyof typeof DentalStatusEnum> | null
  type: Lowercase<keyof typeof PatientTypesEnum>
  first_name: string
  id: string
  last_name: string
  phone: string
  report_sent: boolean
  health_info: any
  report_info: IReportInfo
  school_info: IReportSchoolInfo
  date_of_birth: string
  email: string
  medicare_info: MedicareInfo
  parent_name: string
  address: string | null
  consent_info: {
    medicare_consent_question: string
    patient: string
    consent_given: true
    consent_answer: string
  }[]
}

export interface PatientSchool {
  id: string
  name: string
}

// API
// Get patients E-Forms

export type TGetPatientsEformsParams = Pick<
  IQueryParams,
  'ordering' | 'page' | 'page_size' | 'search'
> & {
  school_ids?: string
  school__names?: string
  type?: IPatientsEforms['type'] | ''
  form_submit_year?: string
  school__region?: string
}

export type TGetPatientsParams = Pick<
  IQueryParams,
  'ordering' | 'page' | 'page_size' | 'search'
> & {
  class_name?: string
  dental_status?: Lowercase<keyof typeof DentalStatusEnum>
  school__name?: string
  school_ids?: string
  school__region?: string
  type?: Lowercase<keyof typeof PatientTypesEnum>
  year_level?: string
  type__in?: string
  is_completed?: boolean
  is_completed_today?: boolean
}

export type TGetPatientsEformsResponseData = IPatientsEforms

export type TGetPatientsResponseData = IPatient

export type TGetPatientsResponse = TServerResponse<
  TServerResponsePagination<TGetPatientsResponseData[]>
>

export type TGetPatientResponse = TServerResponse<IGetPatient>

export type TGetPatientsEformsResponse = TServerResponse<
  TServerResponsePagination<TGetPatientsEformsResponseData[]>
>

// Patch update patients E-Forms

export type TPatchUpdatePatientsEformsParams = Partial<
  Omit<IPatientsEforms, 'created_at'>
>

export type TPatchUpdatePatientsParams = Partial<Omit<IPatient, 'created_at'>>

export type TPatchUpdatePatientsEformsResponseData = IPatientsEforms

export type TPatchUpdatePatientsEformsResponse = TServerResponse<
  TServerResponsePagination<TPatchUpdatePatientsEformsResponseData[]>
>

export type TPatchUpdatePatientsResponse = TServerResponse<
  TServerResponsePagination<IPatient[]>
>

export type TDeletePatientParams = Pick<IPatient, 'id'>

export type TGetPatientParams = Pick<IPatient, 'id'>

export type TDeletePatientEformsParams = Pick<IPatient, 'id'>
export type TDeletePatientEformsResponse = TServerResponse<void>

export interface PatientReportAutofill {
  id: string
  full_name: string
  date_of_birth: string
  dental_assistant: string
}

export type TGetPatientReportAutofill = TServerResponse<PatientReportAutofill>

export interface EFormsNextRefresh {
  next_refresh_possible: string
}

export type EFormsNextRefreshResponse = TServerResponse<EFormsNextRefresh>

export type TSendReportsParams = { patient_ids: string[] }
export type TSendReportsResponse = TServerResponse<void>

export type TPatientFile = {
  id: string
  name: string
  patient: string
  extension: string
  size: number
  confidential: true
  file: File
}

export type TAddPatientFileParams = Omit<TPatientFile, 'id'>
export type TAddPatientFileResponse = TServerResponse<TPatientFile>
