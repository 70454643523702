import {
  Box,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import { theme } from '@/theme'

import { CdbsReportData } from '../cdbs/components/CdbsReport'

interface Props {
  register: UseFormRegister<CdbsReportData>
}

export const TreatmentNotes: React.FC<Props> = (props): JSX.Element => {
  const { register } = props
  return (
    <Box mb={3}>
      <Box mb={2} display="flex" flexDirection="column" gap="20px">
        <FormControl fullWidth>
          <FormLabel htmlFor="admin_notes">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '4px'
              }}
            >
              <Typography variant="inherit">
                Additional treatment notes:
              </Typography>
            </Box>
          </FormLabel>
          <TextField
            id="treatment_notes"
            variant="outlined"
            color="primary"
            multiline
            rows={2}
            placeholder="Add notes"
            {...register('additional_notes')}
          />
        </FormControl>
        <Typography fontSize={16} color={theme.palette.error.main}>
          If our visit at your child’s school has concluded you will need to
          take your child to a local dental clinic to complete any required
          treatments.
        </Typography>
      </Box>
      <Box borderBottom={`1px solid ${theme.palette.grey200.main}`} />
    </Box>
  )
}
