/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

export const Logo: React.FC<SvgIconProps> = (
  props: SvgIconProps
): JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="32"
      viewBox="0 0 44 32"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1185 0.599461L18.7424 0.354805C18.4828 0.308655 18.2192 0.334657 17.9751 0.434349C16.5488 1.01691 11.3232 3.18725 8.01411 5.04218C6.79728 5.72426 5.41783 6.60012 4.09645 7.48185C0.69879 9.74906 -0.723658 13.9749 0.353642 17.9149C1.25542 21.2129 4.74276 23.0948 8.07199 22.3159C11.821 21.4388 16.624 20.6011 20.4114 20.9021C23.7375 21.1665 27.6588 22.4353 30.6374 23.583C30.6843 23.6011 30.7314 23.6192 30.7788 23.6375C33.8258 24.8123 37.7713 26.3335 39.9913 23.9808C40.4876 23.455 41.131 22.5267 41.7906 21.4812C44.0214 17.9453 44.7861 13.5063 43.0415 9.70687C41.3784 6.08492 36.7364 2.30701 33.973 0.283757C33.0325 -0.404876 31.7532 0.241334 31.6956 1.40561C31.6026 3.2871 30.0518 4.77322 28.1707 4.67236C27.1518 4.61773 26.0792 4.53 25.2326 4.38912C23.7453 4.14163 21.8172 3.40626 20.6806 2.93601C20.2191 2.74507 20.1178 2.14865 20.4802 1.80503C20.8999 1.40701 20.6881 0.700716 20.1185 0.599461ZM34.4796 31.8659C34.0741 31.6819 33.651 31.4361 33.3989 31.1354C32.714 30.3187 32.0154 29.2459 32.8085 28.5227C33.1823 28.1818 33.8014 28.3173 34.3581 28.5607C35.1119 28.8904 35.9811 28.8301 36.7441 28.5227C37.3074 28.2958 37.3877 28.3931 37.6166 28.6704C37.6499 28.7108 37.6864 28.755 37.728 28.8026C38.1517 29.2874 37.5578 29.9846 37.2568 30.338C37.2072 30.3963 37.1655 30.4451 37.1377 30.4822C36.9185 30.7741 36.6262 31.1127 36.353 31.4153C35.8748 31.9449 35.1295 32.1606 34.4796 31.8659Z"
        fill="#32B22B"
      />
    </SvgIcon>
  )
}
