import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Paper, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { showError } from '@/helpers/functions'
import { useAppSelector } from '@/hooks'
import { useOfflineMutation } from '@/hooks/useOfflineMuitation'
import { useUpdateCdbsFiles } from '@/hooks/useUpdateCdbsFiles'
import { useGetPatientReportAutofillQuery } from '@/redux/services'
import {
  useAddCdbsReportMutation,
  useEditCdbsReportMutation,
  useGetCDBSReportQuery
} from '@/redux/services/cdbsReportsApi'
import { PAGES } from '@/routes/pages-routes'
import { theme } from '@/theme'
import { TCDBSReportResponse } from '@/types'
import { toastError, toastSuccess } from '@/utils/lib'
import { cdbsReportSchema } from '@/utils/yup/cdbsReportSchema'

import {
  AddSignature,
  BrushingAreas,
  CdbsOralHealth,
  DescriptionsOfDentalTerms,
  NextDentalVisit,
  PatientInfoForm,
  TotalAmount,
  TreatmentNotes,
  TreatmentPlan,
  TreatmentsCompleted,
  UploadFileBlock
} from '../../components'
import Actions from '../../components/Actions'

export type CdbsReportData = yup.InferType<typeof cdbsReportSchema>

const initialValues = {
  focus_brushing_areas: [],
  treatments_completed: [],
  teeth_type_present: '',
  plaque_buildup: '',
  clinician: '',
  calculus_buildup: '',
  holes_decay_present: '',
  ability_to_brush: '',
  ortho_consult_advised: '',
  additional_notes: '',
  dental_assistant: '',
  next_dental_visit: '',
  treatment_plan: [],
  files: []
}

type Props = {
  scrollToTop: () => void
}

const CDBSReport: React.FC<Props> = (props: Props): JSX.Element => {
  const { scrollToTop } = props

  const { patientId, reportId } = useParams()

  const navigate = useNavigate()

  const me = useAppSelector((state) => state.userStore.me)

  const { data: autofillData } = useGetPatientReportAutofillQuery(
    { id: patientId || '' },
    { skip: !patientId, refetchOnReconnect: true }
  )

  const { data: reportData, refetch: refetchCDBSReport } =
    useGetCDBSReportQuery(
      { reportId: reportId || '', patientId: patientId || '' },
      { skip: !reportId || !patientId, refetchOnReconnect: true }
    )

  const { isOnline, handleOfflineMutation } = useOfflineMutation()

  const [createReport, { isLoading: isCreateCdbsLoading }] =
    useAddCdbsReportMutation()

  const [editReport, { isLoading: isEditCdbsLoading }] =
    useEditCdbsReportMutation()

  const { handleFileChanges, isLoading: isMakingFileChanges } =
    useUpdateCdbsFiles()

  const {
    control,
    register,
    watch,
    reset,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors }
  } = useForm<CdbsReportData>({
    mode: 'onSubmit',
    resolver: yupResolver(cdbsReportSchema),
    defaultValues: {
      ...initialValues,
      clinician: me?.id as string,
      view_date: dayjs().format('YYYY-MM-DD')
    }
  })

  const signedImage = watch('signature')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const treatment_plan = watch('treatment_plan') || []

  const totalAmount = useMemo(
    () =>
      treatment_plan
        ? treatment_plan.reduce(
            (acc, item) => acc + item.price * item.quantity,
            0
          )
        : 0,
    [treatment_plan]
  )

  const onSubmit = useCallback(
    async (data: CdbsReportData) => {
      try {
        let reportResponse: null | TCDBSReportResponse = null
        if (!patientId) return

        const treatment_plan =
          data.treatment_plan?.map((el) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...rest } = el
            return rest
          }) || []

        const { files, ...rest } = data

        const body = {
          ...rest,
          treatment_plan,
          treatments_completed: data.treatments_completed || [],
          focus_brushing_areas: data.focus_brushing_areas || [],
          patientId: patientId || ''
        }

        const payload = reportId
          ? {
              ...body,
              reportId: reportId
            }
          : body

        if (!isOnline) {
          handleOfflineMutation({
            type: reportId ? 'EditCdbsReport' : 'AddCdbsReport',
            data: payload,
            timestamp: Date.now()
          })

          reset(data)
        } else {
          reportResponse = reportId
            ? await editReport(payload).unwrap()
            : await createReport(payload).unwrap()

          await handleFileChanges(
            reportData?.data.files || [],
            files,
            reportResponse?.data?.id || ''
          )

          toastSuccess({
            content: `Report ${reportId ? 'updated' : 'created'} successfully`
          })

          if (reportId) {
            refetchCDBSReport()
          } else {
            navigate(
              `${PAGES.CDBS_REPORT}/${patientId}/${reportResponse?.data.id}`
            )
          }
        }

        scrollToTop()
      } catch (error: any) {
        showError(error?.data?.errors || 'Something went wrong')
      }
    },
    [
      patientId,
      reportId,
      isOnline,
      handleFileChanges,
      reportData?.data.files,
      scrollToTop,
      editReport,
      createReport,
      handleOfflineMutation,
      reset,
      refetchCDBSReport,
      navigate
    ]
  )

  const onError: SubmitErrorHandler<CdbsReportData> = (errors) => {
    // @ts-ignore
    if (errors?.treatment_plan?.length > 0) {
      toastError({
        content:
          // @ts-ignore
          Object.values(errors?.treatment_plan)[0].treatment_code.message ||
          'Something went wrong'
      })
      return
    }
    toastError({ content: Object.values(errors)[0].message || '' })
  }

  const resetValues = useCallback(() => {
    if (reportData?.data) {
      reset({
        view_date: dayjs().format('YYYY-MM-DD'),
        dental_assistant:
          reportData?.data.dental_assistant ||
          autofillData?.data.dental_assistant ||
          initialValues.dental_assistant,
        teeth_type_present:
          reportData?.data.teeth_type_present ||
          initialValues.teeth_type_present,
        plaque_buildup:
          reportData?.data.plaque_buildup || initialValues.plaque_buildup,
        calculus_buildup:
          reportData?.data.calculus_buildup || initialValues.calculus_buildup,
        holes_decay_present:
          reportData?.data.holes_decay_present ||
          initialValues.holes_decay_present,
        ability_to_brush:
          reportData?.data.ability_to_brush || initialValues.ability_to_brush,
        ortho_consult_advised:
          reportData?.data.ortho_consult_advised ||
          initialValues.ortho_consult_advised,
        next_dental_visit:
          reportData?.data.next_dental_visit || initialValues.next_dental_visit,
        focus_brushing_areas:
          reportData?.data.focus_brushing_areas?.map((el) => el.id) ||
          initialValues.focus_brushing_areas,
        additional_notes:
          reportData?.data.additional_notes || initialValues.additional_notes,
        treatments_completed:
          reportData?.data.treatments_completed?.map((el) => el.id) ||
          initialValues.treatments_completed,
        treatment_plan:
          reportData?.data.treatment_plan || initialValues.treatment_plan,
        files:
          reportData?.data.files.map((file) => ({
            ...file
          })) || initialValues.files,
        clinician: reportData?.data.clinician || initialValues.clinician
      })
    }
  }, [autofillData?.data.dental_assistant, reportData?.data, reset])

  useEffect(() => {
    if (reportData?.data) {
      resetValues()
    }
  }, [reportData?.data, resetValues])

  useEffect(() => {
    if (reportId) return

    if (autofillData?.data) {
      reset({
        ...initialValues,
        view_date: dayjs().format('YYYY-MM-DD'),
        dental_assistant: autofillData?.data.dental_assistant || '',
        clinician: me?.id as string
      })
    }
  }, [autofillData?.data, me?.id, patientId, reportId, reset])

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSubmit, onError)}
      sx={{ p: '52px 32px', maxWidth: 'xl', mx: 'auto' }}
    >
      {reportId && (
        <Box mb={2} display="flex" justifyContent="flex-end">
          <Actions fullName={autofillData?.data.full_name || ''} />
        </Box>
      )}
      <PatientInfoForm
        disabled={isCreateCdbsLoading || isEditCdbsLoading}
        register={register}
        patientFullname={autofillData?.data.full_name || ''}
        birthDate={dayjs(autofillData?.data.date_of_birth || '').format(
          'DD/MM/YYYY'
        )}
      />
      <CdbsOralHealth control={control} />
      <TreatmentsCompleted
        setValue={setValue}
        treatments_completed={watch('treatments_completed') || []}
      />
      <NextDentalVisit control={control} />

      <Box mb={5} display="flex" flexDirection="column" gap="20px">
        <Typography color={theme.palette.primary.main} variant="h5">
          Areas to focus on while brushing
        </Typography>
        <BrushingAreas
          setValue={(value) => setValue('focus_brushing_areas', value)}
          focus_brushing_areas={watch('focus_brushing_areas') || []}
        />
      </Box>
      <TreatmentPlan setValue={setValue} treatment_plan={treatment_plan} />

      <UploadFileBlock errors={errors} control={control} trigger={trigger} />

      <TotalAmount total={totalAmount} />
      <TreatmentNotes register={register} />
      <DescriptionsOfDentalTerms />
      <AddSignature
        sign={(signatureImage) => setValue('signature', signatureImage)}
        signedImage={signedImage || ''}
      />
      <Box display="flex" justifyContent="center" gap="16px" my="40px">
        <Button
          disabled={isCreateCdbsLoading || isEditCdbsLoading}
          variant="contained"
          color="grey200"
          sx={{
            maxWidth: '211px'
          }}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          disabled={
            isCreateCdbsLoading ||
            isEditCdbsLoading ||
            !signedImage ||
            isMakingFileChanges
          }
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            maxWidth: '211px'
          }}
          fullWidth
        >
          Save
        </Button>
      </Box>
    </Paper>
  )
}

export default CDBSReport
