import { Box, Typography } from '@mui/material'
import React from 'react'

import MobileScreenImage from '@/assets/images/MobileScreenImage'
import { theme } from '@/theme'

const MobileScreen: React.FC = (): JSX.Element => {
  return (
    <Box
      height="100dvh"
      display="flex"
      justifyContent="center"
      p={2}
      alignItems="center"
      flexDirection="column"
      gap="20px"
    >
      <MobileScreenImage sx={{ width: '100%' }} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          textAlign="center"
          color={theme.palette.primary.main}
          variant="h4"
        >
          We apologize!
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="center"
          fontSize={16}
          fontWeight={400}
        >
          Mobile version is not supported now
        </Typography>
      </Box>
    </Box>
  )
}

export default MobileScreen
