import { Box } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EFormsicon, PatientsIcon } from '@/assets/icons'
import { TabItem } from '@/components'
import { Back } from '@/components/UI'
import { PAGES } from '@/routes/pages-routes'
import { TabOption } from '@/types'

import PatientDetails from '../../patient-details/components/PatientDetails'

interface Props {
  children: ReactNode
}

type Tab = 'report' | 'patient-card'

const tabsOptions: TabOption<Tab>[] = [
  { icon: PatientsIcon, title: 'Patient’s card', id: 'patient-card' },
  {
    icon: EFormsicon,
    title: 'Report',
    id: 'report'
  }
]

export const ReportWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<Tab>('report')

  const navigate = useNavigate()

  const content = {
    report: children,
    'patient-card': <PatientDetails />
  }

  return (
    <Box>
      <Box px={4} pb={6}>
        <Back
          sx={{
            marginBottom: '28px'
          }}
          onClick={() => navigate(PAGES.PATIENTS)}
        >
          Back to patient's page
        </Back>
        <Box display="flex" maxWidth="xl" mx="auto" gap={1.5}>
          {tabsOptions.map((tab) => (
            <Box
              sx={{ width: 'fit-content' }}
              key={tab.id}
              onClick={() => setSelectedTab(tab.id)}
            >
              <TabItem
                active={tab.id === selectedTab}
                Icon={tab.icon}
                title={tab.title}
              />
            </Box>
          ))}
        </Box>
        <Box mt="20px">{content[selectedTab]}</Box>
      </Box>
    </Box>
  )
}
