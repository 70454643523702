import { parsePhoneNumberWithError } from 'libphonenumber-js'
import * as yup from 'yup'

import {
  MAX_NAME_LENGTH,
  MAX_UPLOAD_FILE_SIZE_AVATAR,
  MIN_UPLOAD_FILE_SIZE,
  PASSWORD_REGEXP
} from '../constants'

export const basicInfoSchema = yup.object({
  first_name: yup
    .string()
    .max(MAX_NAME_LENGTH, 'Max amount of symbols is 30')
    .required('First name is a required field'),
  last_name: yup
    .string()
    .max(MAX_NAME_LENGTH, 'Max amount of symbols is 30')
    .required('Last name is a required field'),
  phone: yup
    .string()
    .required('This is a required field')
    .test('test-phone-number', 'Phone is invalid', (value) => {
      try {
        return parsePhoneNumberWithError(value).isPossible()
      } catch (e) {
        return false
      }
    }),
  avatar: yup
    .mixed<File | string>()
    .test(
      'is-valid-size',
      'File size must be less than 1 MB',
      async (value) => {
        if (typeof value === 'string' || !value) return true

        return !(
          value.size < MIN_UPLOAD_FILE_SIZE ||
          value.size > MAX_UPLOAD_FILE_SIZE_AVATAR
        )
      }
    ),
  assistant: yup.string().max(MAX_NAME_LENGTH, 'Max amount of symbols is 30')
})

export type TBasicInfoModel = yup.InferType<typeof basicInfoSchema>

export const changePasswordSchema = yup.object({
  old_password: yup.string().required('Password is a required field'),
  new_password1: yup
    .string()
    .max(128, 'Max amount of symbols is 128')
    .matches(PASSWORD_REGEXP, 'Password must be valid')
    .required('New password is a required field'),
  new_password2: yup
    .string()
    .max(128, 'Max amount of symbols is 128')
    .matches(PASSWORD_REGEXP, 'Password must be valid')
    .oneOf(
      [yup.ref('new_password1')],
      'New password and confirm password must match'
    )
    .required('Confirm new password is a required field')
})

export type TChangePasswordModel = yup.InferType<typeof changePasswordSchema>
