import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { PAGES } from '@/routes/pages-routes'
import { ERole } from '@/utils/permissions/permissions'

import { useAppSelector } from './redux'

export const useDefaultPage = () => {
  const navigate = useNavigate()

  const me = useAppSelector((state) => state.userStore.me)
  const role = me?.role as ERole

  const getDefaultPagePath = useCallback(() => {
    if (me?.is_password_change_required) return PAGES.CHANGE_PASSWORD

    return role === ERole.School ? PAGES.PATIENTS : PAGES.SCHOOLS
  }, [me?.is_password_change_required, role])

  const navigateToDefaultPage = useCallback(() => {
    const path = getDefaultPagePath()
    navigate(path)
  }, [getDefaultPagePath, navigate])

  return { getDefaultPagePath, navigateToDefaultPage }
}
