import { Box, CircularProgress } from '@mui/material'
import React, { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import ScrollToTop from './helpers/components/ScrollToTop'
import { useDefaultPage } from './hooks/useDefaultPage'
import { AppWrapper } from './layouts/AppWrapper'
import PatientDetailsPage from './pages/private/patient-details'
import CDBSReport from './pages/private/reports/cdbs'
import ProbonoReport from './pages/private/reports/probono'
import { PAGES } from './routes/pages-routes'
import { PrivateRoute } from './routes/PrivateRoute'
import { PublicRoute } from './routes/PublicRoute'
import { Pages, PrivatePages } from './types/Common'
import { EPermission } from './utils/permissions/permissions'

// private pages
const Schools = lazy(() => import('@/pages/private/schools'))
const AESchool = lazy(() => import('@/pages/private/ae-school'))
const SchoolDetails = lazy(() => import('@/pages/private/school-details'))
const EForms = lazy(() => import('@/pages/private/e-forms'))
const Patients = lazy(() => import('@/pages/private/patients'))
const Staff = lazy(() => import('@/pages/private/staff'))
const Settings = lazy(() => import('@/pages/private/settings'))
const EForm = lazy(() => import('@/pages/other/e-form'))
const EFormSubmited = lazy(() => import('@/pages/other/e-form-submited'))
const Profile = lazy(() => import('@/pages/private/profile'))
const AEStaff = lazy(() => import('@/pages/private/ae-staff'))
const ChangePasswordPage = lazy(() => import('@/pages/private/change-password'))
const AddPatientPage = lazy(() => import('@/pages/private/add-patient'))

// public pages
const Login = lazy(() => import('@/pages/public/sign-in'))
const ResetPassword = lazy(() => import('@/pages/public/reset-password'))

const privatePages: PrivatePages[] = [
  {
    path: PAGES.SCHOOLS,
    component: Schools,
    permissionName: EPermission.VIEW_SCHOOLS
  },
  {
    path: PAGES.ADD_SCHOOL,
    component: AESchool,
    permissionName: EPermission.ACTION_SCHOOLS_ADD
  },
  {
    path: `${PAGES.EDIT_SCHOOL}/:id`,
    component: AESchool,
    permissionName: EPermission.ACTION_SCHOOLS_EDIT
  },
  {
    path: `${PAGES.SCHOOL_DETAILS}/:id`,
    component: SchoolDetails,
    permissionName: EPermission.VIEW_SCHOOL_DETAILS
  },
  {
    path: PAGES.EFORMS,
    component: EForms,
    permissionName: EPermission.VIEW_EFORMS
  },
  {
    path: PAGES.PATIENTS,
    component: Patients,
    permissionName: EPermission.VIEW_PATIENTS
  },
  {
    path: PAGES.STAFF,
    component: Staff,
    permissionName: EPermission.VIEW_STAFF
  },
  {
    path: PAGES.ADD_STAFF,
    component: AEStaff,
    permissionName: EPermission.VIEW_STAFF
  },
  {
    path: `${PAGES.EDIT_STAFF}/:id`,
    component: AEStaff,
    permissionName: EPermission.VIEW_STAFF
  },
  {
    path: PAGES.SETTINGS,
    component: Settings,
    permissionName: EPermission.VIEW_SETTINGS
  },
  {
    path: `${PAGES.PATIENTS_DETAILS}/:patientId`,
    component: PatientDetailsPage,
    permissionName: EPermission.ACTION_PATIENTS_VIEW
  },
  {
    path: `${PAGES.PATIENTS_DETAILS}/:patientId/:reportId`,
    component: PatientDetailsPage,
    permissionName: EPermission.ACTION_PATIENTS_VIEW
  },
  {
    path: PAGES.PROFILE,
    component: Profile,
    permissionName: null
  },
  {
    path: `${PAGES.PROBONO_REPORT}/:patientId`,
    component: ProbonoReport,
    permissionName: EPermission.ACTION_PATIENTS_VIEW
  },
  {
    path: `${PAGES.PROBONO_REPORT}/:patientId/:reportId`,
    component: ProbonoReport,
    permissionName: EPermission.ACTION_PATIENTS_VIEW
  },
  {
    path: `${PAGES.CDBS_REPORT}/:patientId`,
    component: CDBSReport,
    permissionName: EPermission.ACTION_PATIENTS_VIEW
  },
  {
    path: `${PAGES.CDBS_REPORT}/:patientId/:reportId`,
    component: CDBSReport,
    permissionName: EPermission.ACTION_PATIENTS_VIEW
  },
  {
    path: PAGES.CHANGE_PASSWORD,
    component: ChangePasswordPage,
    permissionName: null
  },
  {
    path: PAGES.PATIENTS_ADD,
    component: AddPatientPage,
    permissionName: EPermission.ACTION_PATIENTS_3D_PATIENTS_ADD
  }
]

const publicPages: Pages[] = [
  {
    path: PAGES.LOGIN,
    component: Login
  },
  {
    path: PAGES.RESET_PASSWORD,
    component: ResetPassword
  }
]

const App: React.FC = (): JSX.Element => {
  const { getDefaultPagePath } = useDefaultPage()

  return (
    <AppWrapper>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            height="100%"
            alignItems="center"
          >
            <CircularProgress size={60} />
          </Box>
        }
      >
        <ScrollToTop />
        <Routes>
          {publicPages.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<PublicRoute component={route.component} />}
            />
          ))}
          {privatePages.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PrivateRoute
                  permissionName={route.permissionName}
                  component={route.component}
                />
              }
            />
          ))}
          <Route path={PAGES.EFORM} element={<EForm />} />
          <Route path={PAGES.EFORM_SUBMITED} element={<EFormSubmited />} />
          <Route
            path={PAGES.ENTRY}
            element={<Navigate replace to={getDefaultPagePath()} />}
          />
        </Routes>
      </Suspense>
    </AppWrapper>
  )
}

export default App
