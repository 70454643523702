import { Box } from '@mui/material'
import React, { useCallback, useRef } from 'react'

import { ReportWrapper } from '../components'
import ProbonoReport from './components/ProbonoReport'

const ProbonoReportPage: React.FC = (): JSX.Element => {
  const topBoxRef = useRef<HTMLDivElement | null>(null)

  const scrollToTop = useCallback(() => {
    topBoxRef?.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }, [topBoxRef])

  return (
    <Box ref={topBoxRef}>
      <ReportWrapper>
        <ProbonoReport scrollToTop={scrollToTop} />
      </ReportWrapper>
    </Box>
  )
}

export default ProbonoReportPage
