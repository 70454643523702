import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import useEventListener from './useEventListener'

interface IInitialOptions {
  regExp?: RegExp
  single?: boolean
}

const initialOptions: IInitialOptions = {
  regExp: /\s+/,
  single: false
}

export const useTruncatedWords = <T extends HTMLElement>(
  options?: IInitialOptions
): [string[], MutableRefObject<T | null>, () => void] => {
  const elementRef = useRef<T>(null) as MutableRefObject<T | null>

  const [truncatedWords, setTruncatedWords] = useState<string[]>([])

  options = {
    ...initialOptions,
    ...options
  }

  const getTruncatedWords = useCallback((element: T) => {
    const containerComputedStyle = getComputedStyle(element)
    const containerWidth =
      element.clientWidth -
      parseFloat(containerComputedStyle.paddingLeft) -
      parseFloat(containerComputedStyle.paddingRight) -
      (containerComputedStyle.textOverflow === 'ellipsis' &&
      element.scrollWidth > element.clientWidth
        ? getTextWidth('...', containerComputedStyle)
        : 0)

    // Input support
    const textContent =
      element instanceof HTMLInputElement
        ? element.value.trim()
        : element.textContent?.trim()

    if (!textContent) {
      return setTruncatedWords([])
    }

    const textWidth = getTextWidth(textContent, containerComputedStyle)

    if (textWidth <= containerWidth) {
      return setTruncatedWords([])
    }

    const words = options?.single
      ? [textContent]
      : textContent.split(options?.regExp ? options.regExp : '')

    let totalWidth = 0
    const truncatedWords: string[] = []

    for (const word of words) {
      const wordWidth = getTextWidth(word, element.style)
      totalWidth += wordWidth

      if (totalWidth > containerWidth) {
        truncatedWords.push(word)
      }
    }

    setTruncatedWords(truncatedWords)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getTextWidth(text: string, style?: CSSStyleDeclaration) {
    const span = document.createElement('span')
    span.style.visibility = 'hidden'
    span.style.position = 'absolute'
    span.style.whiteSpace = 'nowrap'

    span.style.font = style?.font || getComputedStyle(document.body).font
    span.style.textTransform = style?.textTransform || ''

    span.textContent = text

    document.body.appendChild(span)

    const width = span.offsetWidth

    document.body.removeChild(span)

    return width
  }

  const handleResize = useCallback(() => {
    if (elementRef.current) {
      getTruncatedWords(elementRef.current)
    }
  }, [getTruncatedWords])

  useEffect(() => {
    if (elementRef.current) {
      getTruncatedWords(elementRef.current)
    }
  }, [getTruncatedWords, handleResize])

  useEventListener({
    type: 'resize',
    element: window,
    listener: handleResize
  })

  const recalculateTruncatedWords = useCallback(
    () => elementRef.current && getTruncatedWords(elementRef.current),
    [getTruncatedWords]
  )

  return [truncatedWords, elementRef, recalculateTruncatedWords]
}
