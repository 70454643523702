import { ISchool } from '@/types'
import { TUserStateMe } from '@/types/Me'
import { TStaff } from '@/types/Staff'
import { ERole } from '@/utils/permissions/permissions'

export const formatUserInitials = (
  first_name: string | null | undefined,
  last_name: string | null | undefined
) => {
  return (
    (first_name ?? '').charAt(0).toUpperCase() +
    (last_name ?? '').charAt(0).toUpperCase()
  )
}

export const formatUserName = (
  first_name: string | null | undefined,
  last_name: string | null | undefined
) => {
  return (first_name ?? '') + (first_name ? ' ' : '') + (last_name ?? '')
}

export const formSchoolMeUserObject = (
  schoolData: ISchool,
  is_password_change_required: boolean
): TUserStateMe => {
  return {
    id: schoolData.id,
    email: schoolData.email,
    name: schoolData.name,
    role: ERole.School,
    phone: schoolData.phone,
    photo: null,
    is_password_change_required
  }
}

export const formStaffMeUserObject = (
  userData: TStaff,
  is_password_change_required: boolean
): TUserStateMe => {
  return {
    id: userData.id,
    email: userData.email,
    first_name: userData.first_name,
    last_name: userData.last_name,
    assistant: userData.assistant || '',
    role: userData.role,
    phone: userData.phone,
    photo: userData.photo ? userData.photo : null,
    is_password_change_required
  }
}
