export const cleanObject = <T>(obj: T): Partial<T> => {
  const filteredObj: Partial<T> = {}
  let key: keyof T

  for (key in obj) {
    const value = obj[key]

    if (
      value ||
      typeof value === 'boolean' ||
      (Array.isArray(value) && value.length > 0)
    ) {
      filteredObj[key] = value
    }
  }

  return filteredObj
}
