import { styled, Tooltip as MuiTooltip,TooltipProps } from '@mui/material'

export const TooltipLight = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip
		enterTouchDelay={0} // Fix tooltip on Tablet/Mobile
		disableInteractive
		{...props}
		classes={{ popper: className }}
	/>
))(() => '')
