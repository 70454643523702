import { RegionEnum, SchoolTypeEnum } from '@/types'

export const regionOptions = [RegionEnum.VICTORIA, RegionEnum.NEW_SOUTH_WALES]

export const schoolTypeOptions = [
  SchoolTypeEnum.MIXED,
  SchoolTypeEnum.PRIMARY,
  SchoolTypeEnum.SECONDARY,
  SchoolTypeEnum.SPECIAL
]
