import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { CheckIcon, CrossIcon, DropdownIcon, PencilIcon } from '@/assets/icons'
import { getEnumKeyByValue, showError } from '@/helpers/functions'
import { usePatchUpdatePatientsMutation } from '@/redux/services'
import { DentalStatusEnum, IPatient } from '@/types'

interface Props {
  id: IPatient['id']
  type: IPatient['dental_status']
  isHover: boolean
  isOnDetailPage?: boolean
}

const dentalStatusTypesOptions = [
  DentalStatusEnum.NOT_YET_SEEN,
  DentalStatusEnum.SEEN_NO_CONSENT,
  DentalStatusEnum.SEEN_YES_CONSENT,
  DentalStatusEnum.ABSENT
]

const dentalStatusColor = {
  [DentalStatusEnum.NOT_YET_SEEN]: '#7B8A99',
  [DentalStatusEnum.SEEN_NO_CONSENT]: '#E4B300',
  [DentalStatusEnum.SEEN_YES_CONSENT]: '#32B22B',
  [DentalStatusEnum.ABSENT]: '#FF9900',
  [DentalStatusEnum.COMPLETE_SEEN]: '#117D0C'
}

const DentalStatus: React.FC<Props> = (props): JSX.Element => {
  const { id, type, isHover, isOnDetailPage } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [value, setValue] = useState<IPatient['dental_status'] | null>(null)

  const [updatePatient, { isLoading: isPatchUpdatePatientsLoading }] =
    usePatchUpdatePatientsMutation()

  const handleUpdateType = async () => {
    try {
      if (type !== value) {
        await updatePatient({
          id,
          dental_status: value!
        }).unwrap()
      }

      setIsEdit(false)
    } catch (e: any) {
      showError(e?.data?.errors)
    }
  }

  const getFormattedPatientType = (type: IPatient['dental_status'] | null) =>
    type
      ? DentalStatusEnum[type.toUpperCase() as keyof typeof DentalStatusEnum]
      : null

  const isLoading = isPatchUpdatePatientsLoading

  useEffect(() => {
    setValue(type)
  }, [type])

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
        minHeight: '44px'
      }}
    >
      {!isEdit && isHover && !isOnDetailPage && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      <Box flexGrow={1}>
        {isEdit ? (
          <Autocomplete
            id="type"
            options={dentalStatusTypesOptions}
            popupIcon={<DropdownIcon />}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select status"
                variant="outlined"
              />
            )}
            value={getFormattedPatientType(value)}
            onChange={(_, data) => {
              const type = getEnumKeyByValue(DentalStatusEnum, data as string)

              if (type) {
                const lowerCase =
                  type.toLowerCase() as IPatient['dental_status']

                setValue(lowerCase)
              }
            }}
            clearIcon={
              <CrossIcon
                sx={{
                  width: '20px',
                  height: '20px'
                }}
              />
            }
          />
        ) : (
          <Typography
            maxWidth="260px"
            color={
              dentalStatusColor[
                getFormattedPatientType(type) as keyof typeof dentalStatusColor
              ]
            }
            variant={isOnDetailPage ? 'h5' : 'body1'}
          >
            {getFormattedPatientType(type)}
          </Typography>
        )}
      </Box>
      {!isEdit && isHover && isOnDetailPage && (
        <IconButton onClick={() => setIsEdit(true)}>
          <PencilIcon
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      {isEdit && (
        <Box
          sx={{
            display: 'flex',
            gap: '4px'
          }}
        >
          <IconButton onClick={handleUpdateType} disabled={isLoading}>
            <CheckIcon
              sx={{
                width: '24px',
                height: '24px',
                path: {
                  fill: '#010800'
                }
              }}
            />
          </IconButton>
          <IconButton onClick={() => setIsEdit(false)} disabled={isLoading}>
            <CrossIcon
              sx={{
                width: '24px',
                height: '24px'
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default DentalStatus
